import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const Covid19 = ({ color = COLORS.PINK }: Props) => (
  <svg version="1.1" id="covid19" width="36px" height="36px" viewBox="0 0 28 28">
    <g>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M2.45,6.21c0.7,0.7,1.83,0.7,2.52,0c0.7-0.7,0.7-1.82,0-2.52
			c-0.7-0.7-1.83-0.7-2.52,0C1.75,4.38,1.75,5.51,2.45,6.21z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M1.55,24.23c0.7,0.7,1.83,0.7,2.52,0c0.7-0.7,0.7-1.83,0-2.52
			s-1.83-0.7-2.52,0S0.85,23.53,1.55,24.23z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M12.28,27c0.99,0,1.78-0.8,1.78-1.78s-0.8-1.78-1.78-1.78
			c-0.99,0-1.78,0.8-1.78,1.78S11.29,27,12.28,27z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M25.19,15.29c0.99,0,1.78-0.8,1.78-1.78s-0.8-1.78-1.78-1.78
			c-0.99,0-1.78,0.8-1.78,1.78S24.21,15.29,25.19,15.29z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M19.88,5.9c0.7,0.7,1.83,0.7,2.52,0c0.7-0.7,0.7-1.82,0-2.52
			c-0.7-0.7-1.83-0.7-2.52,0C19.19,4.08,19.19,5.21,19.88,5.9z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
          d="M19.41,18.11c-0.43-0.43-0.75-0.33-0.73-0.5
			c0.01-0.06,0.05-0.15,0.14-0.3c0.65-1.12,1.03-2.42,1.03-3.8l0,0v0c0-3.45-2.31-6.36-5.47-7.26c-0.06-0.02-0.08-0.04-0.08-0.07
			c0-0.03,0.01-0.08,0.01-0.15l0,0v0c0-1.12-0.9-2.02-2.02-2.02c-0.17,0-0.33,0.03-0.49,0.07c-0.02,0-0.04,0-0.05,0.01
			c0,0,0,0.01,0,0.01c-0.86,0.24-1.49,1.01-1.49,1.94c0,0.24,0.06,0.18-0.18,0.25c-2.52,0.77-4.44,2.82-5.12,5.38
			c-0.1,0.39-0.11,0.37-0.35,0.24c-0.22-0.13-0.51-0.16-0.79-0.16c-0.99,0-1.78,0.8-1.78,1.78s0.8,1.78,1.78,1.78
			c0.39,0,0.75-0.12,1.04-0.33c0.62,3.21,3.28,5.69,6.58,6.06c0,0,0,0,0,0c0.02,0,0.04,0,0.06,0c0.26,0.03,0.52,0.04,0.78,0.04
			c0,0,0,0,0.01,0c1.32,0,2.55-0.34,3.63-0.93c0.88-0.48,0.29-0.19,0.98,0.5c0.7,0.7,1.83,0.7,2.52,0c0.35-0.35,0.52-0.8,0.52-1.26
			C19.93,18.92,19.76,18.46,19.41,18.11z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M10.58,13.19c0.99,0,1.78-0.8,1.78-1.78s-0.8-1.78-1.78-1.78
			s-1.78,0.8-1.78,1.78C8.79,12.39,9.59,13.19,10.58,13.19z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FFFFFF"
          d="M13.5,18.46c0.99,0,1.78-0.8,1.78-1.78
			c0-0.98-0.8-1.78-1.78-1.78c-0.98,0-1.78,0.8-1.78,1.78C11.71,17.66,12.51,18.46,13.5,18.46z"
        />
      </g>
      <path
        fill="#403F41"
        d="M11.19,16.68c0,1.27,1.03,2.31,2.31,2.31c1.27,0,2.31-1.03,2.31-2.31c0-1.27-1.04-2.31-2.31-2.31
		C12.22,14.37,11.19,15.41,11.19,16.68z M13.5,15.42c0.69,0,1.26,0.56,1.26,1.26c0,0.69-0.56,1.26-1.26,1.26
		c-0.69,0-1.26-0.56-1.26-1.26C12.24,15.99,12.8,15.42,13.5,15.42z"
      />
      <path
        fill="#403F41"
        d="M10.58,9.1c-1.27,0-2.31,1.04-2.31,2.31s1.04,2.31,2.31,2.31c1.27,0,2.31-1.04,2.31-2.31
		S11.85,9.1,10.58,9.1z M10.58,12.66c-0.69,0-1.26-0.56-1.26-1.26c0-0.69,0.56-1.26,1.26-1.26c0.69,0,1.26,0.56,1.26,1.26
		S11.27,12.66,10.58,12.66z"
      />
      <path
        fill="#403F41"
        d="M19.78,21c0.44-0.44,0.68-1.01,0.68-1.63c0-0.62-0.24-1.2-0.68-1.63c-0.15-0.15-0.3-0.26-0.42-0.33
		c0.57-1.04,0.91-2.2,0.99-3.38h2.6c0.24,1.02,1.16,1.78,2.25,1.78c1.27,0,2.31-1.04,2.31-2.31s-1.04-2.31-2.31-2.31
		c-1.09,0-2.01,0.76-2.25,1.78h-2.6c-0.1-1.6-0.66-3.1-1.64-4.38c-0.11-0.15-0.23-0.29-0.35-0.43l1.57-1.57
		c0.37,0.23,0.79,0.35,1.22,0.35c0.59,0,1.18-0.22,1.63-0.67c0.44-0.44,0.68-1.02,0.68-1.63s-0.24-1.2-0.68-1.63
		c-0.9-0.9-2.37-0.9-3.27,0c-0.44,0.44-0.68,1.01-0.68,1.63c0,0.44,0.12,0.86,0.35,1.22l-1.57,1.57c-0.81-0.71-1.77-1.26-2.79-1.6
		c-0.1-1.32-1.2-2.36-2.54-2.36c-1.34,0-2.44,1.04-2.54,2.35C8.71,6.17,7.76,6.72,6.95,7.44L5.67,6.17C5.9,5.8,6.02,5.39,6.02,4.95
		c0-0.62-0.24-1.2-0.68-1.63C4.91,2.88,4.33,2.64,3.71,2.64s-1.2,0.24-1.63,0.68C1.64,3.75,1.4,4.33,1.4,4.95s0.24,1.2,0.68,1.63
		c0.45,0.45,1.04,0.67,1.63,0.67c0.42,0,0.85-0.12,1.22-0.35l1.28,1.28c-0.78,0.89-1.36,1.95-1.7,3.1c-0.2-0.06-0.43-0.09-0.7-0.09
		c-1.27,0-2.31,1.04-2.31,2.31s1.04,2.31,2.31,2.31c0.23,0,0.46-0.03,0.68-0.1c0.33,1.16,0.92,2.23,1.7,3.13l-2.17,2.17
		c-0.89-0.55-2.08-0.44-2.85,0.33c-0.9,0.9-0.9,2.36,0,3.26c0.45,0.45,1.04,0.67,1.63,0.67c0.59,0,1.18-0.22,1.63-0.67
		c0.77-0.77,0.88-1.96,0.33-2.85l2.17-2.17c0.28,0.24,0.57,0.47,0.88,0.67c0.01,0.01,0.02,0.02,0.04,0.02l0.02,0.01
		c0.24,0.17,0.56,0.11,0.73-0.13c0.17-0.24,0.11-0.56-0.13-0.73c-0.01-0.01-0.03-0.02-0.04-0.03l-0.02-0.01c0,0-0.01-0.01-0.01-0.01
		c-0.39-0.25-0.75-0.54-1.07-0.87c-0.01-0.01-0.02-0.02-0.03-0.03c-0.01-0.01-0.02-0.02-0.03-0.03c-0.97-0.98-1.64-2.23-1.9-3.6
		c-0.03-0.18-0.16-0.32-0.32-0.39c-0.17-0.07-0.35-0.04-0.5,0.06c-0.22,0.15-0.47,0.24-0.73,0.24c-0.69,0-1.26-0.56-1.26-1.26
		c0-0.69,0.56-1.26,1.26-1.26c0.24,0,0.42,0.03,0.53,0.09l0.01,0.01c0.16,0.09,0.39,0.22,0.67,0.1c0.29-0.12,0.36-0.39,0.43-0.67
		c0.65-2.43,2.43-4.3,4.76-5.01c0.03-0.01,0.06-0.02,0.08-0.02c0.43-0.11,0.5-0.42,0.48-0.65c0-0.02,0-0.04,0-0.08
		c0-0.83,0.67-1.5,1.5-1.5c0.83,0,1.5,0.67,1.5,1.5c0,0.01,0,0.01,0,0.03c-0.04,0.34,0.13,0.6,0.45,0.69c3,0.86,5.09,3.64,5.09,6.76
		c0,1.25-0.33,2.47-0.95,3.54c-0.13,0.22-0.28,0.48-0.17,0.79c0.1,0.28,0.34,0.36,0.47,0.41c0.11,0.04,0.23,0.08,0.39,0.24
		c0.24,0.24,0.37,0.55,0.37,0.89s-0.13,0.65-0.37,0.89c-0.49,0.49-1.29,0.49-1.78,0c-0.19-0.19-0.23-0.31-0.26-0.41
		c-0.04-0.11-0.11-0.34-0.38-0.44c-0.28-0.11-0.5,0.01-0.88,0.22l-0.08,0.04c-1.03,0.57-2.2,0.86-3.38,0.86
		c-0.68,0-1.33-0.14-1.94-0.28c-0.28-0.06-0.56,0.11-0.63,0.39c-0.06,0.28,0.11,0.56,0.39,0.63c0.5,0.11,1.06,0.23,1.65,0.28v1.4
		c-1.02,0.24-1.78,1.15-1.78,2.25c0,1.27,1.04,2.31,2.31,2.31c1.27,0,2.31-1.04,2.31-2.31c0-1.09-0.76-2.01-1.78-2.25v-1.4
		c1.18-0.08,2.33-0.41,3.36-0.98l0.02-0.01c0.08,0.12,0.18,0.27,0.33,0.42c0.45,0.45,1.04,0.67,1.63,0.67
		C18.74,21.68,19.33,21.45,19.78,21z M3.7,23.86c-0.49,0.49-1.29,0.49-1.78,0c-0.49-0.49-0.49-1.29,0-1.78
		c0.25-0.25,0.57-0.37,0.89-0.37c0.32,0,0.64,0.12,0.89,0.37C4.19,22.57,4.19,23.37,3.7,23.86L3.7,23.86z M2.82,5.84
		C2.58,5.6,2.45,5.28,2.45,4.95c0-0.34,0.13-0.65,0.37-0.89c0.24-0.24,0.55-0.37,0.89-0.37S4.36,3.82,4.6,4.06
		C4.84,4.3,4.97,4.61,4.97,4.95c0,0.34-0.13,0.65-0.37,0.89c0,0,0,0,0,0C4.11,6.33,3.31,6.33,2.82,5.84z M20.25,3.75
		c0.49-0.49,1.29-0.49,1.78,0c0.24,0.24,0.37,0.55,0.37,0.89s-0.13,0.65-0.37,0.89c-0.49,0.49-1.29,0.49-1.78,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.24-0.24-0.37-0.55-0.37-0.89C19.89,4.31,20.02,3.99,20.25,3.75z M25.19,12.25c0.69,0,1.26,0.56,1.26,1.26
		c0,0.69-0.56,1.26-1.26,1.26c-0.69,0-1.26-0.56-1.26-1.26C23.93,12.81,24.5,12.25,25.19,12.25z M13.53,25.22
		c0,0.69-0.56,1.26-1.26,1.26c-0.69,0-1.26-0.56-1.26-1.26c0-0.69,0.56-1.26,1.26-1.26C12.97,23.96,13.53,24.52,13.53,25.22z"
      />
    </g>
  </svg>
);
