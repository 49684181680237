import { Typography } from 'antd';
import { parse } from 'query-string';
import React, { useEffect, useContext } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  withRouter,
  useRouteMatch,
} from 'react-router';

import { ReactComponent as Logo } from 'assets/svg/manage24.svg';
import { DEV_LOGIN_CREDENTIALS } from 'constants/env';
import RootStoreContext from 'context/RootStoreContext';
import Copyright from 'modules/Login/components/Copyright';
import { getValueOfFeatureFlag } from 'utils/appUtils';

import CollectNorwegianBankId from './components/CollectNorwegianBankId';
import DevLogin from './components/DevLogin';
import { NewLoginPage } from './components/NewLoginDesign';
import OldLoginMethods from './components/OldLoginMethods';
import SelectPartner from './components/SelectPartner';
import SSOAuthCollect from './components/SSOAuthCollect';
import styles from './Login.module.css';

interface Props extends RouteComponentProps, WrappedComponentProps {}

const Login = ({ location, history, intl }: Props) => {
  const { authStore, flashMessageService, partnersStore } = useContext(RootStoreContext);
  const { path } = useRouteMatch();

  useEffect(() => {
    if (authStore.isAuthenticated()) {
      if (!partnersStore.partnerId) {
        location.pathname !== '/login/select-partner' && history.push('/login/select-partner');
      } else {
        history.push('/');
      }
    }

    if (location.search) {
      const query = parse(location.search);

      if (query.messageKey) {
        flashMessageService.error(intl.formatMessage({ id: query.messageKey }));
      }
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.loginSection} data-testid="login-section">
        <div className={styles.header}>
          <Logo className={styles.logo} />
          <Typography.Title level={2} data-testid="login-header" className={styles.title}>
            <FormattedMessage id="general.welcome" />
          </Typography.Title>
        </div>
        <div className={styles.content}>
          <Switch>
            <Route
              exact
              path={`${path}/dev`}
              component={DEV_LOGIN_CREDENTIALS.length ? DevLogin : () => <Redirect to={path} />}
            />
            <Route
              exact
              path={`${path}/collect/norwegian-bank-id`}
              component={CollectNorwegianBankId}
            />
            <Route exact path={`${path}/select-partner`} component={SelectPartner} />
            <Route exact path={`${path}/practitioner-auth/collect`} component={SSOAuthCollect} />
            <Route exact path={path} component={OldLoginMethods} />
            <Route path={path} render={() => <Redirect to={path} />} />
          </Switch>
        </div>
      </div>
      <div className={styles.copyright}>
        <Copyright />
      </div>
    </div>
  );
};

const OldLoginDesign = injectIntl(withRouter(Login));

export default function LoginPageSplit() {
  const newDesignEnabled = getValueOfFeatureFlag('FF_NEW_LOGIN_DESIGN_MANAGE24');
  if (newDesignEnabled) {
    return <NewLoginPage />;
  }
  return <OldLoginDesign />;
}
