import { BranchesOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './CarePathways.module.css';
import { usePathwaysProps } from './carePathways.utils';
import withRootStoreProp from '../../components/HOC/withRootStoreProp';
import PageHeader from '../../components/PageHeader';
import RootStore from '../../stores/RootStore';
import RemoteComponent from '../../utils/remoteModules/remoteComponent';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

const CarePathwaysOverviewPage = ({ rootStore }: { rootStore: RootStore }) => {
  const pathwayTemplatesOverviewProps = usePathwaysProps(rootStore);

  return (
    <>
      <PartnerStatus />
      <div className={styles.container}>
        <PageHeader
          content="carePathways.page-title"
          breadcrumbs={[
            {
              icon: <BranchesOutlined />,
              text: <FormattedMessage id="carePathways.page-title" />,
              link: '/carePathways',
            },
          ]}
        />

        <React.Suspense fallback={<Skeleton />}>
          <RemoteComponent
            suspense
            module="pathwaysManage/PathwayTemplatesOverview"
            props={pathwayTemplatesOverviewProps}
          />
        </React.Suspense>
      </div>
    </>
  );
};

export default withRootStoreProp(CarePathwaysOverviewPage);
