import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import classNames from 'classnames';
import { FieldArray } from 'formik';
import { Form, Input } from 'formik-antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { BASIC_DETAILS_FORM_FIELDS } from 'constants/origins';

import styles from '../OriginsDetailsForm.module.css';

interface Props {
  isSaving: boolean;
  isDisabled: boolean;
  urlSuffixes: string[];
  required: boolean;
}

const UrlSuffixesList = ({ isSaving, isDisabled, urlSuffixes, required }: Props) => (
  <div className={classNames('ant-form-vertical', styles.fieldArray)}>
    <FieldArray
      name={BASIC_DETAILS_FORM_FIELDS.URL_SUFFIXES}
      render={arrayHelpers => (
        <>
          {urlSuffixes.map((_, index) => (
            <Row key={index} gutter={16}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  key={index}
                  label={
                    index === 0 && (
                      <FormattedMessage id="origin.basic-details-form.url-suffix-label" />
                    )
                  }
                  name={`${BASIC_DETAILS_FORM_FIELDS.URL_SUFFIXES}.${index}`}
                  required={required}
                >
                  <Input
                    disabled={isSaving || isDisabled}
                    name={`${BASIC_DETAILS_FORM_FIELDS.URL_SUFFIXES}.${index}`}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                {index > 0 && (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={isSaving || isDisabled}
                  />
                )}
              </Col>
            </Row>
          ))}
          <Button
            type="link"
            onClick={() => {
              arrayHelpers.push('');
            }}
            className={styles.addButton}
            disabled={isSaving || isDisabled}
          >
            + <FormattedMessage id="general.add-more" />
          </Button>
        </>
      )}
    />
  </div>
);

export default UrlSuffixesList;
