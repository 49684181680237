import { Button, Divider, Modal, Popconfirm } from 'antd';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Image } from 'components/Image';
import {
  getEntityPlacementDataByKey,
  getImageIdFromFileName,
} from 'components/RichTextEditor/utils';
import RootStoreContext from 'context/RootStoreContext';

import styles from './AddImageModal.module.css';

interface AddImageModalProps {
  onCancel: () => void;
  onChange: (editorState: EditorState) => void;
  editorState: EditorState;
  imageEntityKey?: string;
}

type ImageFormData = {
  src: string;
  alt: string;
};

export function AddImageModal(props: AddImageModalProps) {
  const isModalVisible = props.imageEntityKey !== undefined;

  return (
    <Modal
      visible={isModalVisible}
      destroyOnClose
      title={<FormattedMessage id="images.image" />}
      footer={null}
      onCancel={props.onCancel}
    >
      <ModalContent {...(props as AddImageModalContentProps)} />
    </Modal>
  );
}

interface AddImageModalContentProps extends AddImageModalProps {
  imageEntityKey: string;
}

function ModalContent({
  editorState,
  imageEntityKey,
  onChange,
  onCancel,
}: AddImageModalContentProps) {
  const intl = useIntl();
  const {
    partnersStore: { imageStore },
  } = useContext(RootStoreContext);
  const currentContentState = editorState.getCurrentContent();
  const entity = currentContentState.getEntity(imageEntityKey);
  // newly created image has no correspondent entity yet
  const entityData = entity ? entity.getData() : {};

  const initialValues = { src: entityData.src || '', alt: entityData.alt || '' };
  const validationSchema = Yup.object().shape({
    alt: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
    src: Yup.string().required(
      intl.formatMessage({
        id: 'general.errors.required',
      })
    ),
  });

  const handleDelete = () => {
    const entityPlacementData = getEntityPlacementDataByKey(imageEntityKey, editorState);

    if (!entityPlacementData) {
      // just exit, as there is no correspondent block with entity in editor state yet (i.e. entity was newly created)
      onCancel();
    } else {
      const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
      const updatedSelection = newSelection.merge({
        anchorKey: entityPlacementData.blockKey,
        focusKey: entityPlacementData.blockKey,
        anchorOffset: entityPlacementData.anchor,
        focusOffset: entityPlacementData.focus,
      });
      const currentContentStateWithoutEntity = Modifier.applyEntity(
        currentContentState,
        updatedSelection,
        null
      );
      const newContentState = Modifier.replaceText(
        currentContentStateWithoutEntity,
        updatedSelection,
        ''
      );
      const newEditorState = EditorState.set(editorState, { currentContent: newContentState });
      onChange(newEditorState);
    }
  };

  const handleSubmit = ({ src, alt }: ImageFormData) => {
    const entityPlacementData = getEntityPlacementDataByKey(imageEntityKey, editorState);

    // We're updating existing content
    if (entityPlacementData) {
      // Generally, it should work with something like:
      // const newContentState = currentContentState.replaceEntityData(imageEntityKey, { src, alt });
      // ...
      // Unfortunately, it does not refresh the editor image content immediately, only after text focus
      // is placed next to the image.
      // Details: https://github.com/facebookarchive/draft-js/issues/1702
      // Because of that, we take the hard way: first remove entity and its corresponding text, then add
      // it again with modified params.
      const newSelection = SelectionState.createEmpty(entityPlacementData.blockKey);
      const updatedSelection = newSelection.merge({
        anchorKey: entityPlacementData.blockKey,
        focusKey: entityPlacementData.blockKey,
        anchorOffset: entityPlacementData.anchor,
        focusOffset: entityPlacementData.focus,
      });
      const currentContentStateWithoutEntity = Modifier.applyEntity(
        currentContentState,
        updatedSelection,
        null
      );
      const currentContentStateWithoutText = Modifier.replaceText(
        currentContentStateWithoutEntity,
        updatedSelection,
        ''
      );
      const newContentStateWithNewEntity = currentContentStateWithoutText.createEntity(
        'IMAGE',
        'MUTABLE',
        {
          src,
          alt,
        }
      );
      const entityKey = newContentStateWithNewEntity.getLastCreatedEntityKey();
      const newContentStateWithNewText = Modifier.insertText(
        newContentStateWithNewEntity,
        newSelection,
        '📷',
        undefined,
        entityKey
      );
      const newEditorState = EditorState.set(editorState, {
        currentContent: newContentStateWithNewText,
      });
      onChange(newEditorState);
      // We're creating new content
    } else {
      const currentSelection = editorState.getSelection();
      const currentContentStateWithEntity = currentContentState.createEntity('IMAGE', 'MUTABLE', {
        src,
        alt,
      });
      const entityKey = currentContentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = Modifier.insertText(
        currentContentStateWithEntity,
        currentSelection,
        '📷',
        undefined,
        entityKey
      );
      const newEditorState = EditorState.set(editorState, { currentContent: newContentState });

      onChange(newEditorState);
    }
  };

  useEffect(() => {
    imageStore.fetchImages();
  }, [imageStore]);

  return (
    <Observer>
      {() => {
        const imageOptions = imageStore.images.map(image => {
          const src = getImageIdFromFileName(image.fileName);
          return {
            value: src,
            label: image.metadata.title[intl.locale] || image.fileName,
          };
        });

        return (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ isValid, dirty, values, submitForm }) => (
              <Form>
                <Form.Item label={<FormattedMessage id="images.image" />} name="src" required>
                  <Select name="src" showSearch optionFilterProp="label" options={imageOptions} />
                </Form.Item>
                <Form.Item label={<FormattedMessage id="images.alt-text" />} name="alt" required>
                  <Input name="alt" />
                </Form.Item>
                <Image src={values.src} alt={values.alt} />
                <Divider />
                <div className={styles.wrapper}>
                  <div>
                    <Popconfirm
                      title={<FormattedMessage id="general.sure-to-delete" />}
                      cancelText={<FormattedMessage id="general.cancel" />}
                      onConfirm={handleDelete}
                    >
                      <Button shape="round">
                        <FormattedMessage id="general.delete" />
                      </Button>
                    </Popconfirm>
                  </div>
                  <div>
                    {dirty ? (
                      <Popconfirm
                        title={<FormattedMessage id="general.sure-to-cancel" />}
                        cancelText={<FormattedMessage id="general.cancel" />}
                        onConfirm={onCancel}
                      >
                        <Button shape="round" className={styles.btn}>
                          <FormattedMessage id="general.cancel" />
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button shape="round" className={styles.btn} onClick={onCancel}>
                        <FormattedMessage id="general.cancel" />
                      </Button>
                    )}
                    <Button
                      shape="round"
                      type="primary"
                      disabled={!dirty || !isValid}
                      onClick={submitForm}
                    >
                      <FormattedMessage id="general.save" />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        );
      }}
    </Observer>
  );
}
