import { ENV, CURRENT_ENV_TYPE } from 'constants/env';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { ErrorReporter, FlashMessageService } from 'types/types';

import { D24TrackedError } from '../D24ErrorReporting';

const TRACKED_ERROR_ENVS = [ENV.LOCAL, ENV.DEV];

export class FlashMessageReporter implements ErrorReporter {
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private flashMessage: FlashMessageService<any, any>) {}

  report(error: D24TrackedError): void {
    if (TRACKED_ERROR_ENVS.includes(CURRENT_ENV_TYPE)) {
      this.flashMessage.trackedError(error);
    } else if (!this.flashMessage.getActiveMessagesByType('error')) {
      this.showQualifiedMessage(error);
    }
  }

  private showQualifiedMessage = (error: D24TrackedError) => {
    if (!error.meta) {
      this.flashMessage.trackedError(error);
      return;
    }

    if (error.meta.status === 404) {
      this.flashMessage.translatedError('general.errors.resource-not-found', {
        autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT,
      });
      return;
    }

    this.flashMessage.translatedError('general.error', {
      autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT,
    });
  };
}
