import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { parse } from 'query-string';
import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useLocation, Redirect } from 'react-router';

import RootStoreContext from 'context/RootStoreContext';

const CollectNorwegianBankId = () => {
  const { authStore } = useContext(RootStoreContext);
  const [isLoaded, handleLoadedChange] = useState(false);
  const location = useLocation();

  useEffect(() => {
    async function login() {
      try {
        const { code, state } = parse(location.search.slice(1));
        await authStore.loginWithNorwegianBankId(code, state);
        /* eslint-disable no-empty */
      } catch (error: any) {
      } finally {
        handleLoadedChange(true);
      }
    }

    login();
  }, [authStore, location]);

  return (
    <Fragment>
      {isLoaded ? (
        <Redirect to="/login/select-partner" />
      ) : (
        <Spin size="large" indicator={<LoadingOutlined spin />} />
      )}
    </Fragment>
  );
};

export default CollectNorwegianBankId;
