import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { FunctionComponent, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';

import { Section } from '../../models/Code24Model';

/**
 * @notExported
 */
interface AddSectionFormProps {
  data?: Section;
  availableSections: Section[];
  isSaving: boolean;
  onCancel: () => void;
  onSubmit: (data: Section) => void;
  isDisabled?: boolean;
}

const AddSectionForm: FunctionComponent<AddSectionFormProps> = ({
  data,
  isSaving,
  availableSections,
  onCancel,
  onSubmit,
  isDisabled,
}) => {
  const intl = useIntl();
  const handleSubmit = useCallback(
    async values => {
      onSubmit(availableSections.find(({ id }) => id === values.section)!);
    },
    [onSubmit, availableSections]
  );

  const isFormDisabled = isDisabled || isSaving;

  return (
    <Modal
      visible={!!data}
      destroyOnClose
      title={<FormattedMessage id="condition-edit.add-block-header" />}
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={{ section: data?.id }}
        onSubmit={handleSubmit}
        isInitialValid
        enableReinitialize
      >
        {({ isValid, dirty }) => (
          <Form layout="vertical">
            <Form.Item
              name="section"
              label={<FormattedMessage id="condition-edit.add-block-type" />}
            >
              <Select
                name="section"
                disabled={isFormDisabled}
                options={availableSections.map(({ id, type }) => ({
                  value: id,
                  label: intl.formatMessage({ id: `condition.type-${type}` }),
                }))}
              />
            </Form.Item>
            <Divider />
            <FormActionButtons
              isSaving={isSaving}
              isValid={isValid}
              onCancel={onCancel}
              showCancelConfirm={dirty}
              cancelDeclineText={<FormattedMessage id="condition-edit.statement-cancel-confirm" />}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddSectionForm;
