import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { D24TrackedError } from 'services/ErrorReporting/D24ErrorReporting';

import styles from './FlashMessageError.module.css';

interface FlashMessageErrorProps {
  error: D24TrackedError;
}

export const renderFlashMessageError = (error: D24TrackedError) => (
  <FlashMessageError error={error} />
);

const renderErrorMeta = (meta: Record<string, string | number>) => {
  return Object.keys(meta).map(key => (
    <p key={key}>
      {key} <pre className={styles.metaText}>{meta[key]}</pre>
    </p>
  ));
};

const renderError = (error: D24TrackedError) => error.message;

export const FlashMessageError: React.FC<FlashMessageErrorProps> = ({ error }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      <div onClick={() => setIsExpanded(!isExpanded)}>{renderError(error)}</div>
      {isExpanded && (
        <div className={styles.expanded}>
          <p>
            id <pre className={styles.metaText}>{error.id}</pre>
          </p>
          {error.meta && renderErrorMeta(error.meta)}
        </div>
      )}
      <div className={styles.detailsBtnWrapper}>
        {isExpanded ? (
          <button className={styles.detailsBtn} onClick={() => setIsExpanded(false)}>
            <FormattedMessage id="general.hide-details" />
          </button>
        ) : (
          <button className={styles.detailsBtn} onClick={() => setIsExpanded(true)}>
            <FormattedMessage id="general.check-details" />
          </button>
        )}
      </div>
    </div>
  );
};
