import uniqBy from 'lodash/uniqBy';
import { observable, IObservableArray, runInAction, computed } from 'mobx';

import {
  fetchCarePlanGroupPractitioners,
  addPractitioner,
  removePractitioner,
  CarePlanGroupPractitionersListItem,
  PractitionerIdName,
  fetchPractitionerIdNames,
} from 'modules/You24/CarePlanGroups/api/carePlanGroupsApi';
import StateManager from 'stores/abstractStores/StateManager';
import RootStore from 'stores/RootStore';

export default class CarePlanGroupPractitionersStore extends StateManager {
  @observable
  practitioners: IObservableArray<CarePlanGroupPractitionersListItem> = observable.array([]);

  @observable
  allPractitioners: IObservableArray<PractitionerIdName> = observable.array([]);

  @computed
  get availablePractitioners() {
    return this.allPractitioners.filter(
      ({ id }) =>
        this.practitioners.find(({ practitionerId }) => practitionerId === id) === undefined
    );
  }

  constructor(private rootStore: RootStore) {
    super();
  }

  fetchCarePlanGroupPractitioners = async (groupId: string) => {
    const { partnersStore } = this.rootStore;

    this.setLoading();

    try {
      const { data: practitioners } = await fetchCarePlanGroupPractitioners(
        partnersStore.partnerId,
        groupId
      );

      this.setLoaded();

      runInAction(() => {
        this.practitioners.replace(practitioners);
      });
    } catch (e) {
      this.setError();
      throw e;
    }
  };

  fetchPractitioners = async (careUnitIds: string[]) => {
    this.setLoading();

    try {
      const { data } = await fetchPractitionerIdNames(careUnitIds);

      const practitioners = uniqBy(data, 'id');

      this.setLoaded();

      runInAction(() => {
        this.allPractitioners.replace(practitioners);
      });
    } catch (e) {
      this.setError();
      throw e;
    }
  };

  addPractitioner = async (groupId: string, practitioners: string[]) => {
    const { partnersStore } = this.rootStore;

    this.setSaving();

    try {
      await addPractitioner(
        partnersStore.partnerId,
        groupId,
        practitioners.map(id => ({ practitionerId: id }))
      );

      runInAction(() => {
        this.fetchCarePlanGroupPractitioners(groupId);
      });

      this.setLoaded();
    } catch (e) {
      this.setError();
      throw e;
    }
  };

  removePractitioner = async (groupId: string, practitionerId: string) => {
    const { partnersStore } = this.rootStore;

    try {
      await removePractitioner(partnersStore.partnerId, groupId, practitionerId);

      const practitionerToRemove = this.practitioners.find(p => p.id === practitionerId);

      if (practitionerToRemove === undefined) {
        throw new Error('No practitioner exists for provided practitionerId');
      }

      runInAction(() => {
        this.practitioners.remove(practitionerToRemove);
      });
    } catch (e) {
      this.setError();
      throw e;
    }
  };
}
