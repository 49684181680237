import { stringify } from 'query-string';

import {
  authenticateWithSITHS,
  authenticateWithHsaIdDev,
  authenticateWithNorwegianBankIdDev,
  authenticateWithSwedishBankIdStart,
  authenticateWithSwedishBankIdCollect,
  authenticateWithNorwegianBankId,
  getLoginMethods,
  collectSSOToken,
  changePartner,
} from 'api/authApi';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { SWEDISH_BANK_ID_TOKEN_COLLECT_INTERVAL, LOGIN_METHODS, LOGIN_SSO } from 'constants/login';
import { setToken, getToken, removeToken } from 'utils/tokenUtils';

import RootStore from './RootStore';
export default class AuthStore {
  constructor(private rootStore: RootStore) {}

  doLogin = async (loginMethod: LOGIN_METHODS, token: string) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, loginMethod);
      setToken(token);

      await this.checkUserPermissions();
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  loginWithDevHsaId = async (hsaId: string, isRefreshingToken?: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, LOGIN_METHODS.DEV_HSA_ID);
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEV_HSA_ID, hsaId);

    try {
      const {
        data: { token },
      } = await authenticateWithHsaIdDev(hsaId);
      setToken(token);

      !isRefreshingToken && (await this.checkUserPermissions());
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  partnerSwitch = async (partnerId: string) => {
    try {
      const {
        data: { token },
      } = await changePartner(partnerId);

      localStorage.setItem(LOCAL_STORAGE_KEYS.PARTNER_ID, partnerId);
      setToken(token);
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  loginWithDevNorwegianBankId = async (norwegianBankId: string, isRefreshingToken?: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, LOGIN_METHODS.DEV_NORWEGIAN_BANK_ID);
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEV_NORWEGIAN_BANK_ID, norwegianBankId);

    try {
      const {
        data: { token },
      } = await authenticateWithNorwegianBankIdDev(norwegianBankId);
      setToken(token);
      !isRefreshingToken && (await this.checkUserPermissions());
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  loginWithHsaId = async (isRefreshingToken?: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, LOGIN_METHODS.HSA_ID);

    try {
      const {
        data: { token },
      } = await authenticateWithSITHS();
      setToken(token);
      !isRefreshingToken && (await this.checkUserPermissions());
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  loginWithSwedishBankId = async (bankId: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, LOGIN_METHODS.SWEDISH_BANK_ID);

    try {
      const {
        data: { orderRef },
      } = await authenticateWithSwedishBankIdStart(bankId);
      const token: string = await new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          try {
            const {
              data: { token },
            } = await authenticateWithSwedishBankIdCollect(orderRef);

            if (token) {
              clearInterval(intervalId);
              resolve(token);
            }
          } catch (error) {
            reject(error);
          }
        }, SWEDISH_BANK_ID_TOKEN_COLLECT_INTERVAL);
      });
      setToken(token);

      await this.checkUserPermissions();
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  loginWithNorwegianBankId = async (code: string, state: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, LOGIN_METHODS.NORWEGIAN_BANK_ID);

    try {
      const {
        data: { token },
      } = await authenticateWithNorwegianBankId(code, state);
      setToken(token);
      await this.checkUserPermissions();
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'general.error' }) });
    }
  };

  async checkUserPermissions() {
    const { partnersStore, userPermissionsStore } = this.rootStore;
    const partners = await partnersStore.fetchPartners();
    if (partners && partners.length === 1) {
      await this.partnerSwitch(partners[0].id);
    }

    userPermissionsStore.setUserRolesFromJWT();

    if (!userPermissionsStore.isAllowedUser) {
      this.logout({ errorMessage: stringify({ messageKey: 'auth.not-admin-error' }) });
    }
  }

  initiateSSOLogin = (loginMethod: LOGIN_METHODS) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD, loginMethod);
    if (loginMethod === LOGIN_METHODS.OPENIDCONNECT) {
      window.location.assign(LOGIN_SSO.OPENID);
    }
    if (loginMethod === LOGIN_METHODS.SAML) {
      window.location.assign(LOGIN_SSO.SAML);
    }
  };

  collectSSOLogin = async () => {
    try {
      const {
        data: { token },
      } = await collectSSOToken();
      setToken(token);
      await this.checkUserPermissions();
    } catch {
      this.logout({ errorMessage: stringify({ messageKey: 'login.error.failed' }) });
    }
  };

  getLoginMethods = async () => {
    try {
      const { data } = await getLoginMethods();
      return data;
    } catch {
      return [];
    }
  };

  refreshToken = async () => {
    const loginMethod = localStorage.getItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD);

    // As for now, there are no silent refresh token methods implemented on
    // the backend side
    // See https://platform24.atlassian.net/browse/AX-7548
    switch (loginMethod) {
      case LOGIN_METHODS.DEV_HSA_ID: {
        const devHsaId = localStorage.getItem(LOCAL_STORAGE_KEYS.DEV_HSA_ID);
        return this.loginWithDevHsaId(devHsaId || '', true);
      }
      case LOGIN_METHODS.DEV_NORWEGIAN_BANK_ID: {
        const devNorwegianBankId = localStorage.getItem(LOCAL_STORAGE_KEYS.DEV_NORWEGIAN_BANK_ID);
        return this.loginWithDevNorwegianBankId(devNorwegianBankId || '', true);
      }

      case LOGIN_METHODS.HSA_ID:
        return this.loginWithHsaId(true);
      default:
        return this.logout();
    }
  };

  logout = (options: { errorMessage?: string } = {}) => {
    removeToken();
    localStorage.removeItem(LOCAL_STORAGE_KEYS.LOGIN_METHOD);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.DEV_HSA_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.DEV_NORWEGIAN_BANK_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.PARTNER_ID);

    if (options.errorMessage) {
      window.location.assign(`/login?${options.errorMessage}`);
    } else {
      window.location.reload();
    }
  };

  isAuthenticated = () => !!getToken();
}
