import { BankOutlined, CaretDownFilled, CaretRightFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';

import { AccessScopeRole } from 'api/permissionsApi';
import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import OptionalTooltip from 'components/OptionalTooltip';
import { ACCESS_SCOPE_TYPES } from 'constants/roles';

import styles from './ManagePersonalRoles.module.css';
import ManageRolesStore, { ManageViewScope } from '../../stores/ManageRolesStore';
import AddManageRoleModal from '../AddManageRoleModal';
import EditManageRoleModal from '../EditManageRoleModal';
import { isStarLabel } from '../utils';

interface Props extends RootStoreProps, RouteComponentProps<{ id: string }> {}

@observer
class ManagePersonalRoles extends Component<Props> {
  manageRolesStore: ManageRolesStore;

  constructor(props: Props) {
    super(props);
    this.manageRolesStore = new ManageRolesStore(props.rootStore, props.match.params.id);
  }

  componentDidMount() {
    this.manageRolesStore.fetchUserScopedRoles();
  }

  getColumns = (): ColumnsType<ManageViewScope> => {
    return [
      {
        title: <FormattedMessage id="roles.scope" />,
        render: this.renderScope,
      },
      {
        title: <FormattedMessage id="roles.role" />,
        render: (_: string, record: ManageViewScope) => {
          if ('roles' in record && record.roles !== undefined && !record?.children?.length) {
            return record.roles.map((r: AccessScopeRole, index: number) => (
              <div key={index}>
                <OptionalTooltip translationKey={`scoped-role.${r.name}.tooltip`}>
                  <FormattedMessage id={`roles.roleid.${r.name}`} />
                </OptionalTooltip>
              </div>
            ));
          }
          return null;
        },
      },
      {
        title: <FormattedMessage id="general.actions" />,
        width: 100,
        align: 'right',
        render: (_: string, record: ManageViewScope) => (
          <>
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() =>
                this.manageRolesStore.handleDeleteRole(record, this.props.match.params.id)
              }
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        ),
      },
    ];
  };

  renderScope = (_: string, record: ManageViewScope) => {
    const allText = <FormattedMessage id="roles.roleid.all" />;
    const isPartner = record.scopeType === ACCESS_SCOPE_TYPES.PARTNER;
    const isCareProvider = record.scopeType === ACCESS_SCOPE_TYPES.CARE_PROVIDER;
    const isOriginGroup =
      record.scopeType === ACCESS_SCOPE_TYPES.ORIGIN && record.children.length > 0;

    if (isPartner) {
      return (
        <>
          <BankOutlined className={styles.icon} />
          {record.partnerId}
        </>
      );
    }

    if (isCareProvider) {
      return (
        <>
          <FormattedMessage id="general.care-provider" />:{' '}
          {isStarLabel(record.scopeName) ? allText : record.scopeName}
        </>
      );
    }

    if (isOriginGroup) {
      return <FormattedMessage id={`access-scope.${record.scopeType}`} />;
    }
    const label = record.scopeName || record.scopeValue;

    const getAllText = (record: ManageViewScope) => {
      if (record.scopeType === ACCESS_SCOPE_TYPES.CARE_UNIT) {
        return (
          <>
            <FormattedMessage id="general.care-unit" />: {allText}
          </>
        );
      } else if (record.scopeType === ACCESS_SCOPE_TYPES.ORIGIN) {
        return (
          <>
            <FormattedMessage id="general.origin" />: {allText}
          </>
        );
      }
      return label;
    };
    return isStarLabel(label) ? getAllText(record) : label;
  };

  render() {
    const { userPermissionsStore } = this.props.rootStore;
    return (
      <>
        <div className={styles.header}>
          <Typography.Title level={3}>
            <FormattedMessage id="roles.manage24-roles" />
          </Typography.Title>
          <Button shape="round" type="primary" onClick={this.manageRolesStore.handleAddRole}>
            <FormattedMessage id="add-roles-form.add-btn" />
          </Button>
        </div>
        <Table
          columns={this.getColumns()}
          data-testid="managePersonalRolesTable"
          rowKey="uuid"
          dataSource={this.manageRolesStore.personalManageRoles}
          loading={this.manageRolesStore.isLoading || userPermissionsStore.isLoading()}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record.children?.length ? (
                <Button
                  type="link"
                  icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={e => onExpand(record, e)}
                />
              ) : (
                <span className={styles.spacer} />
              ),
          }}
          pagination={false}
        />
        {this.manageRolesStore.activeScopedRole && (
          <EditManageRoleModal
            data={this.manageRolesStore.activeScopedRole}
            onSubmit={this.manageRolesStore.handleUpdateRole}
            onClose={this.manageRolesStore.handleClearActiveRole}
          />
        )}
        {this.manageRolesStore.newRole && (
          <AddManageRoleModal
            data={this.manageRolesStore.newRole}
            editedUserScopedRoles={this.manageRolesStore.personalManageRoles}
            practitionerId={this.props.match.params.id}
            onSubmit={this.manageRolesStore.handleSaveAddedRole}
            onClose={this.manageRolesStore.handleClearActiveRole}
          />
        )}
      </>
    );
  }
}

export default withRootStoreProp(withRouter(ManagePersonalRoles));
