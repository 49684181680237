import {
  ApartmentOutlined,
  BankOutlined,
  HomeOutlined,
  LeftOutlined,
  LogoutOutlined,
  RightOutlined,
  TeamOutlined,
  UserOutlined,
  PicLeftOutlined,
  InfoCircleOutlined,
  FolderViewOutlined,
  BranchesOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Select, Button, Tooltip } from 'antd';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';

import logo from 'assets/svg/manage24.svg';
import logoCollapsed from 'assets/svg/manage24_small.svg';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { LANGS } from 'constants/enums';
import { IntlConsumer } from 'context/IntlContext';
import RootStoreContext from 'context/RootStoreContext';
import {
  canActivateContent24,
  canActivateCareUnits,
  canActivatePractitioners,
  canActivateOrigins,
  canEditCarePlanGroups,
  canViewRules,
  canViewCarePathways,
} from 'routes/routeGuards';
import { InputOption } from 'types/types';
import { getFirstLetters, getValueOfFeatureFlag, isEducationEnv } from 'utils/appUtils';

import Submenu, { SublinkItem } from './components/Submenu';
import styles from './MainNavigation.module.css';

const QUESTIONNAIRE_LINK_GROUPS = ['triage', 'diagnosis', 'assessment', 'libraries'];

interface Props extends RouteComponentProps {
  intl: IntlShape;
}

@observer
class MainNavigation extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  handleLogout = () => {
    this.context.authStore.logout();
    this.props.history.push('/login');
  };

  handleChangePartner = async (partnerId: string) => {
    await this.context.authStore.partnerSwitch(partnerId);
    // Reset the app with new partner context
    this.props.history.push('/');
    window.location.reload();
  };

  @computed get careUnitsLinks(): SublinkItem[] {
    return this.sortNavList(
      toJS(
        this.context.userDataStore.userCareUnits.map(elem => ({
          ...elem,
          slug: `/${elem.id}/basic-details`,
        }))
      )
    );
  }

  get content24Links(): SublinkItem[] {
    const conditionLinks = QUESTIONNAIRE_LINK_GROUPS.map(itemName => ({
      id: `/questionnaires?category=${itemName}`,
      slug: `/questionnaires?category=${itemName}`,
      name: this.props.intl.formatMessage({ id: `condition-list.category-${itemName}` }),
      testId: `main-navigation-content24-${itemName}`,
    }));
    const selfcareLink = {
      id: '/selfcare',
      slug: '/selfcare',
      name: this.props.intl.formatMessage({ id: 'selfcare.menu-item' }),
      testId: 'main-navigation-content24-selfcare',
    };
    const statisticsLink = {
      id: '/statistics',
      slug: '/statistics',
      name: this.props.intl.formatMessage({ id: 'statistics.header' }),
      testId: 'main-navigation-content24-statistics',
    };
    const testLink = {
      id: '/test',
      slug: '/test',
      name: this.props.intl.formatMessage({ id: 'test.menu-item' }),
      testId: 'main-navigation-content24-test',
    };
    const list = this.sortNavList(conditionLinks.concat(selfcareLink));

    if (this.context.partnersStore.partnerCustomizations.get('CODE24_STATISTICS_ENABLED')) {
      list.push(statisticsLink);
    }

    list.push(testLink);

    return list;
  }

  sortNavList = (list: SublinkItem[]) =>
    sortBy(list, elem => (elem.name ? elem.name.toLowerCase() : (elem.id || '').toLowerCase()));

  renderPartner = () => {
    const { partnersStore, layoutStore } = this.context;

    return partnersStore.partners.length > 1 ? (
      <Select
        className={styles.select}
        defaultValue={partnersStore.partnerId}
        onSelect={this.handleChangePartner}
        showSearch
      >
        {partnersStore.partnersAsSelectOptions.map((opt: InputOption) => (
          <Select.Option key={opt.value} value={opt.value}>
            {layoutStore.collapsed ? (
              <Tooltip title={opt.label} placement="right">
                <span>{opt.label}</span>
              </Tooltip>
            ) : (
              opt.label
            )}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <p className={styles.soloPartner}>{partnersStore.currentPartner?.id}</p>
    );
  };

  render() {
    const {
      userDataStore: userStore,
      layoutStore,
      partnersStore,
      userPermissionsStore,
    } = this.context;
    const { location, intl } = this.props;
    const activeMenuKey = location.pathname.split('/')[1];
    const languageOptions = Object.values(LANGS)
      .filter(language => language !== LANGS.DK) // excluding DK because we do not have the translations
      .map(value => ({
        value,
        label: intl.formatMessage({ id: `general.language-original-${value}` }),
      }));

    const IS_EDUCATION_ENV = isEducationEnv();
    const FF_ENABLE_NEW_ROLES = getValueOfFeatureFlag('FF_ENABLE_NEW_ROLES');

    return (
      <Layout.Sider
        trigger={null}
        collapsible
        collapsed={layoutStore.collapsed}
        theme="light"
        className={classNames(styles.container, { [styles.isCollapsed]: layoutStore.collapsed })}
      >
        <div className={styles.sliderChildrenWrapper}>
          <div className={classNames(styles.menuItem, styles.logoContainer)}>
            <NavLink to="/">
              <img
                src={layoutStore.collapsed ? logoCollapsed : logo}
                alt={intl.formatMessage({ id: 'general.logo' })}
                className={styles.logo}
              />
            </NavLink>
          </div>
          <div data-testid="user-name">
            {layoutStore.collapsed ? (
              <Tooltip title={userStore.fullName} placement="right">
                <div
                  data-testid="main-navigation-logout"
                  className={classNames(styles.menuItem, styles.userName)}
                >
                  {getFirstLetters(userStore.fullName)}
                </div>
              </Tooltip>
            ) : (
              <div className={styles.menuItem}>
                <div className={styles.userIcon}>
                  <UserOutlined />
                </div>
                {userStore.fullName}
              </div>
            )}
            <div className={styles.partnerContainer}>
              <p className={styles.partnerLabel}>
                <FormattedMessage id="general.partner" />:
              </p>
              {layoutStore.collapsed ? (
                <Tooltip title={partnersStore.currentPartner?.id} placement="right">
                  {this.renderPartner()}
                </Tooltip>
              ) : (
                this.renderPartner()
              )}
            </div>
          </div>

          {userPermissionsStore.isLoaded() && (
            <Menu
              mode="vertical"
              selectable={false}
              className={styles.menuContainer}
              selectedKeys={[activeMenuKey]}
            >
              {canActivateOrigins(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Menu.Item className={styles.linkContainer} key="origins">
                  <NavLink to="/origins">
                    <HomeOutlined
                      className={styles.linkIcon}
                      data-testid="main-navigation-origins"
                    />
                    <span>
                      <FormattedMessage id="general.origin" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              {canActivateCareUnits(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Menu.Item className={styles.linkContainer} key="care-providers">
                  <NavLink to="/care-providers">
                    <BankOutlined
                      className={styles.linkIcon}
                      data-testid="main-navigation-care-units"
                    />
                    <span>
                      <FormattedMessage id="main-navigation.care-units" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              {canActivatePractitioners(userPermissionsStore) && (
                <Menu.Item className={styles.linkContainer} key="practitioners">
                  <NavLink to={FF_ENABLE_NEW_ROLES ? '/roles' : '/practitioners'}>
                    <TeamOutlined data-testid="main-navigation-practitioners" />
                    <span>
                      <FormattedMessage id="main-navigation.practitioners" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              {/* Activate when ready
              <Menu.Item className={styles.linkContainer} key="roles">
                <NavLink to="/roles">
                  <TeamOutlined/>
                  <span>
                    <FormattedMessage id="roles.roles" />
                  </span>
                </NavLink>
              </Menu.Item>
            */}
              {canActivateContent24(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Submenu
                  mainLink="content24"
                  isMainLinkActive={false}
                  testId="main-navigation-content24"
                  subLinks={this.content24Links}
                  text={<FormattedMessage id="main-navigation.content24" />}
                  icon={<PicLeftOutlined />}
                  activeMenuKey={activeMenuKey}
                />
              )}
              {canEditCarePlanGroups(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Menu.Item className={styles.linkContainer} key="care-plan-groups">
                  <NavLink to="/care-plan-groups">
                    <ApartmentOutlined
                      className={styles.linkIcon}
                      rotate={270}
                      data-testid="main-navigation-care-plan-groups"
                    />
                    <span>
                      <FormattedMessage id="main-navigation.care-plan-groups" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              {canViewRules(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Menu.Item className={styles.linkContainer} key="rules">
                  <NavLink to="/rules">
                    <FolderViewOutlined data-testid="main-navigation-rules" />
                    <span>
                      <FormattedMessage id="main-navigation.rules" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
              {canViewCarePathways(userPermissionsStore) && !IS_EDUCATION_ENV && (
                <Menu.Item className={styles.linkContainer} key="carePathways">
                  <NavLink to="/carePathways">
                    <BranchesOutlined data-testid="main-navigation-carePathways" />
                    <span>
                      <FormattedMessage id="main-navigation.carePathways" />
                    </span>
                  </NavLink>
                </Menu.Item>
              )}
            </Menu>
          )}

          <div className={styles.bottomContainer}>
            <IntlConsumer>
              {({ switchLang, lang }) => (
                <LanguageSwitcher
                  activeLanguage={lang as LANGS}
                  languages={languageOptions}
                  onChange={switchLang}
                  className={classNames(styles.languageSwitcher, {
                    [styles.isCollapsed]: layoutStore.collapsed,
                  })}
                  isCollapsed={layoutStore.collapsed}
                />
              )}
            </IntlConsumer>
            {layoutStore.collapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.about" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={() => this.props.history.push('/about')}
                >
                  <InfoCircleOutlined />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={() => this.props.history.push('/about')}
              >
                <InfoCircleOutlined /> <FormattedMessage id="main-navigation.about" />
              </Button>
            )}
            {layoutStore.collapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.expand" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={layoutStore.toggleCollapseNavigation}
                >
                  <RightOutlined className={styles.collapseIcon} />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={layoutStore.toggleCollapseNavigation}
              >
                <LeftOutlined className={styles.collapseIcon} />
                <FormattedMessage id="main-navigation.collapse" />
              </Button>
            )}
            {layoutStore.collapsed ? (
              <Tooltip title={<FormattedMessage id="main-navigation.logout" />} placement="right">
                <Button
                  type="link"
                  className={classNames(styles.menuItem, styles.menuItemBtn)}
                  onClick={this.handleLogout}
                  data-testid="main-navigation-logout"
                >
                  <LogoutOutlined className={styles.logoutIcon} />
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="link"
                className={classNames(styles.menuItem, styles.menuItemBtn)}
                onClick={this.handleLogout}
                data-testid="main-navigation-logout"
              >
                <LogoutOutlined className={styles.logoutIcon} />
                <FormattedMessage id="main-navigation.logout" />
              </Button>
            )}
          </div>
        </div>
      </Layout.Sider>
    );
  }
}

export default injectIntl(withRouter(MainNavigation));
