import { Button } from 'antd';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { LANGS } from 'constants/enums';
import { CURRENT_ENV_LANGUAGE } from 'constants/env';
import { LOGIN_METHODS } from 'constants/login';
import RootStoreContext from 'context/RootStoreContext';

import NorwegianLoginMethods from '../NorwegianLoginMethods';
import SwedishLoginMethods from '../SwedishLoginMethods';

const useSelectLoginMethod = () => {
  const { authStore } = useContext(RootStoreContext);
  return (loginMethod: LOGIN_METHODS) => {
    authStore.initiateSSOLogin(loginMethod);
  };
};

const OpenIdLoginButton = () => {
  const selectLoginMethod = useSelectLoginMethod();
  return (
    <Button
      type="primary"
      block
      size="large"
      data-testid="external-login-openid"
      onClick={() => {
        selectLoginMethod(LOGIN_METHODS.OPENIDCONNECT);
      }}
    >
      <FormattedMessage id="login.login" />
    </Button>
  );
};

const SamlLoginButton = () => {
  const selectLoginMethod = useSelectLoginMethod();
  return (
    <Button
      type="primary"
      block
      size="large"
      data-testid="external-login-openid"
      onClick={() => {
        selectLoginMethod(LOGIN_METHODS.SAML);
      }}
    >
      <FormattedMessage id="login.login" />
    </Button>
  );
};

const LOGIN_METHOD_BUTTONS = {
  [LOGIN_METHODS.OPENIDCONNECT]: OpenIdLoginButton,
  [LOGIN_METHODS.SAML]: SamlLoginButton,
};

const OldLoginMethods = () => {
  const { authStore } = useContext(RootStoreContext);
  const [loading, setLoading] = useState(true);
  const [loginMethods, setLoginMethods] = useState<{ type: string }[]>([]);
  useEffect(() => {
    (async () => {
      setLoginMethods(await authStore.getLoginMethods());
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, []);
  if (loading) return null;
  if (loginMethods.length) {
    return (
      <>
        {loginMethods.map(({ type }) => {
          const LoginButton = LOGIN_METHOD_BUTTONS[type];
          return <LoginButton key={type} />;
        })}
      </>
    );
  }
  return (
    <Fragment>
      {CURRENT_ENV_LANGUAGE === LANGS.SV && <SwedishLoginMethods />}
      {CURRENT_ENV_LANGUAGE === LANGS.NO && <NorwegianLoginMethods />}
    </Fragment>
  );
};

export default OldLoginMethods;
