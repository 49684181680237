import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popconfirm, Row, Switch, Table, Tag, Typography } from 'antd';
import noop from 'lodash/noop';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import iPhoneX2x from 'assets/png/toplist_preview_iPhoneX@2x.png';
import DraggableRow from 'components/DraggableRow';
import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';

import { ToplistItem } from './api/toplistApi';
import AddEditToplistItem from './components/AddEditToplistItem';
import ToplistStore from './stores/ToplistStore';
import styles from './Toplist.module.css';

type Props = WrappedComponentProps;

@observer
class Toplist extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  toplistStore: ToplistStore;

  components = {
    body: {
      row: DraggableRow,
    },
  };

  columns = [
    {
      title: <FormattedMessage id="general.order" />,
      dataIndex: 'order',
    },
    {
      title: <FormattedMessage id="general.condition" />,
      dataIndex: 'conditionId',
    },
    {
      title: <FormattedMessage id="origin.toplist.searchTermId" />,
      dataIndex: 'searchTermId',
    },
    {
      title: <FormattedMessage id="origin.toplist.description" />,
      dataIndex: 'description',
      render: (_: string, record: ToplistItem) =>
        this.context.originStore.availableOriginLanguages.map(lang => (
          <div key={lang}>
            <FormattedMessage id={`general.language-${lang}`} />: {record.description[lang]}
          </div>
        )),
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      render: (_: string, record: ToplistItem) => (
        <Fragment>
          <Button
            type="link"
            icon={
              this.context.userPermissionsStore.canEditCurrentPartner ? (
                <EditOutlined />
              ) : (
                <EyeOutlined />
              )
            }
            onClick={() => this.toplistStore.handleEdit(record.searchTermId)}
          />
          {this.context.userPermissionsStore.canEditCurrentPartner && (
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() => this.toplistStore.handleDelete(record.searchTermId)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Fragment>
      ),
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.toplistStore = new ToplistStore(context);
  }

  componentDidMount() {
    Promise.all([this.toplistStore.fetchToplistCustomization(), this.toplistStore.fetchToplist()]);
  }

  handleMoveRow = (dragIndex: number, hoverIndex: number) => {
    const newsItems = this.toplistStore.toplist.slice();
    const dragRow = newsItems[dragIndex];

    newsItems.splice(dragIndex, 1);
    newsItems.splice(hoverIndex, 0, dragRow);

    this.toplistStore.saveData(newsItems);
  };

  render() {
    const {
      handleAdd,
      handleSubmit,
      handleDelete,
      toplist,
      isLoadingTopList,
      isLoadingSearchTerms,
      handleCancel,
      activeItem,
      handleEnableToplistSwitchChange,
      conditionSearchTerms,
      isTopListEnabled,
      isSwitchingCustomization,
    } = this.toplistStore;
    const { userPermissionsStore, originStore } = this.context;
    const { intl } = this.props;

    return (
      <Fragment>
        <PageHeader
          content={
            <div className={styles.header}>
              <Typography.Title level={3}>
                <FormattedMessage id="origin.toplist.header" />
              </Typography.Title>
              <Form>
                <Form.Item
                  label={<FormattedMessage id="origin.toplist.isEnabled" />}
                  className={styles.hiddenSwitch}
                >
                  <Switch
                    checked={isTopListEnabled}
                    onChange={handleEnableToplistSwitchChange}
                    disabled={
                      !userPermissionsStore.canEditCurrentPartner ||
                      originStore.isLoading() ||
                      isSwitchingCustomization
                    }
                    loading={originStore.isLoading() || isSwitchingCustomization}
                  />
                </Form.Item>
              </Form>
            </div>
          }
          canEdit={false}
        >
          <p>
            <FormattedMessage id="origin.toplist.subheader" />
          </p>
        </PageHeader>
        <Row gutter={16}>
          <Col flex="auto">
            <DndProvider backend={HTML5Backend}>
              <Table<ToplistItem>
                dataSource={toplist.slice()}
                columns={this.columns}
                pagination={false}
                loading={isLoadingTopList}
                components={
                  userPermissionsStore.canEditCurrentPartner ? this.components : undefined
                }
                onRow={
                  userPermissionsStore.canEditCurrentPartner
                    ? (_, index) => ({
                        index,
                        moveRow: this.handleMoveRow,
                        // Required by onRow return type
                        onClick: noop,
                      })
                    : undefined
                }
                className={styles.table}
                rowKey="searchTermId"
              />
            </DndProvider>
          </Col>
          <Col flex="300px">
            <div className={styles.previewContainer}>
              <img
                src={iPhoneX2x}
                alt={intl.formatMessage({ id: 'origin.toplist.live-preview' })}
                width={300}
              />
              <div className={styles.previewToplist}>
                {toplist.map(({ conditionId, description }) => (
                  <Tag key={conditionId} className={styles.previewToplistItem}>
                    {description[intl.locale] || conditionId}
                  </Tag>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <AddEditToplistItem
          initialValues={activeItem}
          isLoading={isLoadingSearchTerms}
          isSaving={isLoadingTopList}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isDisabled={!userPermissionsStore.canEditCurrentPartner}
          availableLanguages={originStore.availableOriginLanguages}
          conditionSearchTerms={toJS(conditionSearchTerms)}
        />
        {userPermissionsStore.canEditCurrentPartner && toplist.length < 10 && (
          <PlusFloatingButton onClick={handleAdd} />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(Toplist);
