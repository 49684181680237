import { observable, runInAction } from 'mobx';

import { fetchPartnerImages, fetchPartnerImageData, PartnerImage } from 'api/partnersApi';

export default class ImageStore {
  partnerId?: string;
  imageMap = new Map<string, Promise<Blob> | Blob>();
  @observable images: PartnerImage[] = [];

  initialize(partnerId: string) {
    this.partnerId = partnerId;
    this.imageMap.clear();

    runInAction(() => {
      this.images = [];
    });
  }

  async fetchImages() {
    if (!this.partnerId) {
      throw new Error('Partner id not specified');
    }

    const { data } = await fetchPartnerImages(this.partnerId);

    runInAction(() => {
      this.images = data;
    });
  }

  async getImage(imageId: string) {
    const imageInStore = this.imageMap.get(imageId);

    if (imageInStore) {
      if (imageInStore instanceof Blob) {
        return imageInStore;
      }
      const data = await imageInStore;
      return data;
    }

    const loadImage = this.fetchImage(imageId);

    this.imageMap.set(imageId, loadImage);

    const data = await loadImage;

    this.imageMap.set(imageId, data);

    return data;
  }

  private async fetchImage(imageId: string) {
    if (!this.partnerId) {
      throw new Error('Partner id not specified');
    }

    try {
      const { data } = await fetchPartnerImageData(this.partnerId, imageId);
      return data;
    } catch (error) {
      return new Blob();
    }
  }
}
