import { Tag } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { MENU_ITEMS_PROPERTIES_VALUES } from 'constants/origins';
import { ROLES_DESCRIPTION_KEY } from 'constants/roles';

import styles from './MenuItemPropertyTag.module.css';
import { MenuItemTransformedProperty } from '../../stores/MenuItemsStore';

interface Props {
  property: MenuItemTransformedProperty;
}

const MenuItemPropertyTag = ({ property: { value, type } }: Props) => {
  let formattedValue = value;

  switch (type) {
    case MENU_ITEMS_PROPERTIES_VALUES.RESOURCE_TYPE:
      formattedValue = <FormattedMessage id={ROLES_DESCRIPTION_KEY[value]} />;
      break;
    case MENU_ITEMS_PROPERTIES_VALUES.OPTIONS_IDS:
      formattedValue = value.join(' ');
      break;
    case MENU_ITEMS_PROPERTIES_VALUES.INTERVIEW_PROPERTIES:
      formattedValue = Object.values(value).join(' ');
      break;
  }

  return (
    <Tag key={type} className={styles.tag}>
      {formattedValue}
    </Tag>
  );
};

export default MenuItemPropertyTag;
