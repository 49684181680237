import { Tabs } from 'antd';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';

import ClinicPersonalRoles from '../ClinicPersonalRoles';
import ManagePersonalRoles from '../ManagePersonalRoles';

const PersonalRoles = () => {
  const { userPermissionsStore } = useContext(RootStoreContext);
  const hasAnyOfNewRoles = userPermissionsStore.getSideBarAccess(...Object.values(ROLE_MENUITEMS));

  return (
    <Tabs data-testid="rolesTabs">
      <Tabs.TabPane
        tab={
          <span data-testid="clinic-tab">
            <FormattedMessage id="roles.clinic24" />
          </span>
        }
        key="1"
      >
        <ClinicPersonalRoles />
      </Tabs.TabPane>
      {(userPermissionsStore.isAuthorizedToEditPartner || hasAnyOfNewRoles) && (
        <Tabs.TabPane
          tab={
            <span data-testid="manage-tab">
              <FormattedMessage id="roles.manage24" />
            </span>
          }
          key="2"
        >
          <ManagePersonalRoles />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

export default PersonalRoles;
