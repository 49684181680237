import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const Headphones = ({ color = COLORS.PINK }: Props) => (
  <svg viewBox="0 0 32 40" x="0px" y="0px" fill={color} width={32} height={40}>
    <g>
      <path d="M27,17.61V15A11,11,0,0,0,5,15v2.61A5.5,5.5,0,0,0,7.5,28H8a2,2,0,0,0,2-2V19a2,2,0,0,0-2-2H7.5L7,17V15a9,9,0,0,1,18,0v2l-.5,0H24a2,2,0,0,0-2,2v7H19V25a1,1,0,0,0-1-1H16a1,1,0,0,0-1,1v2a1,1,0,0,0,1,1h8.5A5.5,5.5,0,0,0,27,17.61ZM8,19v7H7.5a3.5,3.5,0,0,1,0-7Zm16.5,7H24V19h.5a3.5,3.5,0,0,1,0,7Z" />
    </g>
  </svg>
);
