import { observable, runInAction } from 'mobx';

import { fetchPractitionerByExternalId, Practitioner, ExternalId } from 'api/practitionerApi';
import { asyncDebounce } from 'utils/appUtils';

import StateManager from './abstractStores/StateManager';

export default class PractitionerFindStore extends StateManager {
  @observable practitioner?: Practitioner;

  searchPractitioner = async (externalId: ExternalId) => {
    this.setLoading();
    let practitioner;

    try {
      const { data } = await fetchPractitionerByExternalId(externalId);
      this.setLoaded();

      runInAction(() => {
        if (data && data.length > 0) {
          practitioner = data[0];
          this.practitioner = data[0];
        } else {
          this.practitioner = undefined;
          practitioner = undefined;
          this.setError();
        }
      });

      return practitioner;
    } catch (e) {
      runInAction(() => {
        this.practitioner = undefined;
      });
      this.setError();
      return undefined;
    }
  };
  findByExternalId = asyncDebounce(this.searchPractitioner, 500);
}
