import { observable, action } from 'mobx';

export default class LayoutStore {
  @observable collapsed = false;

  @action
  toggleCollapseNavigation = () => {
    this.collapsed = !this.collapsed;
  };

  @action
  collapseNavigation = () => {
    this.collapsed = true;
  };
}
