import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const HomeMagnifierIcon = ({ color = COLORS.PINK }: Props) => (
  <svg width="25px" height="25px" viewBox="0 0 25 25">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-23.000000, -446.000000)" stroke="#4A4A4A">
        <g transform="translate(24.000000, 447.000000)">
          <g strokeWidth="1.5">
            <circle fill="#FFFFFF" cx="10.2222222" cy="10.2222222" r="10.2222222" />
            <path d="M23,23 L17.4416667,17.4416667" fill="#D8D8D8" />
          </g>
          <g transform="translate(5.000000, 6.000000)" fill={color} strokeWidth="1.2">
            <path d="M9.97349204,1.17292784 C9.45148429,0.662280604 8.74334271,0.375381818 8.00493892,0.375381818 C7.26653514,0.375381818 6.55839356,0.662280604 6.0363858,1.17292784 L5.49996773,1.69742552 L4.96354965,1.17292784 C3.87634778,0.109886043 2.1136453,0.109886056 1.02644344,1.17292787 C-0.060758417,2.23596969 -0.0607584301,3.959501 1.02644341,5.02254284 L1.56286148,5.54704051 L5.49996773,9.3966555 L9.43707397,5.54704051 L9.97349204,5.02254284 C10.4957449,4.51213525 10.7891641,3.81973015 10.7891641,3.09773534 C10.7891641,2.37574053 10.4957449,1.68333543 9.97349204,1.17292784 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
