import React from 'react';

import { COLORS } from '../../constants/colors';

export const Baby = ({ color = COLORS.PINK }) => (
  <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.941 11.048C21.185 7.987 19.057 5.467 16.243 4.181C16.205 1.87 14.32 0 12 0C9.68 0 7.795 1.87 7.757 4.181C4.944 5.467 2.816 7.985 2.06 11.044C0.891 11.254 0 12.272 0 13.5C0 14.728 0.891 15.746 2.06 15.956C3.164 20.423 7.196 23.75 12 23.75C16.804 23.75 20.836 20.423 21.94 15.956C23.109 15.746 24 14.728 24 13.5C24 12.271 23.119 11.25 21.941 11.048ZM12 2C12.965 2 13.783 2.614 14.102 3.469C13.423 3.327 12.72 3.25 12 3.25C11.28 3.25 10.577 3.327 9.898 3.469C10.217 2.614 11.035 2 12 2ZM12 21.75C7.451 21.75 3.75 18.049 3.75 13.5C3.75 8.951 7.451 5.25 12 5.25C12.648 5.25 13.277 5.333 13.883 5.475C13.481 6.091 12.788 6.5 12 6.5C11.448 6.5 11 6.947 11 7.5C11 8.053 11.448 8.5 12 8.5C13.649 8.5 15.066 7.546 15.77 6.17C18.426 7.542 20.25 10.309 20.25 13.5C20.25 18.049 16.549 21.75 12 21.75Z"
      fill={color}
    />
    <path
      d="M8 14.25C8.69035 14.25 9.25 13.6904 9.25 13C9.25 12.3096 8.69035 11.75 8 11.75C7.30964 11.75 6.75 12.3096 6.75 13C6.75 13.6904 7.30964 14.25 8 14.25Z"
      fill={color}
    />
    <path
      d="M16 14.25C16.6904 14.25 17.25 13.6904 17.25 13C17.25 12.3096 16.6904 11.75 16 11.75C15.3096 11.75 14.75 12.3096 14.75 13C14.75 13.6904 15.3096 14.25 16 14.25Z"
      fill={color}
    />
    <path
      d="M14.2966 17.5479C13.0686 18.7749 10.9306 18.7749 9.70263 17.5479C9.40963 17.2549 8.93463 17.2549 8.64163 17.5479C8.34862 17.8409 8.34862 18.3159 8.64163 18.6089C9.56763 19.5349 10.7836 19.9979 11.9986 19.9979C13.2136 19.9979 14.4306 19.5349 15.3556 18.6089C15.6486 18.3159 15.6486 17.8409 15.3556 17.5479C15.0626 17.2549 14.5896 17.2549 14.2966 17.5479Z"
      fill={color}
    />
  </svg>
);
