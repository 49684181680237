import { getIn, useFormikContext } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';
import {
  ConnectedCustomTagInputProps,
  CustomTagInputOnAdd,
  CustomTagInputOnBlur,
  CustomTagInputOnRemove,
} from 'modules/Rules/types';

import { CustomTagInput } from './CustomTagInput';
import styles from '../RuleForm.module.css';

export const SetValueInput = ({
  name,
  onChange,
  onBlur,
  allowedValues,
  isDisabled,
  outcomeMeta,
}: ConnectedCustomTagInputProps) => {
  const { flashMessageService } = useContext(RootStoreContext);
  const { formatMessage } = useIntl();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const fieldValue = getIn(values, name) || [];

  const onAddHandler: CustomTagInputOnAdd = addedValue => {
    if (fieldValue.find((fieldValue: string) => fieldValue === addedValue)) {
      flashMessageService.translatedError(
        'rules.errors.set-unique-value',
        {},
        { fieldId: outcomeMeta?.id }
      );
    } else {
      const newFieldValue = [...fieldValue, addedValue];
      setFieldValue(name, newFieldValue);
      setFieldTouched(name);
      onChange?.(newFieldValue);
    }
  };

  const onRemoveHandler: CustomTagInputOnRemove = index => {
    fieldValue.splice(index, 1);
    const newFieldValue = [...fieldValue];
    setFieldValue(name, newFieldValue);
    setFieldTouched(name);
    onChange?.(newFieldValue);
  };

  const onBlurHandler: CustomTagInputOnBlur = e => {
    setFieldTouched(name);
    onBlur && onBlur(e);
  };

  return (
    <>
      {allowedValues.length > 0 ? (
        <Form.Item
          name={name}
          required
          className={styles.formItem}
          label={formatMessage({ id: 'rules.value' })}
        >
          <Select
            style={{ width: '100%' }}
            allowClear
            name={name}
            disabled={isDisabled}
            options={allowedValues}
            mode="multiple"
            placeholder={formatMessage({ id: 'rules.placeholders.autocomplete' })}
            notFoundContent={formatMessage({ id: 'rules.placeholders.autocomplete.empty' })}
          />
        </Form.Item>
      ) : (
        <Form.Item
          name={name}
          required
          className={styles.formItem}
          label={formatMessage({ id: 'rules.value' })}
        >
          <CustomTagInput
            isDisabled={isDisabled}
            onBlur={onBlurHandler}
            value={fieldValue.length === 0 ? undefined : fieldValue}
            outcomeMeta={outcomeMeta}
            onAdd={onAddHandler}
            onRemove={onRemoveHandler}
          />
        </Form.Item>
      )}
    </>
  );
};
