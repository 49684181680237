import intersection from 'lodash/intersection';
import { action, computed, IObservableArray, observable } from 'mobx';

import { PartnerUserRole } from 'api/partnersApi';
import {
  AccessModes,
  AccessScopeRole,
  ACCESS_LEVEL,
  CareProvidersScope,
  fetchMyAccessScopedRoles,
  fetchMyUserPermissions,
  Scope,
  ScopedRoles,
} from 'api/permissionsApi';
import { RESOURCE_TYPES, ROLE_MENUITEMS } from 'constants/permissions';
import { ACCESS_SCOPE_TYPES, LOGIN_ALLOWED_ROLES, PARTNER_ROLES, ROLES } from 'constants/roles';
import { fetchAccessForMenuItems } from 'modules/Roles/api/rolesApi';
import { extractRolesFromJWT } from 'utils/rolesUtils';
import { getPermissionStringWildCardCombinations } from 'utils/textUtils';
import { getToken } from 'utils/tokenUtils';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export type PermissionQueryConfig = {
  resourceType: RESOURCE_TYPES;
  accessLevel?: ACCESS_LEVEL;
  originId?: string;
  careProviderId?: string;
  careUnitId?: string;
  resourceValue?: string;
};

class UserPermissionsStore extends StateManager {
  private scope: Map<string, ACCESS_LEVEL> = new Map();
  private menuAccess: Map<string, boolean> = new Map();

  @observable myScopedRoles: ScopedRoles[] = [];
  @observable roles?: IObservableArray<ROLES>;

  constructor(private rootStore: RootStore) {
    super();
  }

  // FIXME: utility method meant only for transition period
  get isEnabled() {
    return this.scope.size > 0;
  }

  @computed
  get isAllowedUser() {
    const isAllowedRole =
      !!this.roles && intersection(this.roles.slice(), LOGIN_ALLOWED_ROLES).length > 0;
    const isAllowedPartnerRole = !!this.rootStore.partnersStore.partners.length;

    return isAllowedRole || isAllowedPartnerRole;
  }

  @computed get isSuperAdmin() {
    return !!this.roles && this.roles.includes(ROLES.SUPER_ADMIN);
  }

  @computed get isAdmin() {
    return this.isSuperAdmin || (!!this.roles && this.roles.includes(ROLES.ADMIN));
  }

  @action setUserRolesFromJWT = () => {
    const token = getToken();
    if (token) {
      this.roles = observable.array(extractRolesFromJWT(token));
    }
  };

  @action updateRoles(roles: ROLES[]) {
    this.roles = observable.array(roles);
  }

  @computed
  get isAuthorizedToEditPartner(): boolean {
    const partner = this.rootStore.partnersStore.currentPartner;

    return (
      this.isSuperAdmin ||
      partner?.role === PARTNER_ROLES.ADMIN ||
      partner?.role === PARTNER_ROLES.WRITE
    );
  }

  @computed
  get canEditCurrentPartner(): boolean {
    return this.isAuthorizedToEditPartner && !this.rootStore.partnersStore.isReadOnlyModeEnabled;
  }

  @computed
  get canViewAccountDeletion(): boolean {
    return this.isAdmin;
  }

  hasRole = (role: ROLES) => !!this.roles && this.roles.includes(role);

  isPartnerAdmin = (partnerUserRole: PartnerUserRole) => {
    if (this.isSuperAdmin) {
      return true;
    }

    return partnerUserRole.role === PARTNER_ROLES.ADMIN;
  };

  getManagedOrigins = (partnerId: string): Scope[] => {
    const partnerScopes = this.myScopedRoles.find(
      elem => elem.partnerScope.scopeValue === partnerId
    );
    if (!partnerScopes || !partnerScopes.scopes.origins) return [];

    return partnerScopes.scopes.origins;
  };

  getManagedCareProviders = (partnerId: string): CareProvidersScope[] => {
    const partnerScopes = this.myScopedRoles.find(
      elem => elem.partnerScope.scopeValue === partnerId
    );
    if (!partnerScopes || !partnerScopes.scopes.careProviders) return [];

    return partnerScopes.scopes.careProviders;
  };

  getManagedCareUnits = (partnerId: string, careProviderId: string): Scope[] => {
    const managedCareProviders = this.getManagedCareProviders(partnerId);
    const careProvider = managedCareProviders.find(cp => cp.scopeValue === careProviderId);

    return careProvider ? careProvider.careUnits : [];
  };

  getOriginScopedRoles = (partnerId: string, originId: string): AccessScopeRole[] => {
    const managedOrigins = this.getManagedOrigins(partnerId);
    const origin = managedOrigins.find(o => o.scopeValue === originId);

    if (origin && origin.roles?.length) {
      return origin.roles;
    }

    // If there are no specific roles for origin, check if there are roles set for '*' - all origins
    const allOriginsScope = managedOrigins.find(o => o.scopeValue === ACCESS_SCOPE_TYPES.ALL);
    if (!allOriginsScope) return [];

    return allOriginsScope.roles || [];
  };

  getCareUnitScopedRoles = (
    partnerId: string,
    careProviderId: string,
    careUnitId: string
  ): AccessScopeRole[] => {
    const managedCareUnits = this.getManagedCareUnits(partnerId, careProviderId);

    const careUnit = managedCareUnits.find(cu => cu.scopeValue === careUnitId);
    if (careUnit && careUnit.roles) {
      return careUnit.roles;
    }

    // If there are no specific roles for the care unit, check if there are roles set for '*' - all care units
    const allCareUnitsScopes = managedCareUnits.find(o => o.scopeType === ACCESS_SCOPE_TYPES.ALL);
    if (!allCareUnitsScopes || !allCareUnitsScopes.roles) return [];

    return allCareUnitsScopes.roles;
  };

  fetchMyScopedRoles = async () => {
    this.setLoading();

    try {
      const { data } = await fetchMyAccessScopedRoles();

      if (data.length) {
        console.log('[M24] Using new permission config');
      }

      this.myScopedRoles = data;
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  fetchMyUserPermissions = async () => {
    this.setLoading();

    try {
      const { data } = await fetchMyUserPermissions();
      const { data: access } = await fetchAccessForMenuItems();
      const { scopedRoles = [], partnerRoles = [] } = access;

      for (const menuAccessRole of scopedRoles) {
        this.menuAccess.set(menuAccessRole, true);
      }

      for (const menuAccessRole of partnerRoles) {
        this.menuAccess.set(menuAccessRole, true);
      }

      if (data.length) {
        console.log('[M24] Using new permission config');
      }

      this.scope = new Map(
        data.map(({ path, permissionString }: AccessModes) => [path, permissionString])
      );
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      this.setLoaded();
    }
  };

  private getScopeValue(basePath: string, accessLevel: ACCESS_LEVEL, resourceValue?: string) {
    const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
    const scopeWildcardPaths = getPermissionStringWildCardCombinations(basePath);

    for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
      const path = scopeWildcardPaths[i];
      const resoureWildcardValue = this.scope.get(`/${path}/**`);

      if (!!resoureWildcardValue && (isReadAccess || resoureWildcardValue === ACCESS_LEVEL.WRITE)) {
        return true;
      }

      const value = this.scope.get(`/${path}/${resourceValue}`);

      if (!!value && (isReadAccess || value === ACCESS_LEVEL.WRITE)) {
        return true;
      }
    }

    return false;
  }

  private getSystemScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    resourceValue?: string
  ) => {
    // System scope paths are treated a bit different,
    // that's why getScopeValue is not reused here.
    const isReadAccess = accessLevel === ACCESS_LEVEL.READ;
    // First two work the same, last one is for super admins (access all resources)
    const scopeWildcardPaths = [`/${resourceType}`, `/${resourceType}/**`, `/**`];

    for (let i = 0; i < scopeWildcardPaths.length; i += 1) {
      const path = scopeWildcardPaths[i];
      const resoureWildcardValue = this.scope.get(path);

      if (!!resoureWildcardValue && (isReadAccess || resoureWildcardValue === ACCESS_LEVEL.WRITE)) {
        return true;
      }
    }

    const value = this.scope.get(`/${resourceType}/${resourceValue}`);

    return !!value && (isReadAccess || value === ACCESS_LEVEL.WRITE);
  };

  getSideBarAccess = (...menuRoleAccess: (ROLE_MENUITEMS | PARTNER_ROLES)[]) => {
    if (!menuRoleAccess || menuRoleAccess.length < 1) {
      return false;
    }
    return menuRoleAccess.some(role => this.menuAccess.get(role));
  };

  private getPartnerScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    resourceValue?: string
  ) => this.getScopeValue(`partners/${partnerId}/${resourceType}`, accessLevel, resourceValue);

  private getOriginScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    originId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/origins/${originId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  private getCareProviderScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    careProviderId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/careproviders/${careProviderId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  private getCareUnitScopeValue = (
    resourceType: RESOURCE_TYPES,
    accessLevel: ACCESS_LEVEL,
    partnerId: string,
    careProviderId: string,
    careUnitId: string,
    resourceValue?: string
  ) =>
    this.getScopeValue(
      `partners/${partnerId}/careproviders/${careProviderId}/careunits/${careUnitId}/${resourceType}`,
      accessLevel,
      resourceValue
    );

  getPermission = (
    {
      resourceType,
      accessLevel = ACCESS_LEVEL.WRITE,
      originId,
      careProviderId,
      careUnitId,
      resourceValue,
    }: PermissionQueryConfig,
    // utility argument meant only for transition period
    fallback = false
  ) => {
    if (!this.isEnabled) {
      return fallback;
    }

    const systemScopeValue = this.getSystemScopeValue(resourceType, accessLevel, resourceValue);

    if (systemScopeValue) {
      return true;
    }

    const partnerId = this.rootStore.partnersStore.partnerId;

    if (partnerId) {
      const partnerScopeValue = this.getPartnerScopeValue(
        resourceType,
        accessLevel,
        partnerId,
        resourceValue
      );

      if (partnerScopeValue) {
        return true;
      }

      if (originId) {
        return this.getOriginScopeValue(
          resourceType,
          accessLevel,
          partnerId,
          originId,
          resourceValue
        );
      }

      if (careProviderId) {
        const careProviderScopeValue = this.getCareProviderScopeValue(
          resourceType,
          accessLevel,
          partnerId,
          careProviderId,
          resourceValue
        );

        if (careProviderScopeValue) {
          return true;
        }

        if (careUnitId) {
          return this.getCareUnitScopeValue(
            resourceType,
            accessLevel,
            partnerId,
            careProviderId,
            careUnitId,
            resourceValue
          );
        }
      }
    }

    return false;
  };
}

export default UserPermissionsStore;
