import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import FlashMessage from 'components/FlashMessage/FlashMessage';
import AntdConfigProvider from 'config/antdConfigProvider';
import { rootStore } from 'config/appConfig';
import { IntlProvider } from 'context/IntlContext';
import RootStoreContext from 'context/RootStoreContext';
import Routes from 'routes/Routes';

const App = () => (
  <IntlProvider>
    <AntdConfigProvider>
      <ErrorBoundary>
        <Router>
          <RootStoreContext.Provider value={rootStore}>
            <Fragment>
              <FlashMessage />
              <Routes />
            </Fragment>
          </RootStoreContext.Provider>
        </Router>
      </ErrorBoundary>
    </AntdConfigProvider>
  </IntlProvider>
);

export default App;
