import {
  BankOutlined,
  EditOutlined,
  CaretDownFilled,
  CaretRightFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Table, Input } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { PartnerCareProvider } from 'api/partnersApi';
import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import styles from './CareProvidersList.module.css';
import CareProvidersListStore, { CareProvidersListItem } from './stores/CareProvidersListStore';

interface Props extends RouteComponentProps<{ careProviderId: string }>, WrappedComponentProps {}

@observer
class CareProvidersList extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  careProvidersListStore: CareProvidersListStore;

  columns = [
    {
      title: <FormattedMessage id="general.id" />,
      dataIndex: FIELDS.ID,
      sorter: (a: PartnerCareProvider, b: PartnerCareProvider) => (a.id < b.id ? -1 : 1),
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 500,
      render: (text: string) => (
        <Fragment>
          <BankOutlined className={styles.homeIcon} />
          {this.renderText(text)}
        </Fragment>
      ),
    },
    {
      title: <FormattedMessage id="basic-details-form.name-label" />,
      dataIndex: FIELDS.NAME,
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: PartnerCareProvider, b: PartnerCareProvider) => {
        return sortWithLocale(a, b, 'name');
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      render: (text: string) => this.renderText(text),
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: CareProvidersListItem) => (
        <Fragment>
          {/* Currently editing is only enabled on care unit level */}
          {!record.children && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() =>
                this.props.history.push(
                  `/care-providers/${this.props.match.params.careProviderId}/care-units/${record.id}/basic-details`
                )
              }
            />
          )}
          {record.children && this.context.userPermissionsStore.canEditCurrentPartner && (
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => this.props.history.push(`/care-providers/${record.id}/care-units/add`)}
            />
          )}
        </Fragment>
      ),
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.careProvidersListStore = new CareProvidersListStore(context);
  }

  componentDidMount() {
    const {
      match: {
        params: { careProviderId },
      },
    } = this.props;

    if (careProviderId) {
      this.careProvidersListStore.handleExpand(true, careProviderId);
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { careProviderId },
      },
    } = this.props;

    if (careProviderId && careProviderId !== prevProps.match.params.careProviderId) {
      await this.careProvidersListStore.handleExpand(true, careProviderId);
    }
  }

  handleExpand = (expanded: boolean, record: PartnerCareProvider) => {
    this.props.history.push(`/care-providers/${record.id}/care-units`);
    this.careProvidersListStore.handleExpand(expanded, record.id);
  };

  renderText = (text: string) =>
    this.careProvidersListStore.searchTerm ? (
      <Highlighter
        highlightClassName={styles.highlightedText}
        searchWords={[this.careProvidersListStore.searchTerm]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    );

  render() {
    const { partnersStore, careUnitsStore } = this.context;

    return (
      <Fragment>
        <PartnerStatus />
        <div className={styles.container}>
          <PageHeader
            content="care-units.care-providers"
            breadcrumbs={[
              {
                icon: <BankOutlined />,
                text: <FormattedMessage id="care-units.care-providers" />,
                link: '/care-providers',
              },
            ]}
            headerActions={
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={this.careProvidersListStore.handleSearchChange}
                className={styles.search}
              />
            }
          />
          <Table<CareProvidersListItem>
            columns={this.columns}
            dataSource={this.careProvidersListStore.careProvidersList}
            pagination={false}
            rowKey={FIELDS.ID}
            data-testid="care-providers-list"
            className={styles.table}
            loading={partnersStore.isLoading() || careUnitsStore.isLoading()}
            onExpand={this.handleExpand}
            expandIcon={({ expanded, onExpand, record }) =>
              record.children ? (
                <Button
                  type="link"
                  icon={expanded ? <CaretDownFilled /> : <CaretRightFilled />}
                  onClick={e => onExpand(record, e)}
                />
              ) : (
                <span className={styles.spacer}></span>
              )
            }
            expandedRowKeys={this.careProvidersListStore.expandedRowKeys}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(CareProvidersList);
