import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const PainkillersIcon = ({ color = COLORS.PINK }: Props) => (
  <svg width="28px" height="28px" viewBox="0 0 28 28">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-21.000000, -350.000000)" stroke="#4A4A4A" strokeWidth="1.25">
        <g transform="translate(22.000000, 351.000000)">
          <path
            d="M12.7023102,1.01942765 C14.0617392,2.37893524 14.0630054,4.58186628 12.701234,5.94371649 L9.25626093,9.38888889 L4.33333333,4.4656764 L7.77830637,1.02050399 C9.1383297,-0.339598043 11.3425824,-0.340378856 12.7023102,1.01942765 Z"
            fill={color}
          />
          <path
            d="M1.02050399,7.77830637 C-0.339598043,9.1383297 -0.340378856,11.3425824 1.01942765,12.7023102 L1.01942765,12.7023102 C2.37893524,14.0617392 4.58186628,14.0630054 5.94371649,12.701234 L9.38888889,9.25626093 L4.4656764,4.33333333 L1.02050399,7.77830637 L1.02050399,7.77830637 Z"
            fill="#FFFFFF"
          />
          <path
            d="M14.6105825,13.2982818 C13.2505592,11.9381797 11.0463065,11.9373989 9.68657865,13.2972054 L9.68657865,13.2972054 C8.32714973,14.656713 8.32588353,16.8596441 9.68765493,18.2214943 L13.132628,21.6666667 L18.0555556,16.7434542 L14.6105825,13.2982818 L14.6105825,13.2982818 Z"
            fill={color}
          />
          <path
            d="M21.3683849,20.0560841 C22.7284869,21.4161075 22.7292677,23.6203602 21.3694612,24.980088 L21.3694612,24.980088 C20.0099536,26.3395169 17.8070226,26.3407831 16.4451724,24.9790117 L13,21.5340387 L17.9232125,16.6111111 L21.3683849,20.0560841 L21.3683849,20.0560841 Z"
            fill="#FFFFFF"
          />
          <path
            d="M24.4134951,11.4134951 C22.2981552,13.528835 18.8685115,13.528835 16.7531716,11.4134951 C14.6378317,9.29815515 14.6378317,5.86851152 16.7531716,3.7531716 C18.8685115,1.63783169 22.2981552,1.63783169 24.4134951,3.7531716 C26.528835,5.86851152 26.528835,9.29815515 24.4134951,11.4134951 L24.4134951,11.4134951 Z"
            fill="#ECEFF1"
          />
          <path d="M23.8333333,3.61111111 L17.283784,10.8156153" fill="#E1F2FA" />
        </g>
      </g>
    </g>
  </svg>
);
