import { EditOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { FunctionComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Breadcrumbs from 'components/Breadcrumbs';
import { AppBreadcrumbItem } from 'components/Breadcrumbs/Breadcrumbs';
import { TEST_IDS } from 'constants/testIds';

import styles from './PageHeader.module.css';

interface Props {
  content: ReactNode;
  canEdit?: boolean;
  onEditClick?: () => void;
  className?: string;
  headerActions?: ReactNode;
  breadcrumbs?: AppBreadcrumbItem[];
}

const PageHeader: FunctionComponent<Props> = ({
  content,
  canEdit,
  onEditClick,
  className,
  headerActions,
  breadcrumbs,
  children,
}) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.top}>
      {typeof content === 'string' ? (
        <Typography.Title level={2} className={styles.title}>
          <FormattedMessage id={content} />
        </Typography.Title>
      ) : (
        content
      )}
      {canEdit && (
        <Button
          type="link"
          className={styles.editBtn}
          onClick={onEditClick}
          data-testid={TEST_IDS.EDIT_BTN}
        >
          <FormattedMessage id="general.edit" />
          <EditOutlined className={styles.editIcon} />
        </Button>
      )}
    </div>
    {children}
    <div className={styles.bottom}>
      <div>{!!breadcrumbs && <Breadcrumbs data={breadcrumbs} />}</div>
      <div>{headerActions}</div>
    </div>
  </div>
);

PageHeader.defaultProps = {
  canEdit: false,
  onEditClick: noop,
};

export default PageHeader;
