import React from 'react';

import { COLORS } from '../../constants/colors';

interface HomeCloudSunProps {
  color?: string;
}

export const HomeCloudSun = ({ color = COLORS.PINK }: HomeCloudSunProps) => (
  <svg width="40px" height="40px" viewBox="0 0 100000 125000" fillRule="evenodd" clipRule="evenodd">
    <g>
      <path
        style={{ fill: color }}
        d="M76075 60239l0 0c182,-984 279,-1998 279,-3032 0,-9117 -7390,-16506 -16505,-16506 -6427,0 -11996,3673 -14723,9037 -2234,-2185 -5289,-3535 -8660,-3535 -6835,0 -12378,5544 -12378,12379 0,574 39,1141 115,1695l0 0c-4794,1186 -8368,5519 -8368,10683 0,6066 4929,11003 11010,11003l46750 1c6066,0 11012,-4927 11012,-11002 0,-5213 -3647,-9596 -8532,-10722l0 -1 0 0zm-49107 -13434c-85,-646 -128,-1306 -128,-1976 0,-8357 6773,-15130 15130,-15130 6060,0 11285,3562 13701,8706l0 0c1345,-296 2743,-452 4178,-452 10635,0 19257,8618 19257,19254 0,380 -12,757 -34,1134 4874,2117 8285,6980 8285,12620 0,7596 -6141,13754 -13751,13754l-46766 0c-7596,0 -13753,-6171 -13753,-13753 0,-5644 3388,-10494 8255,-12613 73,-4662 2251,-8813 5628,-11539l0 0 0 0 -2 -4 0 0 0 -1zm2626 -1704l0 0 0 0c2062,-1053 4398,-1647 6873,-1647 2914,0 5631,822 7939,2247 2171,-2908 5145,-5183 8594,-6496 -2048,-4011 -6219,-6758 -11030,-6758 -6837,0 -12378,5545 -12378,12380 0,89 0,182 3,273l-1 1 0 0zm12376 -26405c759,0 1376,595 1376,1363l0 5525c0,754 -639,1362 -1376,1362 -760,0 -1376,-595 -1376,-1362l0 -5525c0,-755 639,-1363 1376,-1363l0 0zm-18523 7672c537,-536 1393,-552 1937,-8l3910 3910c530,530 511,1412 -8,1937 -537,535 -1393,552 -1937,7l-3910 -3909c-531,-532 -512,-1415 8,-1938l0 0 0 1zm-7671 18521c0,-759 594,-1376 1361,-1376l5526 0c755,0 1362,638 1362,1376 0,759 -594,1375 -1362,1375l-5526 0c-754,0 -1361,-638 -1361,-1375l0 0zm44713 -18521c538,535 553,1392 10,1937l-3911 3909c-530,532 -1412,512 -1936,-7 -537,-538 -554,-1394 -9,-1937l3911 -3910c531,-531 1413,-511 1935,8l0 0 0 0z"
      />
    </g>
  </svg>
);
