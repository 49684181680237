import axios from 'axios';

import { TranslatedText } from 'types/types';

export interface MenuItem {
  id: string;
  type: string;
  title: TranslatedText;
  description: TranslatedText;
  label: TranslatedText;
  properties?: { [key: string]: string | number | string[] };
  icon: string;
}

export const fetchMenuItems = (partnerId: string, originId: string) =>
  axios.get<MenuItem[]>(`/rest/admin/partners/${partnerId}/origins/${originId}/menuitems`);

export const updateMenuItems = async (partnerId: string, originId: string, data: MenuItem[]) =>
  axios.put<MenuItem[]>(`/rest/admin/partners/${partnerId}/origins/${originId}/menuitems`, data);

export interface Options {
  [key: string]: string[];
}

export const fetchOptions = (partnerId: string, originId: string) =>
  axios.get<Options>(`/rest/admin/partners/${partnerId}/origins/${originId}/menuitems/types`);
