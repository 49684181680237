import { CheckCircleFilled, CloseCircleFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Alert, Table, Input, Button, Tag } from 'antd';
import classNames from 'classnames';
import { action, observable, runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType, ChangeEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { Customization, CUSTOMIZATION_TYPES } from 'api/customizationsApi';
import EditCustomization from 'components/CustomizationsCommon/EditCustomization';
import PageHeader from 'components/PageHeader';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { ROLES_DESCRIPTION_KEY } from 'constants/roles';
import { TEST_IDS } from 'constants/testIds';
import RootStoreContext from 'context/RootStoreContext';
import { DataError } from 'errors/DataError';

import styles from './Customizations.module.css';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

@observer
class Customizations extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable editedCustomization: Customization | null = null;
  @observable searchTerm = '';

  async componentDidMount() {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;

    this.context.customizationsStore.fetchCareUnitCustomizations(careProviderId, careUnitId);
  }

  handleSave = async (data: Customization) => {
    const {
      intl,
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    const { customizationsStore, flashMessageService } = this.context;

    try {
      if (this.editedCustomization === null) {
        throw new DataError();
      }

      const updatedCustomization = {
        ...this.editedCustomization,
        value: data.value,
        roles: data.roles,
      };

      await customizationsStore.updateCareUnitCustomization(
        updatedCustomization,
        careProviderId,
        careUnitId
      );

      runInAction(() => {
        this.editedCustomization = null;
      });

      flashMessageService.success(
        intl.formatMessage({ id: 'origin.customizations.customization-updated' })
      );
      /* eslint-disable no-empty */
    } catch (e) {}
  };

  @action
  handleCancel = () => {
    this.editedCustomization = null;
  };

  @action
  handleEdit = (index: number) => {
    this.editedCustomization = this.customizationsList[index];
  };

  @action
  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = event.target.value.toLowerCase();
  };

  renderRoles = (_: string, record: Customization) => {
    return (
      <div className={styles.rolesValues}>
        <Tag className={styles.rolesValuesTag}>
          <FormattedMessage id="general.all" />
        </Tag>
        {record.roles?.length &&
          record.roles.map((elem, idx) => (
            <Tag
              className={classNames(styles.rolesValuesTag, styles.roleItem)}
              key={`roles-${idx}`}
            >
              {ROLES_DESCRIPTION_KEY[elem.role]
                ? this.props.intl.formatMessage({
                    id: ROLES_DESCRIPTION_KEY[elem.role],
                  })
                : elem.role}
            </Tag>
          ))}
      </div>
    );
  };

  renderValues = (_: string, { type, value, roles }: Customization) => {
    return (
      <div className={styles.rolesValues}>
        {this.renderValue(type, value)}
        {roles?.length &&
          roles.map((elem, idx) => (
            <div className={styles.roleItem} key={`roleValues-${idx}`}>
              {this.renderValue(type, elem.value)}
            </div>
          ))}
      </div>
    );
  };

  renderValue = (type: CUSTOMIZATION_TYPES, value: any) => {
    switch (type) {
      case CUSTOMIZATION_TYPES.BOOLEAN:
        return value === true ? (
          <span>
            <CheckCircleFilled className={classNames(styles.booleanIcon, styles.trueIcon)} />
            <FormattedMessage id="general.true" />
          </span>
        ) : (
          <span>
            <CloseCircleFilled className={classNames(styles.booleanIcon, styles.falseIcon)} />
            <FormattedMessage id="general.false" />
          </span>
        );
      case CUSTOMIZATION_TYPES.TEXTLIST:
        return <p>{value ? `'${value.join("', '")}'` : ''}</p>;
      default:
        return <p>{value}</p>;
    }
  };

  get columns() {
    return [
      {
        title: <FormattedMessage id="origin.customizations.setting" />,
        dataIndex: 'header',
      },
      {
        title: <FormattedMessage id="general.roles" />,
        dataIndex: 'roles',
        render: this.renderRoles,
      },
      {
        title: <FormattedMessage id="general.value" />,
        dataIndex: 'value',
        render: this.renderValues,
      },
      {
        title: <FormattedMessage id="general.tags" />,
        dataIndex: 'tags',
        filters: this.context.customizationsStore.careUnitsCustomizationsTagFilters,
        onFilter: (value: any, record: Customization) => !!record.tags.includes(value as string),
        render: (_: string, record: Customization) => (
          <div className={styles.tags}>
            {record.tags.map((tag, idx) => (
              <Tag className={styles.tag} key={`${tag}-${idx}`}>
                {tag}
              </Tag>
            ))}
          </div>
        ),
      },
      {
        title: <FormattedMessage id="general.description" />,
        dataIndex: 'description',
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: EDIT_ACTIONS_FIELD,
        render: (_: string, customization: Customization, index: number) => (
          <Button
            type="link"
            icon={
              this.context.userPermissionsStore.canEditCurrentPartner && customization.editable ? (
                <EditOutlined />
              ) : (
                <EyeOutlined />
              )
            }
            onClick={() => this.handleEdit(index)}
            className={styles.actionBtn}
            data-testid={TEST_IDS.EDIT_BTN}
          />
        ),
      },
    ];
  }

  @computed
  get customizationsList() {
    const { customizationsStore } = this.context;
    const searchTerm = this.searchTerm.toLowerCase();
    const customizations = customizationsStore.careUnitCustomizationsSorted.slice();

    if (searchTerm) {
      return customizations.filter(
        item =>
          item.header.toLowerCase().includes(searchTerm) ||
          item.description?.toLowerCase().includes(searchTerm) ||
          item.key.toLowerCase().includes(searchTerm)
      );
    }
    return customizations;
  }

  render() {
    const { intl } = this.props;
    const { customizationsStore, userPermissionsStore } = this.context;

    return (
      <div data-testid="customizations">
        <Alert
          message={intl.formatMessage({ id: 'origin.customizations.slow-saving-alert' })}
          type="warning"
          showIcon
          className={styles.notification}
        />
        <PageHeader
          content="origin.customizations.header"
          canEdit={false}
          headerActions={
            <Input.Search
              placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
              onChange={this.handleSearchChange}
              className={styles.search}
            />
          }
        >
          <p className={styles.subheader}>
            <FormattedMessage id="origin.customizations.subheader" />
          </p>
        </PageHeader>
        <Fragment>
          <Table<Customization>
            dataSource={this.customizationsList}
            columns={this.columns}
            pagination={false}
            className={styles.table}
            loading={customizationsStore.isLoading() || customizationsStore.isSaving()}
            data-testid="customizations-list"
            rowKey="key"
          />
          <EditCustomization
            data={this.editedCustomization}
            isSaving={customizationsStore.isSaving()}
            isDisabled={
              !userPermissionsStore.canEditCurrentPartner || !this.editedCustomization?.editable
            }
            isVisible={this.editedCustomization !== null}
            onSubmit={this.handleSave}
            onCancel={this.handleCancel}
          />
        </Fragment>
      </div>
    );
  }
}

export default injectIntl(Customizations);
