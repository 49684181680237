import { Modal } from 'antd';
import { Location, History } from 'history';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt, withRouter, RouteComponentProps } from 'react-router-dom';

import { Subtract } from 'types/types';

export interface OnUnloadGuardProps {
  updateIsDirty: (isDirty: boolean) => void;
  isDirty: boolean;
}

const withOnUnloadGuard = <P extends OnUnloadGuardProps & RouteComponentProps>(
  WrappedComponent: ComponentType<P>
) => {
  @observer
  class ComponentWithOnUnloadGuard extends Component<Subtract<P, OnUnloadGuardProps>> {
    @observable isDirty = false;
    @observable confirmedNavigation = false;
    @observable modalVisible = false;
    @observable lastLocation?: Location<History.LocationState>;

    componentDidMount() {
      window.addEventListener('beforeunload', this.handleOnUnload);
    }

    componentWillUnmount() {
      window.removeEventListener('beforeunload', this.handleOnUnload);
    }

    // this part handles page reload
    handleOnUnload = (event: Event) => {
      if (this.isDirty) {
        // Chrome requires returnValue to be set
        event.returnValue = true;
        return true;
      }
      return undefined;
    };

    @action
    handleUpdateState = (value: boolean) => {
      this.isDirty = value;
    };

    @action
    handleModalShow = (location: Location<History.LocationState>) => {
      this.modalVisible = true;
      this.lastLocation = location;
    };

    @action
    handleModalClose = (callback?: any) => {
      this.modalVisible = false;
      typeof callback === 'function' && callback();
    };

    handleBlockedNavigation = (nextLocation: Location<History.LocationState>) => {
      if (!this.confirmedNavigation && this.isDirty) {
        this.handleModalShow(nextLocation);
        return false;
      }

      return true;
    };

    handleConfirmNavigationClick = () =>
      this.handleModalClose(() => {
        if (this.lastLocation) {
          const { history } = this.props;
          runInAction(() => {
            this.confirmedNavigation = true;
          });

          if (history) {
            history.push(this.lastLocation.pathname);
          }
        }
      });

    render() {
      return (
        <Fragment>
          <WrappedComponent {...(this.props as P)} updateIsDirty={this.handleUpdateState} />
          {
            // this part handles navigate away event
          }
          <Prompt when={this.isDirty} message={this.handleBlockedNavigation} />
          <Modal
            visible={this.modalVisible}
            title={<FormattedMessage id="general.confirm" />}
            onCancel={this.handleModalClose}
            onOk={this.handleConfirmNavigationClick}
            okButtonProps={{ shape: 'round' }}
            cancelButtonProps={{ shape: 'round' }}
            closable={false}
            okText={<FormattedMessage id="general.confirm" />}
            cancelText={<FormattedMessage id="general.cancel" />}
          >
            <p>
              <FormattedMessage id="general.onbeforeunload" />
            </p>
          </Modal>
        </Fragment>
      );
    }
  }

  return withRouter(ComponentWithOnUnloadGuard);
};

export default withOnUnloadGuard;
