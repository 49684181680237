import { useCallback, useContext, useEffect, useState } from 'react';

import { ACCESS_SCOPE_TYPES } from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import {
  SelectableNameIT,
  SelectableRoleIT,
  fetchCareUnitSelectableRoles,
  fetchOriginSelectableRoles,
  fetchSelectableCareProviders,
  fetchSelectableCareUnits,
  fetchSelectableOrigins,
  fetchSelectablePartners,
  fetchSelectableScopes,
} from 'modules/Roles/api/rolesApi';

export const useOptionsSelector = () => {
  const { partnersStore } = useContext(RootStoreContext);
  const [partnerId, setPartnerId] = useState(partnersStore.currentPartner?.id ?? '');
  const [selectablePartners, setSelectablePartners] = useState<string[]>([]);
  const [selectableScopes, setSelectableScopes] = useState<string[]>([]);
  const [selectableOrigins, setSelectableOrigins] = useState<SelectableNameIT[]>([]);
  const [selectableCareProviders, setSelectableCareProviders] = useState<SelectableNameIT[]>([]);
  const [selectableCareUnits, setSelectableCareUnits] = useState<SelectableNameIT[]>([]);
  const [selectableRole, setSelectableRole] = useState<SelectableRoleIT[]>([]);

  useEffect(() => {
    let isMounted = true;
    fetchSelectablePartners().then(({ data }) => {
      isMounted && setSelectablePartners(data.partners);
    });

    fetchSelectableScopes().then(({ data }) => {
      isMounted && setSelectableScopes(data.scopes);
    });

    fetchSelectableCareProviders(partnerId).then(({ data }) => {
      isMounted && setSelectableCareProviders(data.careProviders);
    });

    return () => {
      isMounted = false;
    };
  }, [partnerId, fetchSelectablePartners, fetchSelectableScopes, fetchSelectableCareProviders]);

  const onPartnerChange = useCallback((id: string, scopeId: ACCESS_SCOPE_TYPES) => {
    setPartnerId(id);
    onScopeChange(scopeId, id);
  }, []);

  const onScopeChange = useCallback((scope: ACCESS_SCOPE_TYPES, partnerId: string) => {
    if (scope === ACCESS_SCOPE_TYPES.CARE_UNIT) {
      fetchSelectableCareProviders(partnerId).then(({ data }) => {
        setSelectableCareProviders(data.careProviders);
      });
    } else {
      fetchSelectableOrigins(partnerId).then(({ data }) => {
        setSelectableOrigins(data.origins);
      });
    }
  }, []);

  const onOriginChange = useCallback((originId: string, partnerId: string, practitionerId) => {
    if (practitionerId) {
      fetchOriginSelectableRoles({ partnerId, originId, practitionerId }).then(({ data }) => {
        setSelectableRole(data.roles);
      });
    }
  }, []);

  const onCareProviderChange = useCallback((selectedCareProviderId: string, partnerId: string) => {
    fetchSelectableCareUnits({ partnerId, careProviderId: selectedCareProviderId }).then(
      ({ data }) => {
        setSelectableCareUnits(data.careUnits);
      }
    );
  }, []);

  const onCareUnitChange = useCallback(
    (careUnitId: string, partnerId: string, careProviderId: string, practitionerId: string) => {
      if (practitionerId && careProviderId) {
        fetchCareUnitSelectableRoles({
          partnerId,
          careUnitId,
          careProviderId,
          practitionerId,
        }).then(({ data }) => {
          setSelectableRole(data.roles);
        });
      }
    },
    []
  );

  return {
    selectablePartners,
    selectableScopes,
    onScopeChange,
    onPartnerChange,
    onOriginChange,
    onCareProviderChange,
    onCareUnitChange,
    selectableOrigins,
    selectableCareProviders,
    selectableCareUnits,
    selectableRole,
  };
};
