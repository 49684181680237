import { Modal, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import omit from 'lodash/omit';
import { Observer } from 'mobx-react';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { ALPHANUMERIC_CHARS_REGEX, NOT_ONLY_NUMBERS_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';
import { RulesPackageMetaDraft } from 'modules/Rules/types';
import { sortWithLocale } from 'utils/textUtils';

const { Text } = Typography;

/**
 * @notExported
 */
interface RulesPackageFormProps {
  isSaving: boolean;
  isDisabled?: boolean;
  onSubmit: (data: RulesPackageMetaDraft, copyOf?: string) => Promise<void>;
  onCancel: () => void;
  visible?: boolean;
}

/**
 * @notExported
 */
enum NEW_RULE_PACKAGE_MODE {
  NEW = 'new',
  REPLACE = 'replace',
}

/**
 * @notExported
 */
const newRuleModes = [
  { label: 'general.new', value: NEW_RULE_PACKAGE_MODE.NEW },
  { label: 'general.replace', value: NEW_RULE_PACKAGE_MODE.REPLACE },
];

/**
 * @notExported
 */
interface FormValues extends RulesPackageMetaDraft {
  mode: NEW_RULE_PACKAGE_MODE;
  copyOf?: string;
}

export const RulesPackageForm = ({
  visible = false,
  onSubmit,
  onCancel,
  isSaving,
  isDisabled = false,
}: RulesPackageFormProps) => {
  const intl = useIntl();
  const { rulesPackagesListStore, partnersStore } = useContext(RootStoreContext);
  const { partnerId } = partnersStore;

  const isFormDisabled = isDisabled || isSaving;
  const initialValues = {
    id: '',
    description: '',
    title: '',
    copyOf: '',
    mode: NEW_RULE_PACKAGE_MODE.NEW,
  };
  const newRuleModesOptions = newRuleModes.map(({ value, label }) => ({
    value,
    label: intl.formatMessage({ id: label }),
  }));

  const submitHandler = async (data: FormValues) => {
    const submitData: RulesPackageMetaDraft = omit(data, ['mode', 'copyOf']);
    let copyOf: string | undefined;

    if (data.mode === NEW_RULE_PACKAGE_MODE.REPLACE) {
      if (!data.copyOf) {
        throw new Error('Malformed form data');
      }
      copyOf = data.copyOf;
    }

    onSubmit(submitData, copyOf);
  };

  return (
    <Observer>
      {() => {
        const replaceRulesPackageSelectOptions = rulesPackagesListStore.rulesPackagesList
          .map(({ title, id }) => ({
            label: title,
            value: id,
          }))
          .sort((a, b) => sortWithLocale(a, b, 'label', intl.locale));
        const validationSchema = Yup.object().shape({
          id: Yup.string()
            .required(
              intl.formatMessage({
                id: 'general.errors.required',
              })
            )
            .test(
              'idIsUsed',
              intl.formatMessage({ id: 'general.errors.already-in-use' }),
              value =>
                value &&
                partnerId &&
                !rulesPackagesListStore.lowerCasedRulePackageIds.includes(
                  `${partnerId}-${value.toLowerCase()}`
                )
            )
            .matches(
              ALPHANUMERIC_CHARS_REGEX,
              intl.formatMessage({
                id: 'general.errors.alphanumeric-characters-validation',
              })
            )
            .matches(
              NOT_ONLY_NUMBERS_REGEX,
              intl.formatMessage({
                id: 'general.errors.not_only_numbers_validation',
              })
            ),
          title: Yup.string()
            .required(intl.formatMessage({ id: 'general.errors.required' }))
            .test(
              'titleIsUsed',
              intl.formatMessage({ id: 'general.errors.already-in-use' }),
              value =>
                value &&
                !rulesPackagesListStore.lowerCasedRulePackageTitles.includes(value.toLowerCase())
            ),
          copyOf: Yup.string().when('mode', {
            is: val => val === NEW_RULE_PACKAGE_MODE.REPLACE,
            then: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
            otherwise: Yup.string(),
          }),
        });

        return (
          <Modal
            visible={visible}
            destroyOnClose
            title={<FormattedMessage id="rules.packages.new-package" />}
            footer={null}
            closable={false}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
              render={({ isValid, values, dirty, setFieldValue }) => (
                <Form layout="vertical" labelAlign="left">
                  <Form.Item
                    name="mode"
                    required
                    label={<FormattedMessage id="rules.rule-package-add.mode" />}
                  >
                    <Select
                      name="mode"
                      disabled={isFormDisabled}
                      options={newRuleModesOptions}
                      onChange={() => {
                        setFieldValue('copyOf', '');
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="id" label={<FormattedMessage id="general.id" />}>
                    <Input
                      name="id"
                      disabled={isFormDisabled}
                      addonBefore={<Text>{partnerId}-</Text>}
                    />
                  </Form.Item>

                  {values.mode === NEW_RULE_PACKAGE_MODE.REPLACE && (
                    <Form.Item
                      name="copyOf"
                      required
                      label={<FormattedMessage id="general.replace" />}
                    >
                      <Select
                        name="copyOf"
                        disabled={isFormDisabled}
                        showSearch
                        optionFilterProp="label"
                        options={replaceRulesPackageSelectOptions}
                      />
                    </Form.Item>
                  )}
                  <Form.Item name="title" required label={<FormattedMessage id="general.title" />}>
                    <Input name="title" disabled={isFormDisabled} />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label={<FormattedMessage id="general.description" />}
                  >
                    <Input name="description" disabled={isFormDisabled} />
                  </Form.Item>
                  <FormActionButtons
                    isSaving={isSaving}
                    isValid={isValid && dirty}
                    onCancel={onCancel}
                    showCancelConfirm={dirty}
                    cancelDeclineText={<FormattedMessage id="general.cancel" />}
                  />
                </Form>
              )}
            ></Formik>
          </Modal>
        );
      }}
    </Observer>
  );
};
