import React from 'react';

export const HomeMedicine = () => (
  <svg width="20px" height="38px" viewBox="0 0 20 38">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-33.000000, -407.000000)">
        <g transform="translate(33.000000, 407.000000)">
          <path
            d="M3.52000008,4.18000009 L3.52000008,8.84400019 C7.68148014,10.3721458 12.2505203,10.3721458 16.4120004,8.84400019 L16.4120004,4.18000009 C12.1918176,5.33159383 7.74018286,5.33159383 3.52000008,4.18000009 L3.52000008,4.18000009 Z"
            fill="#DC6F6F"
            fillRule="nonzero"
          />
          <rect
            fill="#FAE7D9"
            fillRule="nonzero"
            x="0.48400001"
            y="17.4460004"
            width="18.6120004"
            height="10.0100002"
          />
          <path
            d="M9.81200021,36.9160008 L4.33400009,36.9160008 C0.198000004,36.9160008 0.572000012,33.3960007 0.572000012,33.3960007 L0.572000012,12.2100003 C0.572000012,10.0100002 3.10200007,8.84400019 3.10200007,8.84400019 C7.4021219,10.6044924 12.2218785,10.6044924 16.5220004,8.84400019 C16.5220004,8.84400019 19.1180004,10.1420002 19.1180004,12.3420003 L19.1180004,33.5060007 C19.1180004,33.5060007 19.4040004,36.9380008 15.3560003,37.0260008 L9.81200021,37.0260008"
            stroke="#222221"
            strokeWidth="1.10000002"
          />
          <path
            d="M3.34400007,2.55200006 L3.34400007,8.82200019"
            stroke="#222221"
            strokeWidth="1.10000002"
          />
          <path
            d="M16.6320004,2.94800006 L16.6320004,8.9980002"
            stroke="#222221"
            strokeWidth="1.10000002"
          />
          <path
            d="M0.48400001,17.4460004 L19.0960004,17.4460004"
            stroke="#222221"
            strokeWidth="1.10000002"
          />
          <path
            d="M0.48400001,27.4560006 L19.0960004,27.4560006"
            stroke="#222221"
            strokeWidth="1.10000002"
          />
          <path
            d="M9.65800021,19.9540004 L9.65800021,24.9480005"
            stroke="#222221"
            strokeWidth="1.10000002"
            strokeLinecap="round"
          />
          <path
            d="M12.1660003,22.4400005 L7.15000015,22.4400005"
            stroke="#222221"
            strokeWidth="1.10000002"
            strokeLinecap="round"
          />
          <ellipse
            fill="#EAB5B2"
            fillRule="nonzero"
            cx="9.94400022"
            cy="3.03600007"
            rx="6.53400014"
            ry="2.50800005"
          />
          <ellipse
            stroke="#222221"
            strokeWidth="1.10000002"
            cx="9.94400022"
            cy="2.94800006"
            rx="6.68800014"
            ry="2.39800005"
          />
        </g>
      </g>
    </g>
  </svg>
);
