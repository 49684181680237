import { BankOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType, ComponentType } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs/Breadcrumbs';
import PageWithSectionsContainer from 'components/PageWithSectionsContainer';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import PageWithSectionsHeader from 'components/PageWithSectionsHeader';
import SideMenu from 'components/SideMenu';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import PartnerStatus from 'modules/PartnerStatus';

import CareUnitBasicDetails from './CareUnitBasicDetails';
import Customizations from './Customizations';
import Phrases from './Phrases';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ careProviderId: string; careUnitId: string }> {}

@observer
class CareUnits extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  basePath = '/care-providers/:careProviderId/care-units/:careUnitId';

  get breadcrumbs(): AppBreadcrumbItem[] {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    return [
      {
        icon: <BankOutlined />,
        text: <FormattedMessage id="care-units.care-providers" />,
        link: '/care-providers',
      },
      {
        text: careProviderId,
        link: `/care-providers/${careProviderId}`,
      },
      {
        text: <FormattedMessage id="care-units.care-units" />,
        link: `/care-providers/${careProviderId}/care-units`,
      },
      {
        text: careUnitId,
        link: `/care-providers/${careProviderId}/care-units/${careUnitId}/basic-details`,
      },
    ];
  }

  async componentDidMount() {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    const { careUnitStore, careUnitsStore } = this.context;

    try {
      await careUnitStore.fetchCareUnit(careProviderId, careUnitId);
      await careUnitsStore.fetchCareProvider(careProviderId);
    } catch (e: any) {
      this.handleNotFound();
    }
  }

  async componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { careProviderId, careUnitId },
      },
    } = this.props;
    const { careUnitStore, careUnitsStore } = this.context;

    if (prevProps.match.params.careUnitId !== careUnitId) {
      try {
        await careUnitStore.fetchCareUnit(careProviderId, careUnitId);
        await careUnitsStore.fetchCareProvider(careProviderId);
      } catch (e: any) {
        this.handleNotFound();
      }
    }
  }

  componentWillUnmount() {
    this.context.careUnitStore.dispose();
  }

  handleNotFound = () => {
    const { match, history } = this.props;
    this.context.flashMessageService.translatedError(
      'care-units.errors.not-found',
      { autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT },
      { id: match.params.careUnitId }
    );
    history.push('/care-units');
  };

  get routes() {
    const {
      match: {
        params: { careUnitId },
      },
    } = this.props;
    const {
      userDataStore: { userCareUnits },
    } = this.context;
    const isUsersCareUnit = userCareUnits.some(({ id }) => careUnitId === id);
    const routes: { path: string; component: ComponentType<any> }[] = [
      {
        path: 'basic-details',
        component: CareUnitBasicDetails,
      },
      {
        path: 'customizations',
        component: Customizations,
      },
    ];

    if (isUsersCareUnit) {
      routes.push({
        path: 'phrases',
        component: Phrases,
      });
    }

    return routes;
  }

  get sideMenuLinks() {
    const {
      match: {
        params: { careUnitId, careProviderId },
      },
    } = this.props;

    return this.routes.map(({ path }) => {
      const fullPath = `/care-providers/${careProviderId}/care-units/${careUnitId}/${path}`;

      return {
        url: fullPath,
        id: fullPath,
        content: this.props.intl.formatMessage({ id: `care-units.${path}` }),
      };
    });
  }

  render() {
    const {
      careUnitStore: { careUnit },
    } = this.context;

    if (careUnit === undefined) {
      return null;
    }

    return (
      <Fragment>
        <PartnerStatus />
        <PageWithSectionsHeader
          title={
            <span>
              <FormattedMessage id="care-units.care-units" />: {careUnit.name}
            </span>
          }
          breadcrumbs={this.breadcrumbs}
        />
        <PageWithSectionsContainer>
          <SideMenu links={this.sideMenuLinks} />
          <PageWithSectionsContent>
            <Switch>
              {this.routes.map(({ path, component }) => (
                <Route key={path} path={`${this.basePath}/${path}`} component={component} />
              ))}
            </Switch>
          </PageWithSectionsContent>
        </PageWithSectionsContainer>
      </Fragment>
    );
  }
}

export default injectIntl(CareUnits);
