import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { TRANSLATION_KEYS } from 'constants/enums';

interface Props {
  translationKey: TRANSLATION_KEYS | string;
}

/**
 * Optional tooltip is available only when there's a translation string defined
 */
const OptionalTooltip: React.FC<Props> = ({ children, translationKey }) => {
  const { messages, formatMessage } = useIntl();

  if (translationKey && messages[translationKey]) {
    return (
      <>
        {children}{' '}
        <Tooltip title={formatMessage({ id: translationKey })}>
          <QuestionCircleOutlined />
        </Tooltip>
      </>
    );
  }

  return <>{children}</>;
};

export default OptionalTooltip;
