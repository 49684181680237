import { Modal } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import PlusFloatingButton from 'components/PlusFloatingButton';

interface FormValues {
  interviewId: string;
}

interface CreateTestProps {
  isSaving: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
  isDisabled?: boolean;
}

const CreateCode24TestModal: React.FC<CreateTestProps> = ({ isSaving, isDisabled, onSubmit }) => {
  const [visible, setVisible] = React.useState(false);

  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    interviewId: Yup.string().required(requiredErrorMessage),
  });

  const toggleModal = () => setVisible(!visible);

  const handleSubmit = (values: { interviewId: string }) => {
    onSubmit(values).then(() => setVisible(false));
  };

  return (
    <>
      <Modal
        visible={visible}
        destroyOnClose
        onCancel={toggleModal}
        footer={false}
        title={<FormattedMessage id="test.create.modal.title" />}
      >
        <Formik
          initialValues={{ interviewId: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty }) => (
            <Form layout="vertical">
              <Form.Item
                label={<FormattedMessage id={'test.form.interview.label'} />}
                name="interviewId"
              >
                <Input name="interviewId" />
              </Form.Item>
              <FormActionButtons
                saveLabel={<FormattedMessage id="test.form.save" />}
                isSaving={isSaving}
                isValid={isValid && dirty}
                showCancelConfirm={dirty}
              />
            </Form>
          )}
        </Formik>
      </Modal>
      {!isDisabled && <PlusFloatingButton testId="create-code24test-btn" onClick={toggleModal} />}
    </>
  );
};

export default CreateCode24TestModal;
