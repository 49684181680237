import { ReactNode } from 'react';

import { InputOption } from 'types/types';

type OptionType = { children: string; disabled: boolean; key: string; value: string };

// Use this utility when filtering customized Option in AntD Select component.
// It handles option type ReactNode (options provided to AntD select as children) search, which "regular"
// Select filter functionality cannot do.

export function filterSelectOption(
  value: string,
  option: InputOption | ReactNode,
  childIndex?: number
) {
  if (!option || typeof option !== 'object') {
    return false;
  }

  if ('children' in option && option.children) {
    const optionValue =
      childIndex !== undefined ? (option.children as JSX.Element[])[childIndex] : option.children;
    return (optionValue as string).toLowerCase().includes(value.toLowerCase());
  }

  return false;
}

interface FilterOption {
  (input: string, option: InputOption | ReactNode | OptionType): boolean;
}

export const filterSelectOptionV2: FilterOption = (
  value: string,
  option: InputOption | ReactNode | OptionType,
  childIndex?: number
) => {
  if (!option || typeof option !== 'object') {
    return false;
  }

  if ('children' in option && option.children) {
    const optionValue =
      childIndex !== undefined ? (option.children as JSX.Element[])[childIndex] : option.children;

    if (typeof optionValue === 'object') {
      // NB JSX.Element needs to be traverse recursively cos it can have nested elements
      return filterSelectOptionV2(value, (optionValue as JSX.Element).props);
    }
    return (optionValue as string).toLowerCase().includes(value.toLowerCase());
  }

  return false;
};

export const stringToSelectOption = (elem: string): InputOption => ({
  label: elem,
  value: elem,
});
