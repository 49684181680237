import { SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React, { Fragment, ReactNode, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';

import styles from './SideMenu.module.css';

export interface SideMenuLink {
  url?: string;
  id: string | number;
  // ReactNode for MenuItem, string (title) for SubMenu
  content?: ReactNode | string;
  testid?: string;
  submenus?: SideMenuLink[];
}

interface Props extends RouteComponentProps {
  links: SideMenuLink[];
  testid?: string;
  title?: ReactNode;
}

const renderLink = (link: SideMenuLink) => {
  if (link.submenus && link.content) {
    return (
      <Menu.SubMenu
        key={link.id}
        className={styles.link}
        title={link.content}
        data-testid={link.testid}
      >
        {link.submenus.map(renderLink)}
      </Menu.SubMenu>
    );
  }

  return (
    <Menu.Item key={link.url}>
      <NavLink
        to={link.url || '#'}
        className={styles.link}
        activeClassName={styles.active}
        key={link.id}
        data-testid={link.testid}
      >
        {link.content}
      </NavLink>
    </Menu.Item>
  );
};

const renderLinks = (links: SideMenuLink[], title: ReactNode, activeMenuKey: string) => (
  <Fragment>
    {title || (
      <div className={styles.title}>
        <SettingOutlined className={styles.titleIcon} />
        &nbsp;
        <span className={styles.titleText}>
          <FormattedMessage id="general.settings" />
        </span>
      </div>
    )}
    <Menu className={styles.navigation} mode="inline" selectedKeys={[activeMenuKey]}>
      {links.length && links.map(renderLink)}
    </Menu>
  </Fragment>
);

const SideMenu: FunctionComponent<Props> = ({ links = [], testid, title, location }) => (
  <div className={styles.container} data-testid={testid}>
    {renderLinks(links, title, location.pathname)}
  </div>
);

export default withRouter(SideMenu);
