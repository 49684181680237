import { DEFAULT_LOCALE } from 'constants/dateFormat';
import { LANGS } from 'constants/enums';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import translationsDe from 'translations/de.json';
import translationsEn from 'translations/en.json';
import translationsNl from 'translations/nl.json';
import translationsNo from 'translations/no.json';
import translationsSv from 'translations/sv.json';

export const TRANSLATIONS = {
  en: translationsEn,
  sv: translationsSv,
  no: translationsNo,
  nl: translationsNl,
  de: translationsDe,
};

export const getLanguage = () => {
  const lang = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);
  if (lang) {
    return lang as LANGS;
  } else {
    const browserLang = navigator?.language ? navigator.language.split('-')[0] : DEFAULT_LOCALE;
    if (browserLang in TRANSLATIONS) {
      return browserLang as LANGS;
    }
  }
  return DEFAULT_LOCALE;
};
