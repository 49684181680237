import { Modal, Divider, Input as AntdInput, Button } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Select } from 'formik-antd';
import union from 'lodash/union';
import { observable, IObservableArray, action, runInAction } from 'mobx';
import { observer, Observer } from 'mobx-react';
import React, { Component, ChangeEvent, Fragment, createRef, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import FormActionButtons from 'components/FormActionButtons';
import RichTextEditor from 'components/RichTextEditor';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';
import { sortWithLocale } from 'utils/textUtils';

import { Phrase } from '../../api/phrasesApi';

const { Search } = AntdInput;

interface Props extends WrappedComponentProps {
  initialValues?: Phrase;
  isSaving: boolean;
  categories: string[];
  onCancel: () => void;
  onSubmit: (data: Phrase) => void;
}

@observer
class AddEditPhrase extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable newCategoryName: string | null = null;
  @observable newCategories: IObservableArray<string> = observable.array([]);

  // Should be InputRef for antd version newer than 4.3.5. More details: https://platform24.atlassian.net/browse/AX-28008
  newCategoryInputRef = createRef<AntdInput>();

  formLayouts = {
    form: {
      labelCol: {
        sm: { span: 14 },
      },
      wrapperCol: {
        sm: { span: 10 },
      },
    },
    sideBySide: {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    },
    fullWidth: {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    },
  };

  constructor(props: Props, context: RootStore) {
    super(props);
  }

  @action
  handleNewCategoryAdd = () => {
    this.newCategoryName = '';

    // this has to happen in the next cycle, after input is rendered
    setTimeout(() => {
      const newCategoryInput = this.newCategoryInputRef.current;

      if (newCategoryInput) {
        newCategoryInput.focus();
      }
    });
  };

  @action
  handleNewCategoryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    this.newCategoryName = value ? value : null;
  };

  handleNewCategorySubmit =
    (setFieldValue: (fieldName: string, value: any) => any) => (value: string) => {
      if (value) {
        runInAction(() => {
          this.newCategoryName = null;
          this.newCategories.replace(this.newCategories.concat([value]));
        });

        setFieldValue('category', value);
      }
    };

  handleSubmit = (phrase: Phrase) => {
    this.props.onSubmit({
      ...phrase,
      category: phrase.category || null,
    });
  };

  render() {
    const { isSaving, onCancel, initialValues, categories } = this.props;

    if (!initialValues) {
      return null;
    }

    return (
      <Modal
        visible
        destroyOnClose
        title={
          initialValues.id ? (
            <FormattedMessage id="phrases.edit-phrase-label" />
          ) : (
            <FormattedMessage id="phrases.add-phrase-label" />
          )
        }
        footer={null}
        closable={false}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          render={({ isValid, values, setFieldValue, dirty }) => (
            <Observer>
              {() => (
                <Form layout="horizontal" labelAlign="left" {...this.formLayouts.form}>
                  <Form.Item
                    name="header"
                    label={<FormattedMessage id="phrases.table-header" />}
                    {...this.formLayouts.sideBySide}
                  >
                    <Input name="header" disabled={isSaving} />
                  </Form.Item>
                  <Divider />
                  <Form.Item
                    name="category"
                    label={<FormattedMessage id="phrases.table-category" />}
                    {...this.formLayouts.sideBySide}
                  >
                    {this.newCategoryName === null && (
                      <Select
                        name="category"
                        disabled={isSaving}
                        dropdownRender={menu => (
                          <Fragment>
                            {menu}
                            <Button
                              type="link"
                              onClick={this.handleNewCategoryAdd}
                              onMouseDown={e => e.preventDefault()}
                            >
                              + <FormattedMessage id="general.add-more" />
                            </Button>
                          </Fragment>
                        )}
                        options={union(categories, this.newCategories.slice())
                          .sort((a, b) => sortWithLocale(a, b))
                          .map(category => ({ value: category, label: category }))}
                      />
                    )}
                    {this.newCategoryName !== null && (
                      <Search
                        onChange={this.handleNewCategoryChange}
                        enterButton={<FormattedMessage id="general.add" />}
                        onSearch={this.handleNewCategorySubmit(setFieldValue)}
                        allowClear
                        ref={this.newCategoryInputRef}
                      />
                    )}
                  </Form.Item>
                  <Divider />
                  <Form.Item
                    name="text"
                    label={<FormattedMessage id="phrases.table-phrase" />}
                    {...this.formLayouts.fullWidth}
                  >
                    <RichTextEditor
                      isDisabled={isSaving}
                      initialValue={values.text}
                      onChange={data => {
                        setFieldValue('text', data);
                      }}
                      paragraphControls
                    />
                  </Form.Item>
                  <Divider />
                  <FormActionButtons
                    isSaving={isSaving}
                    isValid={isValid && dirty}
                    onCancel={onCancel}
                    showCancelConfirm={dirty}
                  />
                </Form>
              )}
            </Observer>
          )}
        />
      </Modal>
    );
  }
}

export default injectIntl(AddEditPhrase);
