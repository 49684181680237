import { getIn, useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import React from 'react';
import { useIntl } from 'react-intl';

import {
  ConnectedCustomTagInputProps,
  CustomTagInputOnAdd,
  CustomTagInputOnBlur,
  CustomTagInputOnRemove,
} from 'modules/Rules/types';

import { CustomTagInput } from './CustomTagInput';
import styles from '../RuleForm.module.css';

export const ListValueInput = ({
  name,
  onChange,
  onBlur,
  allowedValues,
  isDisabled,
  outcomeMeta,
}: ConnectedCustomTagInputProps) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const fieldValue = getIn(values, name) || [];

  const onAddHandler: CustomTagInputOnAdd = addedValue => {
    const newFieldValue = [...fieldValue, addedValue];
    setFieldValue(name, newFieldValue);
    setFieldTouched(name);
    onChange?.(newFieldValue);
  };

  const onRemoveHandler: CustomTagInputOnRemove = index => {
    const newFieldValue = [...fieldValue];
    newFieldValue.splice(index, 1);
    setFieldValue(name, newFieldValue);
    setFieldTouched(name);
    onChange?.(newFieldValue);
  };

  const onBlurHandler: CustomTagInputOnBlur = e => {
    setFieldTouched(name);
    onBlur && onBlur(e);
  };

  return (
    <Form.Item
      name={name}
      required
      className={styles.customTagWrapper}
      label={formatMessage({ id: 'rules.value' })}
    >
      <CustomTagInput
        isDisabled={isDisabled}
        onBlur={onBlurHandler}
        value={fieldValue.length === 0 ? undefined : fieldValue}
        outcomeMeta={outcomeMeta}
        onAdd={onAddHandler}
        onRemove={onRemoveHandler}
        allowedValues={allowedValues}
      />
    </Form.Item>
  );
};
