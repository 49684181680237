import { observable, IObservableArray, runInAction } from 'mobx';

import {
  fetchCarePlanGroups,
  CarePlanGroupsListItem,
} from 'modules/You24/CarePlanGroups/api/carePlanGroupsApi';
import StateManager from 'stores/abstractStores/StateManager';
import RootStore from 'stores/RootStore';

export default class CarePlanGroupsStore extends StateManager {
  @observable
  groups: IObservableArray<CarePlanGroupsListItem> = observable.array([]);

  constructor(private rootStore: RootStore) {
    super();
  }

  fetchCarePlanGroups = async () => {
    const { partnersStore } = this.rootStore;

    this.setLoading();

    try {
      const { data: groups } = await fetchCarePlanGroups(partnersStore.partnerId);

      this.setLoaded();

      runInAction(() => {
        this.groups.replace(groups);
      });
    } catch (e: any) {
      this.setError();
      throw e;
    }
  };

  getCarePlanGroup(groupId: string) {
    return this.groups.find(group => group.id === groupId);
  }
}
