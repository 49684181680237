import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button, Divider } from 'antd';
import { FieldArray, FormikValues } from 'formik';
import { Select, Form, Input } from 'formik-antd';
import transform from 'lodash/transform';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { InputOption } from 'types/types';

import styles from './RuleConditions.module.css';
import { OriginRuleCondition, OriginRuleConditionOperators } from '../../api/rulesApi';

interface Props {
  values: FormikValues;
  isDisabled: boolean;
}

const RuleConditions = ({ values, isDisabled }: Props) => (
  <FieldArray
    name="conditional"
    render={arrayHelpers => {
      const lastItemIndex = values.conditional.length - 1;
      return (
        <Fragment>
          {values.conditional.map((_: OriginRuleCondition, index: number) => (
            <Fragment key={index}>
              <div className={styles.header}>
                <div>
                  <FormattedMessage id="origin.rules.condition" /> {index + 1}
                </div>
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => arrayHelpers.remove(index)}
                  className={styles.deleteButton}
                  disabled={isDisabled}
                />
              </div>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={`conditional.${index}.field`}
                    label={<FormattedMessage id="origin.rules.field" />}
                    required
                  >
                    <Input name={`conditional.${index}.field`} disabled={isDisabled} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`conditional.${index}.operator`}
                    label={<FormattedMessage id="origin.rules.operator" />}
                    required
                  >
                    <Select
                      name={`conditional.${index}.operator`}
                      disabled={isDisabled}
                      options={transform<
                        OriginRuleConditionOperators,
                        InputOption<OriginRuleConditionOperators, OriginRuleConditionOperators>[]
                      >(
                        OriginRuleConditionOperators,
                        (accumulator, value) => accumulator.push({ label: value, value }),
                        []
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`conditional.${index}.value`}
                    label={<FormattedMessage id="origin.rules.value" />}
                    required
                  >
                    <Input name={`conditional.${index}.value`} disabled={isDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              {index !== lastItemIndex && <Divider />}
            </Fragment>
          ))}
          {!values.conditional.length && (
            <p>
              <FormattedMessage id="origin.rules.condition-applies-to-all" />
            </p>
          )}
          <Button
            type="link"
            onClick={() => {
              arrayHelpers.push({
                field: '',
                operator: '',
                value: '',
              });
            }}
            className={styles.addButton}
            disabled={isDisabled}
          >
            + <FormattedMessage id="general.add-more" />
          </Button>
        </Fragment>
      );
    }}
  />
);

export default RuleConditions;
