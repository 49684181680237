import { Typography } from 'antd';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { OriginSave } from 'api/originsApi';
import withOnUnloadGuard, { OnUnloadGuardProps } from 'components/HOC/withOnUnloadGuard';
import PageWithSectionsContent from 'components/PageWithSectionsContent';
import PageWithSectionsHeader from 'components/PageWithSectionsHeader';
import UnitRolesTable from 'components/UnitRolesTable';
import RootStoreContext from 'context/RootStoreContext';
import OriginModel from 'models/OriginModel';
import PartnerStatus from 'modules/PartnerStatus';

import styles from './AddOrigin.module.css';
import OriginsDetailsForm from '../components/OriginsDetailsForm';

interface Props
  extends WrappedComponentProps,
    RouteComponentProps<{ parentOriginId: string }>,
    OnUnloadGuardProps {}

@observer
class AddOrigin extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  get breadcrumbs() {
    const {
      match: {
        params: { parentOriginId },
      },
    } = this.props;

    return [
      {
        iconName: 'home',
        text: <FormattedMessage id="origins.all-origins" />,
        link: `/origins?expanded=${parentOriginId}`,
      },
      {
        text: parentOriginId,
        link: `/origins/${parentOriginId}`,
      },
      {
        text: <FormattedMessage id="origins.add-origin" />,
      },
    ];
  }

  @action
  componentDidMount() {
    this.props.updateIsDirty(true);
    this.context.partnersStore.fetchPartnerUsersRoles();
  }

  handleSubmit = async (data: OriginSave) => {
    const {
      history,
      updateIsDirty,
      match: {
        params: { parentOriginId },
      },
    } = this.props;
    const { originStore, flashMessageService } = this.context;

    try {
      await originStore.createOrigin({ ...data, parentOriginId });

      flashMessageService.translatedSuccess('origins.origin-created-message');
      updateIsDirty(false);
      history.push(`/origins?expanded=${parentOriginId}`);
      /* eslint-disable no-empty */
    } catch (e: any) {}
  };

  handleCancel = () => {
    const {
      history,
      match: {
        params: { parentOriginId },
      },
    } = this.props;
    history.push(`/origins?expanded=${parentOriginId}`);
  };

  render() {
    const { partnersStore, originStore } = this.context;
    const { match } = this.props;

    return (
      <Fragment>
        <PartnerStatus />
        <PageWithSectionsHeader
          title={<FormattedMessage id="origins.add-origin" />}
          breadcrumbs={this.breadcrumbs}
        />
        <PageWithSectionsContent data-testid="add-origin" className={styles.contentWrapper}>
          <div className={styles.container}>
            <Typography.Title level={2}>
              <FormattedMessage id="origins.basic-details" />
            </Typography.Title>
            <OriginsDetailsForm
              initialValues={new OriginModel({ parentOriginId: match.params.parentOriginId })}
              isSaving={originStore.isSaving() || originStore.isLoading()}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              isDisabled={false}
            />
          </div>
          <div className={styles.rolesWrapper}>
            <Typography.Title level={2}>
              <FormattedMessage id="origins.add-origin.origin-roles-header" />
            </Typography.Title>
            <UnitRolesTable
              practitionersWithRoles={partnersStore.partnerRoles}
              isEditingAvailable={false}
            />
          </div>
        </PageWithSectionsContent>
      </Fragment>
    );
  }
}

export default withOnUnloadGuard(injectIntl(AddOrigin));
