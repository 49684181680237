import { ApartmentOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton, Typography } from 'antd';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import RootStoreContext from 'context/RootStoreContext';
import AddPractitioner from 'modules/You24/CarePlanGroups/components/AddPractitioner';
import PractitionersList from 'modules/You24/CarePlanGroups/components/PractitionersList';

import styles from './CarePlanGroupPractitioners.module.css';

interface CarePlanGroupPractitionersProps
  extends WrappedComponentProps,
    RouteComponentProps<{ groupId: string }> {}

const CarePlanGroupPractitioners = ({ intl, match }: CarePlanGroupPractitionersProps) => {
  const { carePlanGroupsStore, carePlanGroupPractitionersStore, flashMessageService } =
    useContext(RootStoreContext);

  const [isAddPractitionerVisible, setAddPractitionerVisible] = useState(false);

  useEffect(() => {
    if (!carePlanGroupsStore.isLoaded()) {
      carePlanGroupsStore.fetchCarePlanGroups();
    }
  }, [carePlanGroupsStore]);

  const showModal = () => setAddPractitionerVisible(true);
  const hideModal = () => setAddPractitionerVisible(false);

  const handleAddPractitioner = async (formData: { practitioners: string[] }) => {
    await carePlanGroupPractitionersStore.addPractitioner(
      match.params.groupId,
      formData.practitioners
    );

    setAddPractitionerVisible(false);

    flashMessageService.success(
      intl.formatMessage({ id: 'care-plan-group-practitioners.practitioner-added-message' })
    );
  };

  return (
    <Observer>
      {() => (
        <div className={styles.container}>
          <Skeleton loading={carePlanGroupsStore.isLoading()} active paragraph={{ rows: 1 }}>
            <PageHeader
              content={
                <Typography.Title level={2}>
                  <FormattedMessage
                    id="care-plan-group-practitioners.page-title"
                    values={{
                      careplan: carePlanGroupsStore.getCarePlanGroup(match.params.groupId)?.name,
                    }}
                  />
                </Typography.Title>
              }
              breadcrumbs={[
                {
                  icon: <ApartmentOutlined rotate={270} />,
                  text: <FormattedMessage id="care-plan-groups.page-title" />,
                  link: '/care-plan-groups',
                },
                {
                  text: (
                    <FormattedMessage
                      id="care-plan-group-practitioners.page-title"
                      values={{
                        careplan: carePlanGroupsStore.getCarePlanGroup(match.params.groupId)?.name,
                      }}
                    />
                  ),
                },
              ]}
            />
          </Skeleton>

          <Row gutter={[0, 16]}>
            <Col span={12}>
              <Typography.Title level={4}>
                <FormattedMessage id="care-plan-group-practitioners.practitioners" />
              </Typography.Title>
            </Col>
            <Col span={12} className={styles.addPractitioner}>
              <Button type="link" onClick={showModal}>
                <UserAddOutlined />
                <FormattedMessage id="care-plan-group-practitioners.add-practitioner" />
              </Button>
            </Col>
          </Row>

          <PractitionersList groupId={match.params.groupId} />

          <AddPractitioner
            groupId={match.params.groupId}
            isVisible={isAddPractitionerVisible}
            onCancel={hideModal}
            onSubmit={handleAddPractitioner}
          />
        </div>
      )}
    </Observer>
  );
};

export default injectIntl(CarePlanGroupPractitioners);
