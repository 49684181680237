export enum RESOURCE_TYPES {
  CUSTOMIZATIONS = 'customizations',
  CODE24 = 'code24',
  SELFCARE = 'selfcare',
  HOOKS = 'hooks',
  TOPLIST = 'toplist',
  CAREPLANNER = 'careplanner',
  RULES = 'rules',
  PRACTITIONERS = 'practitioners',
  CARE_UNITS = 'careunits',
  ORIGINS = 'origins',
}

export enum ROLE_MENUITEMS {
  MEDICAL_CONTENT_EDITOR = 'medical_content_editor',
  MEDICAL_CONTENT_VIEWER = 'medical_content_viewer',
  CLINIC_USER_ADMIN = 'clinic_user_admin',
  BUSINESS_RULES_EDITOR = 'business_rules_editor',
  BUSINESS_RULES_VIEWER = 'business_rules_viewer',
  CAREPLANNER_EDITOR = 'careplanner_editor',
  CAREPLANNER_VIEWER = 'careplanner_viewer',
  SUPER_ADMIN = 'super_admin',
  CONFIG_ADMIN = 'config_admin',
  PATHWAY_EDITOR = 'pathway_editor',
  PATHWAY_VIEWER = 'pathway_viewer',
}
