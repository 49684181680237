import { BranchesOutlined } from '@ant-design/icons';
import { Skeleton, Typography } from 'antd';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './CarePathways.module.css';
import { usePathwaysProps } from './carePathways.utils';
import withRootStoreProp from '../../components/HOC/withRootStoreProp';
import PageHeader from '../../components/PageHeader';
import { getLanguage } from '../../context/lang.utils';
import RootStore from '../../stores/RootStore';
import RemoteComponent from '../../utils/remoteModules/remoteComponent';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

const CreateCarePathwayTemplatePage = ({ rootStore }: { rootStore: RootStore }) => {
  const [pathwayTemplateTitle, setPathwayTemplateTitle] = useState('');

  const pathwayTemplateCreatorProps = usePathwaysProps(rootStore, {
    languages: {
      default: getLanguage(),
      list: ['en', 'sv'],
    },
    updatePathwayTemplateTitle: setPathwayTemplateTitle,
  });

  const title = pathwayTemplateTitle ? (
    <span>{pathwayTemplateTitle}</span>
  ) : (
    <FormattedMessage id="carePathways.create-page.unnamed-template" />
  );

  return (
    <>
      <PartnerStatus />
      <div className={styles.container}>
        <PageHeader
          content={<Typography.Title level={2}>{title}</Typography.Title>}
          breadcrumbs={[
            {
              icon: <BranchesOutlined />,
              text: <FormattedMessage id="carePathways.page-title" />,
              link: '/carePathways',
            },
            {
              text: title,
            },
          ]}
        />

        <React.Suspense fallback={<Skeleton />}>
          <RemoteComponent
            suspense
            module="pathwaysManage/PathwayTemplateCreator"
            props={pathwayTemplateCreatorProps}
          />
        </React.Suspense>
      </div>
    </>
  );
};

export default withRootStoreProp(CreateCarePathwayTemplatePage);
