export enum ROLES {
  ADMIN = 'ADMIN',
  ADMINISTRATOR = 'ADMINISTRATOR',
  AMBULANCE_PARAMEDICS = 'AMBULANCE_PARAMEDICS',
  ANONYMOUS = 'ANONYMOUS',
  ASSISTANT_NURSE = 'ASSISTANT_NURSE',
  AUDIOLOGIST = 'AUDIOLOGIST',
  BIOMEDICAL_ANALYST = 'BIOMEDICAL_ANALYST',
  CARE_COORDINATOR = 'CARE_COORDINATOR',
  CARE_PLANNER = 'CARE_PLANNER',
  CARER = 'CARER',
  CHIROPRACTOR = 'CHIROPRACTOR',
  CURATOR = 'CURATOR',
  CYTOTECHNOLOGIST = 'CYTOTECHNOLOGIST',
  DENTAL_HYGIENIST = 'DENTAL_HYGIENIST',
  DENTAL_NURSE = 'DENTAL_NURSE',
  DENTIST = 'DENTIST',
  DERMATOLOGIST = 'DERMATOLOGIST',
  DIETICIAN = 'DIETICIAN',
  DISTRICT_NURSE = 'DISTRICT_NURSE',
  DOCTOR = 'DOCTOR',
  HEALTH_EDUCATOR = 'HEALTH_EDUCATOR',
  HEALTH_SCIENCE = 'HEALTH_SCIENCE',
  HEARING_EDUCATOR = 'HEARING_EDUCATOR',
  INTERN_PSYCHOLOGIST = 'INTERN_PSYCHOLOGIST',
  IRREGULARITY_ASSESSOR = 'IRREGULARITY_ASSESSOR',
  LOW_VISION_SPECIALIST = 'LOW_VISION_SPECIALIST',
  MEDICAL_ADMIN = 'MEDICAL_ADMIN',
  MIDWIFE = 'MIDWIFE',
  MOLECULAR_BIOLOGIST = 'MOLECULAR_BIOLOGIST',
  NAPRAPATH = 'NAPRAPATH',
  NURSE = 'NURSE',
  OCCUPATIONAL_THERAPIST = 'OCCUPATIONAL_THERAPIST',
  OPHTHALMOLOGIST = 'OPHTHALMOLOGIST',
  OPTICIAN = 'OPTICIAN',
  ORTHOPEDIC_SURGEON = 'ORTHOPEDIC_SURGEON',
  PATIENT = 'PATIENT',
  PEDIATRICIAN = 'PEDIATRICIAN',
  PERSONAL_TRAINER = 'PERSONAL_TRAINER',
  PHARMACIST = 'PHARMACIST',
  PHYSICIAN_ASSISTANT = 'PHYSICIAN_ASSISTANT',
  PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
  PODIATRIST = 'PODIATRIST',
  PRESCHOOL_TEACHER = 'PRESCHOOL_TEACHER',
  PSYCHIATRIST = 'PSYCHIATRIST',
  PSYCHOLOGIST = 'PSYCHOLOGIST',
  PSYCHOTHERAPIST = 'PSYCHOTHERAPIST',
  RECEPTIONIST = 'RECEPTIONIST',
  REHAB_ASSISTANT = 'REHAB_ASSISTANT',
  REHAB_COORDINATOR = 'REHAB_COORDINATOR',
  HEALTH_REHAB_COORDINATOR = 'HEALTH_REHAB_COORDINATOR',
  SCHEDULER = 'SCHEDULER',
  SECRETARY = 'SECRETARY',
  SOCIAL_WORKER = 'SOCIAL_WORKER',
  SPECIAL_EDUCATOR = 'SPECIAL_EDUCATOR',
  SPEECH_THERAPIST = 'SPEECH_THERAPIST',
  SPORTS_EDUCATOR = 'SPORTS_EDUCATOR',
  SPORTS_LEADER = 'SPORTS_LEADER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SUPER_DOCTOR = 'SUPER_DOCTOR',
  SVF_COORDINATOR = 'SVF_COORDINATOR',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  THERAPIST = 'THERAPIST',
  TIMESLOTTER = 'TIMESLOTTER',
  TREATMENT_EDUCATOR = 'TREATMENT_EDUCATOR',
  TREATMENT_STAFF = 'TREATMENT_STAFF',
  WELLNESS_EDUCATOR = 'WELLNESS_EDUCATOR',
}

export const LOGIN_ALLOWED_ROLES = [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.SYSTEM_ADMIN];

export const DEPRECATED_ROLES = [ROLES.PATIENT, ROLES.SUPER_DOCTOR, ROLES.ANONYMOUS];

export const DEFAULT_PRACTITIONER_RECEIVING_ROLE = ROLES.DOCTOR;
export const DEFAULT_ADMINISTRATIVE_ROLE = ROLES.ADMIN;

export const ROLES_DESCRIPTION_KEY: Record<ROLES, string> = {
  [ROLES.ADMIN]: 'roles.ADMIN',
  [ROLES.ADMINISTRATOR]: 'roles.ADMINISTRATOR',
  [ROLES.AMBULANCE_PARAMEDICS]: 'roles.AMBULANCE_PARAMEDICS',
  [ROLES.ANONYMOUS]: 'roles.ANONYMOUS',
  [ROLES.ASSISTANT_NURSE]: 'roles.ASSISTANT_NURSE',
  [ROLES.AUDIOLOGIST]: 'roles.AUDIOLOGIST',
  [ROLES.BIOMEDICAL_ANALYST]: 'roles.BIOMEDICAL_ANALYST',
  [ROLES.CARE_COORDINATOR]: 'roles.CARE_COORDINATOR',
  [ROLES.CARE_PLANNER]: 'roles.CARE_PLANNER',
  [ROLES.CARER]: 'roles.CARER',
  [ROLES.CHIROPRACTOR]: 'roles.CHIROPRACTOR',
  [ROLES.CURATOR]: 'roles.CURATOR',
  [ROLES.CYTOTECHNOLOGIST]: 'roles.CYTOTECHNOLOGIST',
  [ROLES.DENTAL_HYGIENIST]: 'roles.DENTAL_HYGIENIST',
  [ROLES.DENTAL_NURSE]: 'roles.DENTAL_NURSE',
  [ROLES.DENTIST]: 'roles.DENTIST',
  [ROLES.DERMATOLOGIST]: 'roles.DERMATOLOGIST',
  [ROLES.DIETICIAN]: 'roles.DIETICIAN',
  [ROLES.DISTRICT_NURSE]: 'roles.DISTRICT_NURSE',
  [ROLES.DOCTOR]: 'roles.DOCTOR',
  [ROLES.HEALTH_EDUCATOR]: 'roles.HEALTH_EDUCATOR',
  [ROLES.HEALTH_REHAB_COORDINATOR]: 'roles.HEALTH_REHAB_COORDINATOR',
  [ROLES.HEALTH_SCIENCE]: 'roles.HEALTH_SCIENCE',
  [ROLES.HEARING_EDUCATOR]: 'roles.HEARING_EDUCATOR',
  [ROLES.INTERN_PSYCHOLOGIST]: 'roles.INTERN_PSYCHOLOGIST',
  [ROLES.IRREGULARITY_ASSESSOR]: 'roles.IRREGULARITY_ASSESSOR',
  [ROLES.LOW_VISION_SPECIALIST]: 'roles.LOW_VISION_SPECIALIST',
  [ROLES.MEDICAL_ADMIN]: 'roles.MEDICAL_ADMIN',
  [ROLES.MIDWIFE]: 'roles.MIDWIFE',
  [ROLES.MOLECULAR_BIOLOGIST]: 'roles.MOLECULAR_BIOLOGIST',
  [ROLES.NAPRAPATH]: 'roles.NAPRAPATH',
  [ROLES.NURSE]: 'roles.NURSE',
  [ROLES.OCCUPATIONAL_THERAPIST]: 'roles.OCCUPATIONAL_THERAPIST',
  [ROLES.OPHTHALMOLOGIST]: 'roles.OPHTHALMOLOGIST',
  [ROLES.OPTICIAN]: 'roles.OPTICIAN',
  [ROLES.ORTHOPEDIC_SURGEON]: 'roles.ORTHOPEDIC_SURGEON',
  [ROLES.PATIENT]: 'roles.PATIENT',
  [ROLES.PEDIATRICIAN]: 'roles.PEDIATRICIAN',
  [ROLES.PERSONAL_TRAINER]: 'roles.PERSONAL_TRAINER',
  [ROLES.PHARMACIST]: 'roles.PHARMACIST',
  [ROLES.PHYSICIAN_ASSISTANT]: 'roles.PHYSICIAN_ASSISTANT',
  [ROLES.PHYSIOTHERAPIST]: 'roles.PHYSIOTHERAPIST',
  [ROLES.PODIATRIST]: 'roles.PODIATRIST',
  [ROLES.PRESCHOOL_TEACHER]: 'roles.PRESCHOOL_TEACHER',
  [ROLES.PSYCHIATRIST]: 'roles.PSYCHIATRIST',
  [ROLES.PSYCHOLOGIST]: 'roles.PSYCHOLOGIST',
  [ROLES.PSYCHOTHERAPIST]: 'roles.PSYCHOTHERAPIST',
  [ROLES.RECEPTIONIST]: 'roles.RECEPTIONIST',
  [ROLES.REHAB_ASSISTANT]: 'roles.REHAB_ASSISTANT',
  [ROLES.REHAB_COORDINATOR]: 'roles.REHAB_COORDINATOR',
  [ROLES.SCHEDULER]: 'roles.SCHEDULER',
  [ROLES.SECRETARY]: 'roles.SECRETARY',
  [ROLES.SOCIAL_WORKER]: 'roles.SOCIAL_WORKER',
  [ROLES.SPECIAL_EDUCATOR]: 'roles.SPECIAL_EDUCATOR',
  [ROLES.SPEECH_THERAPIST]: 'roles.SPEECH_THERAPIST',
  [ROLES.SPORTS_EDUCATOR]: 'roles.SPORTS_EDUCATOR',
  [ROLES.SPORTS_LEADER]: 'roles.SPORTS_LEADER',
  [ROLES.SUPER_ADMIN]: 'roles.SUPER_ADMIN',
  [ROLES.SUPER_DOCTOR]: 'roles.SUPER_DOCTOR',
  [ROLES.SVF_COORDINATOR]: 'roles.SVF_COORDINATOR',
  [ROLES.SYSTEM_ADMIN]: 'roles.SYSTEM_ADMIN',
  [ROLES.THERAPIST]: 'roles.THERAPIST',
  [ROLES.TIMESLOTTER]: 'roles.TIMESLOTTER',
  [ROLES.TREATMENT_EDUCATOR]: 'roles.TREATMENT_EDUCATOR',
  [ROLES.TREATMENT_STAFF]: 'roles.TREATMENT_STAFF',
  [ROLES.WELLNESS_EDUCATOR]: 'roles.WELLNESS_EDUCATOR',
} as const;

export enum PARTNER_ROLES {
  ADMIN = 'ADMIN',
  WRITE = 'WRITE',
  READ = 'READ',
}

export enum ACCESS_SCOPE_TYPES {
  ALL = '*',
  PARTNER = 'PARTNER',
  ORIGIN = 'ORIGIN',
  CARE_UNIT = 'CARE_UNIT',
  CARE_PROVIDER = 'CARE_PROVIDER',
}

export const ACCESS_SCOPE_OPTIONS = [
  { id: ACCESS_SCOPE_TYPES.ALL, translationId: 'access-scope.*' },
  { id: ACCESS_SCOPE_TYPES.PARTNER, translationId: 'access-scope.PARTNER' },
  { id: ACCESS_SCOPE_TYPES.ORIGIN, translationId: 'access-scope.ORIGIN' },
  { id: ACCESS_SCOPE_TYPES.CARE_UNIT, translationId: 'access-scope.CARE_UNIT' },
  { id: ACCESS_SCOPE_TYPES.CARE_PROVIDER, translationId: 'access-scope.CARE_PROVIDER' },
];
