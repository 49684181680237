import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { observable, action, computed, IObservableArray } from 'mobx';
import { ChangeEvent } from 'react';

import { ChildOrigin } from 'api/partnersApi';
import RootStore from 'stores/RootStore';

export interface OriginListItem extends Omit<ChildOrigin, 'origins'> {
  name: string;
  children: OriginListItem[];
}

export default class OriginsListStore {
  @observable searchTerm = '';
  @observable private expandedRowKeys: IObservableArray<string> = observable.array([]);

  constructor(private rootStore: RootStore) {}

  @computed
  get originsList() {
    if (!this.searchTerm) {
      return this.rootOrigins;
    }

    return this.filterChildOrigin(this.rootOrigins, this.searchTerm);
  }

  @computed
  private get rootOrigins() {
    return this.transformChildOrigin(this.rootStore.partnersStore.rootOrigins);
  }

  @computed
  get nestedExpandedRowKeys() {
    const accumulator: string[][] = [];

    this.rootOrigins.slice().forEach(origin => {
      this.flattenOriginsTree(origin, [], this.expandedRowKeys.slice(), accumulator);
    });

    return uniq(flatten(accumulator));
  }

  @action
  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.searchTerm = event.target.value.toLowerCase();
  };

  @action
  replaceExpandedKeys = (keys: string[]) => {
    this.expandedRowKeys.replace(keys);
  };

  transformChildOrigin(childOrigins: ChildOrigin[]): OriginListItem[] {
    return childOrigins.map(origin => ({
      id: origin.id,
      name: origin.name || '',
      archived: origin.archived,
      children: origin.origins ? this.transformChildOrigin(origin.origins) : [],
      hostnames: origin.hostnames,
    }));
  }

  filterChildOrigin(childOrigins: OriginListItem[], text: string): OriginListItem[] {
    return childOrigins
      .filter(child => this.findInChildOrigin(child, text))
      .map(child => ({
        ...child,
        children: this.filterChildOrigin(child.children, text),
      }));
  }

  findInChildOrigin(childOrigin: OriginListItem, text: string): boolean {
    if (
      childOrigin.id.toLowerCase().includes(text) ||
      childOrigin.name.toLowerCase().includes(text)
    ) {
      return true;
    }

    if (childOrigin.children.length) {
      return childOrigin.children.some(child => this.findInChildOrigin(child, text));
    }

    return false;
  }

  flattenOriginsTree(
    origin: OriginListItem,
    base: string[],
    targetIds: string[],
    accumulator: string[][]
  ) {
    if (targetIds.includes(origin.id)) {
      accumulator.push(base.concat(origin.id));
    }

    if (!origin.children.length) {
      return;
    }

    for (let i = 0; i < origin.children.length; i += 1) {
      if (accumulator.length === targetIds.length) {
        break;
      }
      this.flattenOriginsTree(origin.children[i], base.concat(origin.id), targetIds, accumulator);
    }
  }
}
