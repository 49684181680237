import { Button } from 'antd';
import React, { Component, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as Logo } from 'assets/svg/manage24.svg';

import styles from './ErrorBoundary.module.css';

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithChildren<void>, ErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: unknown, info: Record<string, any>) {
    // Can use both error and info.componentStack and log them to @platform/analytics.
    // See https://platform24.atlassian.net/browse/AX-35777 for details
  }

  reloadAndGoHome = () => {
    window.location.href = '/';
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.header}>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.info}>
            <h1>
              <FormattedMessage id="fallback.oops" />
            </h1>
            <h4>
              <FormattedMessage id="fallback.header" />
            </h4>
            <h4>
              <FormattedMessage id="fallback.sorry" />
            </h4>
            <h5>
              <FormattedMessage id="fallback.report.problem" />
            </h5>
            <div>
              <Button className={styles.button} onClick={this.reloadAndGoHome}>
                <FormattedMessage id="fallback.home.button" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
