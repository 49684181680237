import sortBy from 'lodash/sortBy';
import React, { Component, ComponentType } from 'react';

import { Baby } from 'components/IconsSvg/Baby';
import { Covid19 } from 'components/IconsSvg/Covid19';
import { Headphones } from 'components/IconsSvg/Headphones';
import { HeartIcon } from 'components/IconsSvg/Heart';
import { HomeCloudIcon } from 'components/IconsSvg/HomeCloudIcon';
import { HomeCloudSun } from 'components/IconsSvg/HomeCloudSun';
import { HomeMagnifierIcon } from 'components/IconsSvg/HomeMagnifierIcon';
import { HomeMedicine } from 'components/IconsSvg/HomeMedicine';
import { HomeStethoscope } from 'components/IconsSvg/HomeStethoscope';
import { HomeStretching } from 'components/IconsSvg/HomeStretching';
import { HomeSunCloud } from 'components/IconsSvg/HomeSunCloud';
import { PainkillersIcon } from 'components/IconsSvg/PainkillersIcon';
import { Weights } from 'components/IconsSvg/Weights';
import { InputOption, Subtract } from 'types/types';

export interface IconsOptionsProps {
  iconsOptions: InputOption[];
}

const ICONS = {
  heart: HeartIcon,
  magnifier: HomeMagnifierIcon,
  pills: PainkillersIcon,
  cloud: HomeCloudIcon,
  cloud_sun: HomeCloudSun,
  headphones: Headphones,
  weights: Weights,
  stretching: HomeStretching,
  covid19: Covid19,
  stethoscope: HomeStethoscope,
  sun_cloud: HomeSunCloud,
  medicine: HomeMedicine,
  baby: Baby,
};

const withIconsOptions = <P extends IconsOptionsProps>(WrappedComponent: ComponentType<P>) => {
  class ComponentWithIconsOptions extends Component<Subtract<P, IconsOptionsProps>> {
    render() {
      const options = Object.keys(ICONS).map(key => {
        const Icon = ICONS[key];
        return {
          value: key,
          label: <Icon />,
        };
      });
      const sortedOptions = sortBy(options, ['label']);

      return <WrappedComponent {...(this.props as P)} iconsOptions={sortedOptions} />;
    }
  }

  return ComponentWithIconsOptions;
};

export default withIconsOptions;
