import { Tag } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import omit from 'lodash/omit';
import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { Origin, Alias, Hostname, OriginSave } from 'api/originsApi';
import FormActionButtons from 'components/FormActionButtons';
import { BASIC_DETAILS_FORM_FIELDS as FORM_FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import {
  validateSmsSender,
  validateNoSpaces,
  validateNoUppercase,
  validateAlphanumericAndHyphens,
  validateAlphanumericLowercaseAndDotAndDashes,
} from 'utils/validationUtils';

import BasicDetails from './components/BasicDetails';
import HostnamesList from './components/HostnamesList';
import UrlSuffixesList from './components/UrlSuffixesList';
import styles from './OriginsDetailsForm.module.css';
import { LETTERS_NUMBERS_SPACES_DASHES_REGEX } from '../../../../constants/regex';

export interface OriginDetailsFormData extends Origin {
  primaryAlias: number;
  primaryHostname: number;
  aliases: (Alias & { index: number })[];
  hostnames: (Hostname & { index: number })[];
  urlSuffixes: string[];
}

interface Props {
  initialValues: Origin;
  isSaving: boolean;
  onSubmit: (data: OriginSave) => void;
  onCancel?: () => void;
  isDisabled: boolean;
}

const hostnamesHaveValues = (hostnames: Hostname[]) =>
  hostnames.length > 0 && !hostnames.some(hostname => hostname.hostname === '');

const hostnameValidator = {
  name: 'Hostname is valid',
  message: () => <FormattedMessage id="origin.basic-details-form.id-error" />,
  test: (value: string) =>
    validateNoSpaces(value) && validateAlphanumericLowercaseAndDotAndDashes(value),
};

const idValidator = {
  name: 'Id is valid',
  message: () => <FormattedMessage id="origin.basic-details-form.id-error" />,
  test: (value: string) =>
    validateNoSpaces(value) && validateAlphanumericAndHyphens(value) && validateNoUppercase(value),
};

const urlSuffixValidator = {
  name: 'urlSuffix is valid',
  message: () => <FormattedMessage id="origin.basic-details-form.id-error" />,
  test: (value: string) =>
    validateNoSpaces(value) && validateAlphanumericLowercaseAndDotAndDashes(value),
};

const OriginsDetailsForm: FunctionComponent<Props> = ({
  initialValues,
  onSubmit,
  isDisabled,
  isSaving,
  onCancel,
}) => {
  const intl = useIntl();
  const { originId, parentOriginId } = useParams<{ originId?: string; parentOriginId?: string }>();
  const { partnersStore } = useContext(RootStoreContext);

  const validationSchema = Yup.object().shape({
    [FORM_FIELDS.ID]: Yup.string()
      .required(() => <FormattedMessage id="general.errors.required" />)
      .test(idValidator),
    [FORM_FIELDS.NAME]: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () => (
      <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
    )),
    [FORM_FIELDS.CLINIC_NAME]: Yup.string().matches(LETTERS_NUMBERS_SPACES_DASHES_REGEX, () => (
      <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
    )),
    [FORM_FIELDS.BANK_ID_DISPLAY_NAME]: Yup.string().matches(
      LETTERS_NUMBERS_SPACES_DASHES_REGEX,
      () => (
        <FormattedMessage id="general.errors.alphanumeric-characters-spaces-dashes-hyphens-validation" />
      )
    ),
    [FORM_FIELDS.COUNTRY_CODE]: Yup.string(),
    [FORM_FIELDS.SMS_DISPLAY_NAME]: Yup.string().test({
      name: 'SMS sender',
      message: () => <FormattedMessage id="origin.basic-details-form.sms-sender-error" />,
      test: value => validateSmsSender(value),
    }),
    [FORM_FIELDS.EMAIL]: Yup.string().email(() => (
      <FormattedMessage id="general.email-validation-error" />
    )),
    [FORM_FIELDS.HOSTNAMES]: Yup.array().of(
      Yup.object().shape({
        hostname: Yup.string().test(hostnameValidator),
        primary: Yup.boolean(),
      })
    ),
    [FORM_FIELDS.URL_SUFFIXES]: Yup.array().when('hostnames', {
      is: (val: Hostname[]) => !hostnamesHaveValues(val),
      then: Yup.array().of(
        Yup.string()
          .required(
            intl.formatMessage({
              id: 'general.errors.required',
            })
          )
          .test(urlSuffixValidator)
      ),
      otherwise: Yup.array().of(Yup.string().test(urlSuffixValidator)),
    }),
  });

  const handleSubmit = (data: OriginDetailsFormData) => {
    const saveData: OriginSave = {
      // remove form-related properties that are not accepted by API or are optional
      ...omit(data, ['primaryAlias', 'primaryHostname', 'urlSuffixes', 'hostnames']),
      // filter out empty values - they are allowed but should not be pushed to API
      // remove index property, set primary property according to form primaryAlias
      aliases: data.aliases
        .map((alias, index) => ({ ...omit(alias, 'index'), primary: index === data.primaryAlias }))
        .filter(({ alias }) => alias),
    };

    // filter out empty values - they are allowed but should not be pushed to API
    // remove index property, set primary property according to form primaryHostname
    const saveHostnames = data.hostnames
      .map((hostname, index) => ({
        ...omit(hostname, 'index'),
        primary: index === data.primaryHostname,
      }))
      .filter(({ hostname }) => hostname);

    if (saveHostnames.length) {
      saveData.hostnames = [...saveHostnames];
    }

    if (data.urlSuffixes && data.urlSuffixes.length) {
      saveData.urlSuffixes = data.urlSuffixes.filter(suffix => !!suffix);
    }

    onSubmit(saveData);
  };

  const getInheritedHostnames = () => {
    const isAddOriginPage = !originId && parentOriginId;
    if (isAddOriginPage) {
      return getInheritedHostnamesFromParent(parentOriginId!);
    }

    if (!originId || partnersStore.isRootOrigin(originId)) {
      return [];
    }

    return getInheritedHostnamesFromParent(originId);
  };

  const getInheritedHostnamesFromParent = (originId: string): Hostname[] => {
    const currentChildOrigin = partnersStore.allOrigins.slice().find(o => o.id === originId);
    if (!currentChildOrigin) {
      return [];
    }
    if (currentChildOrigin.hostnames.length) {
      return currentChildOrigin.hostnames;
    }
    if (currentChildOrigin.parentId) {
      return getInheritedHostnamesFromParent(currentChildOrigin.parentId);
    }

    return [];
  };

  let { aliases, hostnames, urlSuffixes } = initialValues;
  let parentHostnames: Hostname[] = [];

  if (!urlSuffixes || !urlSuffixes.length || !hostnames.length) {
    parentHostnames = getInheritedHostnames();
  }

  if (!hostnames || !hostnames.length) {
    hostnames = [{ hostname: '', primary: false }];
  }

  if (!aliases || !aliases.length) {
    aliases = [{ alias: '', primary: false }];
  }

  if (!urlSuffixes || urlSuffixes.length < 1) {
    urlSuffixes = [''];
  }

  return (
    <Formik
      initialValues={{
        ...initialValues,
        aliases: aliases.map((alias, index) => ({ ...alias, index })),
        hostnames: hostnames.map((hostname, index) => ({ ...hostname, index })),
        primaryAlias: aliases.findIndex(({ primary }) => primary),
        primaryHostname: hostnames.findIndex(({ primary }) => primary),
        urlSuffixes,
      }}
      // Origin details can change (e.g. from root to sub origin) after component is mounted,
      // so the form should react to this change.
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<OriginDetailsFormData>) => (
        <Form>
          <BasicDetails
            isSaving={isSaving}
            parentOriginId={parentOriginId || ''}
            isDisabled={isDisabled}
            isNew={!initialValues.id}
          />
          {!!parentHostnames.length && (
            <div className={styles.parentHostnames}>
              <p>
                <FormattedMessage id="origin.basic-details-form.inherited-hostnames" />
              </p>
              {parentHostnames.map(({ hostname }) => (
                <Tag key={hostname}>{hostname}</Tag>
              ))}
            </div>
          )}
          <HostnamesList
            isSaving={isSaving}
            isDisabled={isDisabled}
            hostnames={props.values.hostnames}
          />
          <UrlSuffixesList
            isSaving={isSaving}
            isDisabled={isDisabled}
            urlSuffixes={props.values.urlSuffixes}
            required={!hostnamesHaveValues(props.values.hostnames)}
          />
          <div className={styles.submit}>
            {!initialValues.id && onCancel && (
              <FormActionButtons isSaving={isSaving} isValid={props.isValid} onCancel={onCancel} />
            )}
            {initialValues.id && (
              <SubmitButton
                shape="round"
                key="submit"
                loading={isSaving}
                disabled={isSaving || isDisabled || !props.isValid}
              >
                <FormattedMessage id="general.save" />
              </SubmitButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OriginsDetailsForm;
