import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const HomeStretching = ({ color = COLORS.PINK }: Props) => (
  <svg width="36px" height="36px" viewBox="0 0 128 128">
    <g>
      <g>
        <path
          fill={color}
          d="M67.5,34.5c-6.7,0-12.1-5.4-12.1-12.1s5.4-12.1,12.1-12.1s12.1,5.4,12.1,12.1S74.2,34.5,67.5,34.5z     M67.5,14.3c-4.5,0-8.1,3.6-8.1,8.1s3.6,8.1,8.1,8.1c4.5,0,8.1-3.6,8.1-8.1S72,14.3,67.5,14.3z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M87.3,127.5c-3.5,0-6.6-2.4-7.4-5.9L69.4,84.5h-3.7l-10.6,37.2c-0.8,3.4-3.9,5.8-7.4,5.8    c-0.6,0-1.2-0.1-1.8-0.2c-2-0.5-3.7-1.7-4.7-3.4c-1.1-1.7-1.4-3.8-0.9-5.8l11.3-40c0.2-1.7,0.8-17.4,1.2-27    c0-8,6.6-14.6,14.7-14.6c8.1,0,14.7,6.6,14.7,14.7c0.4,9.6,1,25.3,1.2,27L94.8,118c0.5,2.1,0.2,4.1-0.9,5.9    c-1.1,1.7-2.7,3-4.7,3.4C88.5,127.4,87.9,127.5,87.3,127.5z M64.2,80.5h6.7c0.9,0,1.7,0.6,1.9,1.5l11,38.6c0.5,2,2.4,3.2,4.4,2.8    c0.9-0.2,1.7-0.8,2.3-1.6c0.5-0.8,0.7-1.8,0.4-2.7L79.6,79.1c-0.2-1-0.9-15.7-1.3-27.8c0-6-4.8-10.8-10.7-10.8    s-10.7,4.8-10.7,10.7c-0.5,12.2-1.1,26.9-1.3,27.8l-11.4,40.1c-0.2,0.9,0,1.8,0.5,2.7c0.5,0.8,1.3,1.4,2.3,1.6    c2,0.5,3.9-0.8,4.4-2.7l11-38.7C62.5,81.1,63.3,80.5,64.2,80.5z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M48.1,74.6c-1.5,0-3-0.5-4.2-1.5l-11.5-9.3c-1.7-1.4-2.6-3.5-2.4-5.7c0.2-2.2,1.4-4.1,3.3-5.2l24.6-14    c1-0.6,2.1-0.9,3.3-0.9c0.9,0,1.7,0.6,2,1.5c0.2,0.9-0.2,1.8-1,2.3c-3.3,1.8-5.4,5.3-5.4,9.1v2.7c0,0.7-0.4,1.4-1,1.7l-7.4,4.2    l3.9,3.2c2.8,2.3,3.3,6.5,1,9.3C52,73.7,50.1,74.6,48.1,74.6z M53.7,45.9L35.3,56.3c-0.8,0.4-1.2,1.2-1.3,2.1    c-0.1,0.9,0.3,1.7,1,2.3L46.4,70c1.1,0.9,2.8,0.7,3.7-0.4c0.9-1.1,0.7-2.8-0.4-3.7l-6.2-5.1c-0.5-0.4-0.8-1.1-0.7-1.7    c0.1-0.7,0.4-1.2,1-1.6l9-5.1v-1.5C52.8,49.1,53.1,47.4,53.7,45.9z"
        />
      </g>
      <g>
        <path
          fill={color}
          d="M80.2,55.4c-0.3,0-0.6-0.1-0.9-0.2c-0.7-0.3-1.1-1-1.1-1.8v-2.5c0-2.4-0.9-4.8-2.4-6.7    c-0.6-0.8-0.6-1.9,0.1-2.6l5.8-6.3c2.2-2.5,3.4-5.8,3-9.1L82.9,7.8c-0.2-1.8,0.4-3.5,1.5-4.9c1.1-1.4,2.7-2.2,4.5-2.4    c3.7-0.4,6.9,2.3,7.2,6l1.8,18.4c0.7,7-1.7,14.1-6.4,19.3L82.5,54c-0.3,0.4-0.7,0.7-1.1,1C81,55.3,80.6,55.4,80.2,55.4z     M79.9,43.1c1.1,1.7,1.8,3.5,2.1,5.5l6.4-7.1c4-4.4,6-10.3,5.4-16.2L92.1,6.9c-0.1-1.4-1.4-2.5-2.9-2.4c-0.7,0.1-1.3,0.4-1.8,0.9    C87,6,86.8,6.7,86.9,7.4l1.8,18.4c0.4,4.4-1.1,8.9-4.1,12.2L79.9,43.1z"
        />
      </g>
    </g>
  </svg>
);
