import { LeftOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';

import { SwedishSecureBankIdLoginForm } from './SwedishSecureBankIdLoginForm';

interface Props extends RouteComponentProps {
  isActive: boolean;
  onClick: () => void;
  onBack: () => void;
}

const SwedishSecureLogin = ({ isActive, onClick, onBack }: Props) => {
  if (isActive) {
    return (
      <Space direction="vertical" align="center">
        <Button
          type="default"
          onClick={onBack}
          icon={<LeftOutlined />}
          data-testid="choose-another-login-method-btn"
        >
          <FormattedMessage id="login.choose-another-login-method" />
        </Button>
        <SwedishSecureBankIdLoginForm />
      </Space>
    );
  }
  return (
    <Fragment>
      <Button
        onClick={onClick}
        type="primary"
        size="large"
        block
        data-testid="swedish-bank-id-login-btn"
      >
        <FormattedMessage id="login.with-bank-id" />
      </Button>
    </Fragment>
  );
};

export default withRouter(SwedishSecureLogin);
