import translationsSv from 'translations/sv.json';

export const State = {
  UNLOADED: 'unloaded',
  LOADING: 'loading',
  LOADED: 'loaded',
  ERROR: 'error',
  SAVING: 'saving',
};

export enum LANGS {
  EN = 'en',
  SV = 'sv',
  NO = 'no',
  DK = 'da',
  NL = 'nl',
  DE = 'de',
}

export const ACCEPT_LANGUAGE_HEADERS = {
  [LANGS.EN]: 'en-US,en',
  [LANGS.SV]: 'sv-sv,sv',
  [LANGS.NO]: 'no',
  [LANGS.DK]: 'da-DK,da',
  [LANGS.NL]: 'nl-NL, nl',
  [LANGS.DE]: 'de',
};

export const SYSTEM_CODES = {
  RESOURCE: 'alerisx:mhp:resourcetype',
  CONDITION: 'alerisx:mhp:condition',
};

export const NULLABLE_BOOL_OPTIONS = [
  { label: 'general.yes', value: true },
  { label: 'general.no', value: false },
  { label: 'general.null', value: null },
];

export enum TRANSLATION_SOURCE {
  SYSTEM = 'system',
}

export type TRANSLATION_KEYS = keyof typeof translationsSv;
