import classNames from 'classnames';
import { computed, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import { Practitioner } from 'api/practitionerApi';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';

import styles from './Practitioner.module.css';
import PractitionerData from './PractitionerData/PractitionerData';
import PractitionerRoles from './PractitionerRoles/PractitionerRoles';

interface Props extends RouteComponentProps<{ id: string }>, WrappedComponentProps {}

@observer
class PractitionerDetails extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  componentDidMount() {
    this.initialize();
    this.context.partnersStore.fetchCountryCallingCodes();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.context.practitionerStore.clear();
  }

  async initialize() {
    const {
      match: { params },
      history,
    } = this.props;
    const { practitionerStore, flashMessageService } = this.context;

    if (this.isAddMode) {
      this.context.practitionerStore.clear();
      return;
    }

    if (!practitionerStore.isLoading()) {
      try {
        await practitionerStore.fetch(params.id);
      } catch (e: any) {
        flashMessageService.translatedError(
          'practitioner.errors.not-found',
          { autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT },
          { id: params.id }
        );
        history.push('/practitioners');
      }
    }
  }

  handleSubmit = async (data: Practitioner) => {
    const { history, intl } = this.props;
    const { practitionerStore, flashMessageService } = this.context;

    try {
      const newPractitioner = await practitionerStore.save(data);

      flashMessageService.success(
        intl.formatMessage({
          id: data.id
            ? 'practitioner.practitioner-updated-message'
            : 'practitioner.new-practitioner-created-message',
        })
      );

      if (!data.id && newPractitioner.id) {
        history.push(`/practitioners/${newPractitioner.id}`);
      }
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  @computed
  get isAddMode() {
    return this.props.match.params.id === 'add';
  }

  render() {
    const {
      practitionerStore,
      practitionerFindStore,
      practitionerRolesStore,
      userPermissionsStore,
      userDataStore: userStore,
      partnersStore,
    } = this.context;
    const practitionerData = practitionerStore.data.id
      ? (practitionerStore.data as Practitioner)
      : undefined;
    const hasRoleAtUserCareUnit =
      userPermissionsStore.isSuperAdmin ||
      (!!practitionerData &&
        practitionerRolesStore.allRoles.some(({ careUnitId }) =>
          userStore.userCareUnits.some(({ id }) => careUnitId === id)
        ));
    const isClinicUserAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN
    );

    return (
      <div className={classNames(styles.practitionerEdit, styles.container)}>
        <PractitionerData
          initialValues={toJS(practitionerData)}
          countryCallingCodes={toJS(partnersStore.countryCallingCodes)}
          externalIdValidationFunction={practitionerFindStore.findByExternalId}
          onSubmit={this.handleSubmit}
          hasRoleAtUserCareUnit={hasRoleAtUserCareUnit}
          isFullyEditable={
            userPermissionsStore.isSuperAdmin || !practitionerData || isClinicUserAdmin
          }
          isLoading={practitionerStore.isLoading() || practitionerStore.isSaving()}
        />
        {!this.isAddMode && <PractitionerRoles />}
      </div>
    );
  }
}

export default injectIntl(PractitionerDetails);
