import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import nbNO from 'antd/es/locale/nb_NO';
import nlNL from 'antd/es/locale/nl_NL';
import svSE from 'antd/es/locale/sv_SE';
import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

const translationsMap = {
  en: enUS,
  sv: svSE,
  no: nbNO,
  nl: nlNL,
};

const AntdConfigProvider: FunctionComponent = ({ children }) => {
  const intl = useIntl();

  return <ConfigProvider locale={translationsMap[intl.locale]}>{children}</ConfigProvider>;
};

export default AntdConfigProvider;
