import { ApartmentOutlined } from '@ant-design/icons';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageHeader from 'components/PageHeader';

import styles from './CarePlanGroups.module.css';
import CarePlanGroupsList from '../CarePlanGroupsList';

const CarePlanGroups = () => (
  <div className={styles.container}>
    <PageHeader
      content="care-plan-groups.page-title"
      breadcrumbs={[
        {
          icon: <ApartmentOutlined rotate={270} />,
          text: <FormattedMessage id="care-plan-groups.page-title" />,
          link: '/care-plan-groups',
        },
      ]}
    />

    <CarePlanGroupsList />
  </div>
);

export default CarePlanGroups;
