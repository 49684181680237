import { runInAction, computed, action, observable, IObservableArray } from 'mobx';

import { MENU_ITEMS_PROPERTIES_VALUES } from 'constants/origins';
import RootStore from 'stores/RootStore';
import { generateUUID } from 'utils/uuidUtils';

import { fetchMenuItems, updateMenuItems, MenuItem, fetchOptions } from '../api/menuItemsApi';

export interface MenuItemTransformedProperty {
  type: string;
  value: any;
}

export interface MenuItemTransformed extends Omit<MenuItem, 'properties'> {
  properties: MenuItemTransformedProperty[];
}

export default class MenuItemsStore {
  menuItems: IObservableArray<MenuItemTransformed> = observable.array([]);
  @observable
  menuItemTypes: Record<string, string[]> = {};
  @observable isLoading = false;

  // activeMenuItem serves as initialValues for Formik form. If it's not shallow, "properties" observable
  // array leads to cryptic errors being thrown when changing value in form.
  @observable.shallow activeMenuItem?: MenuItemTransformed;

  constructor(private rootStore: RootStore, private originId: string) {}

  initialize = () => Promise.all([this.fetchMenuItems(), this.fetchMenuItemTypes()]);

  @action
  fetchMenuItems = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchMenuItems(this.rootStore.partnersStore.partnerId, this.originId);

      runInAction(() => {
        this.menuItems.replace(data.map(this.mapToMenuItemTransformed));
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  fetchMenuItemTypes = async () => {
    try {
      this.isLoading = true;
      const { data } = await fetchOptions(this.rootStore.partnersStore.partnerId, this.originId);

      runInAction(() => {
        this.menuItemTypes = data;
      });
      /* eslint-disable no-empty */
    } catch {
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @computed
  get menuItemTypeOptions() {
    return Object.keys(this.menuItemTypes).map(option => ({
      value: option,
      label: option,
    }));
  }

  @action
  handleEdit = (id: string) => {
    this.activeMenuItem = this.menuItems.find(menuItem => menuItem.id === id);
  };

  @action
  handleAdd = () => {
    this.activeMenuItem = {
      id: '',
      type: '',
      title: {},
      description: {},
      label: {},
      icon: '',
      properties: [],
    };
  };

  @action
  handleCancel = () => {
    this.activeMenuItem = undefined;
  };

  handleUpdateOrder = async (menuItems: MenuItemTransformed[]) => {
    this.save(menuItems.map(this.mapToMenuItem));
  };

  handleDelete = async (id: string) => {
    this.save(this.menuItems.filter(menuItem => menuItem.id !== id).map(this.mapToMenuItem));
  };

  handleSubmit = async (newMenuItem: MenuItemTransformed) => {
    try {
      const newMenuItems = this.menuItems.slice();

      if (newMenuItem.id === '') {
        newMenuItems.push({ ...newMenuItem, id: generateUUID() });
      } else {
        const newMenuItemIndex = this.menuItems.findIndex(
          menuItem => menuItem.id === newMenuItem.id
        );

        if (newMenuItemIndex === -1) {
          throw new Error('No such menu item');
        }

        newMenuItems[newMenuItemIndex] = newMenuItem;
      }

      this.save(newMenuItems.map(this.mapToMenuItem));
    } catch {}
  };

  @action
  private save = async (menuItems: MenuItem[]) => {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      await updateMenuItems(this.rootStore.partnersStore.partnerId, this.originId, menuItems);
      runInAction(() => {
        this.activeMenuItem = undefined;
        this.menuItems.replace(menuItems.map(this.mapToMenuItemTransformed));
      });
      this.rootStore.flashMessageService.translatedSuccess('origin.menu-items.menu-item-updated');
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  private mapToMenuItemTransformed = (menuItem: MenuItem): MenuItemTransformed => {
    const properties = menuItem.properties || {};

    return {
      ...menuItem,
      properties: Object.keys(properties)
        .map(propertyKey => ({
          type: propertyKey,
          value: properties[propertyKey],
        }))
        // We don't know what can be the type of this property value, so we prefer to not handle it at all,
        // rather than let it break something (see https://platform24.atlassian.net/browse/AX-11067).
        .filter(({ type }) => Object.values(MENU_ITEMS_PROPERTIES_VALUES).includes(type)),
    };
  };

  private mapToMenuItem = (menuItem: MenuItemTransformed): MenuItem => ({
    ...menuItem,
    properties: menuItem.properties.reduce((accumulator, current) => {
      accumulator[current.type] = current.value;
      return accumulator;
    }, {}),
  });
}
