import { Tabs } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import NorwegianDevLogin from '../NorwegianDevLogin';
import SwedishDevLogin from '../SwedishDevLogin/SwedishDevLogin';

const { TabPane } = Tabs;

const DevLogin = () => (
  <Tabs defaultActiveKey="1">
    <TabPane tab={<FormattedMessage id="general.hsa-id" />} key="1">
      <SwedishDevLogin />
    </TabPane>
    <TabPane tab={<FormattedMessage id="general.norwegian-personal-id" />} key="2">
      <NorwegianDevLogin />
    </TabPane>
  </Tabs>
);

export default DevLogin;
