import { BranchesOutlined } from '@ant-design/icons';
import { routes } from '@platform24/admin-ui-utils';
import { Skeleton } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

import styles from './CarePathways.module.css';
import { usePathwaysProps } from './carePathways.utils';
import withRootStoreProp from '../../components/HOC/withRootStoreProp';
import PageHeader from '../../components/PageHeader';
import RootStore from '../../stores/RootStore';
import RemoteComponent from '../../utils/remoteModules/remoteComponent';
import PartnerStatus from '../PartnerStatus/PartnerStatus';

const CarePathwayTemplatePage = ({ rootStore }: { rootStore: RootStore }) => {
  const { releaseStatus, templateName } = useParams<{
    templateName: string;
    releaseStatus: string;
  }>();

  const pathwayTemplateEditorProps = usePathwaysProps(rootStore, {
    templateName: templateName,
    releaseStatus: releaseStatus,
    languages: {
      default: 'en',
      list: ['en', 'sv'],
    },
  });

  return (
    <>
      <PartnerStatus />
      <div className={styles.container}>
        <PageHeader
          content="carePathwaysTemplate.page-title"
          breadcrumbs={[
            {
              icon: <BranchesOutlined />,
              text: <FormattedMessage id="carePathways.page-title" />,
              link: routes.pathways.overview.fullPath,
            },
            {
              text: templateName,
              link: routes.pathways.editTemplate.buildPath({
                templateName,
                releaseStatus: releaseStatus,
              }),
            },
          ]}
        />

        <React.Suspense fallback={<Skeleton />}>
          <RemoteComponent
            suspense
            module="pathwaysManage/PathwayTemplateEditor"
            props={pathwayTemplateEditorProps}
          />
        </React.Suspense>
      </div>
    </>
  );
};

export default withRootStoreProp(CarePathwayTemplatePage);
