import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from 'constants/dateFormat';
import { LANGS } from 'constants/enums';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';

import { TRANSLATIONS, getLanguage } from './lang.utils';

const LangContext = React.createContext({
  switchLang: (value: LANGS) => {
    console.log('default implementation of switchLang method', value);
  },
  lang: DEFAULT_LOCALE,
});

const { Provider, Consumer } = LangContext;

class IntlProviderWrapper extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };
  state: { lang: LANGS; messages: Record<string, string> };

  constructor(props: { children: React.ReactNode }) {
    super(props);
    const lang = getLanguage();

    this.state = {
      lang,
      messages: TRANSLATIONS[lang],
    };
  }

  switchLang = (lang: LANGS) => {
    this.setState({ lang, messages: TRANSLATIONS[lang] });
    localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, lang);
    window.location.reload();
  };

  render() {
    const { children } = this.props;
    const { lang, messages } = this.state;
    return (
      <Provider value={{ ...this.state, switchLang: this.switchLang }}>
        <IntlProvider
          key={lang}
          locale={lang}
          messages={messages}
          defaultLocale="en"
          textComponent={React.Fragment}
        >
          {children}
        </IntlProvider>
      </Provider>
    );
  }
}

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer };

export const useLang = () => React.useContext(LangContext);
