import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import { FieldArray, FormikValues } from 'formik';
import { Select, Form, Input, InputNumber, Checkbox } from 'formik-antd';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import {
  MENU_ITEMS_PROPERTIES_VALUES,
  MENU_ITEMS_PROPERTIES_VALUES_TIME_SLOT_TYPES,
} from 'constants/origins';
import { InputOption } from 'types/types';

import styles from './MenuItemProperties.module.css';
import { MenuItemTransformedProperty } from '../../stores/MenuItemsStore';

interface Props extends WrappedComponentProps {
  menuItemTypes: { [key: string]: string[] };
  values: FormikValues;
  resourceTypeOptions: InputOption[];
  setFieldValue: (fieldName: string, fieldValue: any) => any;
  isSaving: boolean;
  isDisabled?: boolean;
}

class MenuItemProperties extends Component<Props> {
  renderValueField(
    activeProperties: MenuItemTransformedProperty[],
    property: MenuItemTransformedProperty,
    index: number
  ) {
    const { resourceTypeOptions, intl, isSaving, isDisabled } = this.props;
    const isFieldDisabled = !property.type || isSaving || isDisabled;

    switch (activeProperties[index].type) {
      case MENU_ITEMS_PROPERTIES_VALUES.RESOURCE_TYPE:
        return (
          <Select
            name={`properties.${index}.value`}
            disabled={isFieldDisabled}
            options={resourceTypeOptions}
          />
        );
      case MENU_ITEMS_PROPERTIES_VALUES.LOGOUT:
      case MENU_ITEMS_PROPERTIES_VALUES.CHILD:
        return <Checkbox name={`properties.${index}.value`} disabled={isFieldDisabled} />;
      case MENU_ITEMS_PROPERTIES_VALUES.TIME_SLOTE_TIME_FRAME_IN_HOURS:
        return <InputNumber name={`properties.${index}.value`} disabled={isFieldDisabled} />;
      case MENU_ITEMS_PROPERTIES_VALUES.TIME_SLOT_TYPE:
        return (
          <Select
            name={`properties.${index}.value`}
            disabled={isFieldDisabled}
            options={Object.values(MENU_ITEMS_PROPERTIES_VALUES_TIME_SLOT_TYPES).map(value => ({
              value,
              label: value,
            }))}
          />
        );
      case MENU_ITEMS_PROPERTIES_VALUES.OPTIONS_IDS:
        return (
          <Input
            name={`properties.${index}.value`}
            disabled={isFieldDisabled}
            placeholder={intl.formatMessage({
              id: 'origin.menu-items.properties.option-ids-placeholder',
            })}
          />
        );
      case MENU_ITEMS_PROPERTIES_VALUES.INTERVIEW_PROPERTIES:
        return (
          <Input
            name={`properties.${index}.value`}
            disabled={isFieldDisabled}
            placeholder={intl.formatMessage({
              id: 'origin.menu-items.properties.interview-properties-placeholder',
            })}
          />
        );
      default:
        return <Input name={`properties.${index}.value`} disabled={isFieldDisabled} />;
    }
  }

  render() {
    const { menuItemTypes, values, setFieldValue, isSaving, isDisabled } = this.props;
    const fullWidthLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };
    const activeMenuItemType = values.type;
    const arePropertiesInActiveMenuItemType =
      menuItemTypes[activeMenuItemType] && menuItemTypes[activeMenuItemType].length;
    const properties: MenuItemTransformedProperty[] = values.properties;
    const activeProperties = arePropertiesInActiveMenuItemType
      ? properties.filter(({ type }) => menuItemTypes[activeMenuItemType].includes(type))
      : [];
    const availableOptionValues = menuItemTypes[activeMenuItemType]
      ? menuItemTypes[activeMenuItemType].filter(
          optionValue =>
            !activeProperties.some(
              (property: MenuItemTransformedProperty) => property.type === optionValue
            )
        )
      : [];
    const isFormDisabled = isSaving || isDisabled;

    return (
      <FieldArray
        name="properties"
        render={arrayHelpers => (
          <Fragment>
            {!activeProperties.length && (
              <Button
                type="link"
                onClick={() => {
                  arrayHelpers.push({
                    type: availableOptionValues[0],
                    value: '',
                  });
                }}
                className={styles.addButton}
                disabled={!arePropertiesInActiveMenuItemType || isFormDisabled}
              >
                + <FormattedMessage id="general.add-more" />
              </Button>
            )}
            {activeProperties.map((property, index) => (
              <Row key={index} gutter={16} align="middle">
                <Col xs={{ span: 24 }} sm={{ span: 11 }}>
                  <Form.Item
                    name={`properties.${index}.type`}
                    label={index === 0 && <FormattedMessage id="general.id" />}
                    {...fullWidthLayout}
                  >
                    <Select
                      name={`properties.${index}.type`}
                      onChange={() => {
                        setFieldValue(`properties.${index}.value`, '');
                      }}
                      disabled={isFormDisabled}
                      options={availableOptionValues.map(value => ({ value, label: value }))}
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 11 }}>
                  <Form.Item
                    name={`properties.${index}.value`}
                    label={index === 0 && <FormattedMessage id="general.value" />}
                    {...fullWidthLayout}
                  >
                    {this.renderValueField(activeProperties, property, index)}
                  </Form.Item>
                  {!!availableOptionValues.length && index === activeProperties.length - 1 && (
                    <div className={styles.addButtonContainer}>
                      <Button
                        type="link"
                        onClick={() => {
                          arrayHelpers.push({
                            type: availableOptionValues[0],
                            value: '',
                          });
                        }}
                        className={styles.addButton}
                        disabled={isFormDisabled}
                      >
                        + <FormattedMessage id="general.add-more" />
                      </Button>
                    </div>
                  )}
                </Col>
                <Col span={2}>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    className={index !== 0 ? styles.deleteButton : undefined}
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={isFormDisabled}
                  />
                </Col>
              </Row>
            ))}
          </Fragment>
        )}
      />
    );
  }
}

export default injectIntl(MenuItemProperties);
