import { SearchOutlined } from '@ant-design/icons';
import { Divider, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Observer } from 'mobx-react';
import React, { FunctionComponent, useContext, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';

import styles from './AddPractitionerForm.module.css';

export interface AddPractitionerFormData {
  practitioners: string[];
}

interface AddPractitionerFormProps {
  groupId: string;
  onCancel: () => void;
  onSubmit: (data: AddPractitionerFormData) => void;
}

const AddPractitionerForm: FunctionComponent<AddPractitionerFormProps> = ({
  groupId,
  onCancel,
  onSubmit,
}) => {
  const intl = useIntl();
  const { carePlanGroupPractitionersStore, carePlanGroupsStore } = useContext(RootStoreContext);

  const initialValues: AddPractitionerFormData = { practitioners: [] };

  const validationSchema = useMemo(() => {
    const requiredErrorMessage = intl.formatMessage({
      id: 'general.errors.required',
    });

    return Yup.object().shape({
      practitioners: Yup.array().of(Yup.string()).required(requiredErrorMessage),
    });
  }, [intl]);

  useEffect(() => {
    const carePlanGroup = carePlanGroupsStore.getCarePlanGroup(groupId);
    if (carePlanGroup) {
      carePlanGroupPractitionersStore.fetchPractitioners(
        carePlanGroup.careUnits || [carePlanGroup.careUnitId]
      );
    }
  }, [groupId, carePlanGroupPractitionersStore]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isValid, dirty }) => (
        <Observer>
          {() => (
            <Form layout="vertical">
              <Form.Item
                name="practitioner"
                label={<FormattedMessage id="care-plan-group-practitioners.search-practitioner" />}
              >
                <Spin spinning={carePlanGroupPractitionersStore.isLoading()}>
                  <Select
                    name="practitioners"
                    mode="multiple"
                    showArrow
                    showSearch
                    suffixIcon={<SearchOutlined />}
                    optionFilterProp="label"
                    disabled={carePlanGroupPractitionersStore.isLoading()}
                  >
                    {carePlanGroupPractitionersStore.availablePractitioners.map(
                      ({ id, givenName, middleAndSurname }) => (
                        <Select.Option
                          key={id}
                          label={`${givenName} ${middleAndSurname}`}
                          value={id}
                        >
                          <div>
                            <div
                              className={styles.practitionerName}
                            >{`${givenName} ${middleAndSurname}`}</div>
                          </div>
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Spin>
              </Form.Item>
              <Divider />
              <FormActionButtons
                isSaving={
                  carePlanGroupPractitionersStore.isLoading() ||
                  carePlanGroupPractitionersStore.isSaving()
                }
                isValid={isValid && dirty}
                onCancel={onCancel}
                showCancelConfirm={dirty}
              />
            </Form>
          )}
        </Observer>
      )}
    </Formik>
  );
};

export default AddPractitionerForm;
