import { Table, Input } from 'antd';
import { observer } from 'mobx-react';
import React, { Fragment, Component, ContextType } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import RootStoreContext from 'context/RootStoreContext';
import StatusTag from 'modules/Content24/components/StatusTag';

import styles from './SelfcareList.module.css';
import { SelfCareInfo, SELF_CARE_STATUS } from '../api/selfcareApi';
import AddPartnerSelfcareAdvice from '../components/AddPartnerSelfcareAdvice';

/**
 * @notExported
 */
interface SelfcareListProps extends WrappedComponentProps, RouteComponentProps {}

@observer
class SelfcareList extends Component<SelfcareListProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  breadcrumbs = [
    {
      iconName: 'pic-left',
      text: <FormattedMessage id="main-navigation.content24" />,
    },
    {
      text: <FormattedMessage id="selfcare.list.header" />,
    },
  ];

  columns = [
    {
      title: <FormattedMessage id="general.name" />,
      dataIndex: 'id',
      render: (_: string, { id }: SelfCareInfo) => (
        <Link to={`/content24/selfcare/${id}`}>{id}</Link>
      ),
    },
    {
      title: <FormattedMessage id="general.status" />,
      dataIndex: 'status',
      width: 120,
      render: (_: string, record: SelfCareInfo) => {
        if (
          record.status === SELF_CARE_STATUS.SYSTEM_DEFAULT_AND_LOCAL ||
          record.status === SELF_CARE_STATUS.REPLACED_SYSTEM_DEFAULT
        ) {
          return <StatusTag status="modified" />;
        }

        if (record.status === SELF_CARE_STATUS.LOCAL) {
          return <StatusTag status="localCopy" />;
        }

        return null;
      },
    },
  ];

  componentDidMount() {
    this.context.selfcareStore.fetchAll();
  }

  handleSelfcareItemClick = (record: SelfCareInfo) => {
    this.props.history.push(`/content24/selfcare/${record.id}`);
  };

  render() {
    const { intl } = this.props;
    const { selfcareStore, content24Store } = this.context;

    return (
      <Fragment>
        <PageHeader
          content="selfcare.list.header"
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <div className={styles.headerActions}>
              <Input
                placeholder={intl.formatMessage({
                  id: 'selfcare.search-placeholder',
                })}
                data-testid="search-input"
                onChange={event => selfcareStore.handleSearchChange(event.target.value)}
                disabled={selfcareStore.isLoading}
                className={styles.search}
              />
            </div>
          }
        />
        <Table<SelfCareInfo>
          columns={this.columns}
          loading={selfcareStore.isLoading}
          dataSource={selfcareStore.filteredList.slice()}
          className={styles.table}
          pagination={false}
          rowKey="id"
        />
        {content24Store.canEditSelfcareAdvice && (
          <Fragment>
            <AddPartnerSelfcareAdvice
              isVisible={selfcareStore.isNewSelfCareAdviceModalVisible}
              onSubmit={selfcareStore.handleSelfcareAdviceCreate}
              onCancel={selfcareStore.handleCancelAddSelfcareAdvice}
              isSaving={selfcareStore.isSaving}
              usedSelfcareIds={selfcareStore.lowerCasedSelfcareAdvicesIds}
            />
            <PlusFloatingButton onClick={selfcareStore.handleAddSelfcareAdvice} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default injectIntl(SelfcareList);
