import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Row, Col, Popconfirm, Button } from 'antd';
import { FieldArray } from 'formik';
import { Select, Input, Form, Radio } from 'formik-antd';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Outcome, RulesAttribute, RULES_ATTRIBUTE_TYPE } from 'modules/Rules/types';
import {
  filterOutUsedValues,
  getBaseValueType,
  getBooleanControlValues,
  getInputMetaProps,
} from 'modules/Rules/utils/utils';
import { InputOption } from 'types/types';

import styles from '../RuleForm.module.css';

type MapValueInputProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allowedValues: InputOption<any, string>[];
  isDisabled: boolean;
  outcomeMeta: RulesAttribute | null;
  outcome: Outcome;
};

export const MapValueInput = ({
  name,
  allowedValues,
  isDisabled,
  outcomeMeta,
  outcome,
}: MapValueInputProps) => {
  const { formatMessage } = useIntl();
  const hasAllowedValues = useMemo(() => allowedValues.length > 0, [allowedValues]);
  const [mapKeysAllowedValuesData, setMapKeysAllowedValuesData] = useState(allowedValues);

  useEffect(() => {
    if (hasAllowedValues) {
      const updatedAllowedValues = filterOutUsedValues(outcome.value, allowedValues);
      setMapKeysAllowedValuesData(updatedAllowedValues);
    }
  }, [outcome.value, outcome.value?.length, allowedValues, hasAllowedValues]);

  const baseValueType = getBaseValueType(outcomeMeta);
  const isDeleteButtonDisabled = isDisabled || outcome.value?.length <= 1;

  const booleanValues = getBooleanControlValues(formatMessage);

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        return (
          <>
            <div className="ant-col ant-form-item-label ant-form-item-label-left">
              <label
                className="ant-form-item-required"
                title={formatMessage({ id: 'rules.value' })}
              >
                {formatMessage({ id: 'rules.value' })}
              </label>
            </div>

            {Array.isArray(outcome.value) &&
              outcome.value.map((_, vIdx) => {
                const keyControlName = `${name}[${vIdx}].key`;
                const valueControlName = `${name}[${vIdx}].value`;

                return (
                  <Row key={`${keyControlName}`} gutter={[16, 0]}>
                    <Col span={12}>
                      {hasAllowedValues ? (
                        <Form.Item name={keyControlName} required className={styles.formItem}>
                          <Select
                            name={keyControlName}
                            disabled={isDisabled}
                            options={mapKeysAllowedValuesData}
                            placeholder={formatMessage({ id: 'rules.placeholders.map.key.pick' })}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item name={keyControlName} required className={styles.formItem}>
                          <Input
                            name={keyControlName}
                            disabled={isDisabled}
                            placeholder={formatMessage({ id: 'rules.placeholders.map.key.add' })}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col span={12}>
                      <Row gutter={8}>
                        <Col span={isDeleteButtonDisabled ? 24 : 22}>
                          {baseValueType === RULES_ATTRIBUTE_TYPE.STRING ? (
                            <Form.Item name={valueControlName} required className={styles.formItem}>
                              <Input.TextArea
                                autoSize
                                placeholder={formatMessage({
                                  id: 'rules.placeholders.map.value.add',
                                })}
                                disabled={isDisabled}
                                name={valueControlName}
                              />
                            </Form.Item>
                          ) : baseValueType === RULES_ATTRIBUTE_TYPE.BOOL ? (
                            <Form.Item name={valueControlName} required className={styles.formItem}>
                              <Radio.Group
                                name={valueControlName}
                                options={booleanValues}
                                disabled={isDisabled}
                                buttonStyle="outline"
                              />
                            </Form.Item>
                          ) : (
                            <Form.Item name={valueControlName} required className={styles.formItem}>
                              <Input
                                {...getInputMetaProps(outcomeMeta)}
                                placeholder=""
                                disabled={isDisabled}
                                name={valueControlName}
                              />
                            </Form.Item>
                          )}
                        </Col>
                        <Col span={isDeleteButtonDisabled ? 0 : 2}>
                          {!isDeleteButtonDisabled && (
                            <Popconfirm
                              title={<FormattedMessage id="general.sure-to-delete" />}
                              cancelText={<FormattedMessage id="general.cancel" />}
                              onConfirm={() => arrayHelpers.remove(vIdx)}
                              disabled={isDeleteButtonDisabled}
                            >
                              <Button
                                type="link"
                                disabled={isDeleteButtonDisabled}
                                className={styles.iconButton}
                              >
                                <MinusCircleOutlined />
                              </Button>
                            </Popconfirm>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            {hasAllowedValues && mapKeysAllowedValuesData.length === 0 ? null : (
              <Button
                type="link"
                disabled={isDisabled}
                className={styles.iconButton}
                onClick={() => {
                  arrayHelpers.push({
                    key: hasAllowedValues ? mapKeysAllowedValuesData[0].value : '',
                    value: '',
                  });
                }}
              >
                <PlusCircleOutlined />
                &nbsp;
                {`${formatMessage({ id: 'general.add' })} ${formatMessage({
                  id: 'rules.value',
                }).toLowerCase()}`}
              </Button>
            )}
          </>
        );
      }}
    />
  );
};
