import { Modal, Divider } from 'antd';
import { Formik, FormikProps } from 'formik';
import { Input, Form, Select, InputNumber, Switch } from 'formik-antd';
import React, { FunctionComponent, useCallback, Fragment, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { Customization, CUSTOMIZATION_TYPES, CustomizationRoleValue } from 'api/customizationsApi';
import TextlistForm from 'components/CustomizationsCommon/TextlistForm';
import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import { createSelectOptions } from 'utils/selectUtils';

import styles from './EditCustomization.module.css';
import RolesCustomizationsList from '../RolesCustomizationsList';

interface Props {
  data: Customization | null;
  onCancel: () => void;
  onSubmit: (data: Customization) => void;
  isVisible: boolean;
  isSaving: boolean;
  isDisabled?: boolean;
  allowCustomizationsOnRoles?: boolean;
}

const EditCustomization: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  data,
  onSubmit,
  isVisible,
  allowCustomizationsOnRoles,
  isDisabled,
}) => {
  const isFormDisabled = isSaving || isDisabled;
  const { customizationsStore } = useContext(RootStoreContext);

  const handleSubmit = useCallback(
    values => {
      if (data) {
        onSubmit({
          ...data,
          ...values,
        });
      }
    },
    [onSubmit, data]
  );

  const renderField = useCallback(
    (
      field: Customization,
      form: FormikProps<{
        roles: CustomizationRoleValue[] | undefined;
        value: any;
      }>
    ) => {
      switch (field.type) {
        case CUSTOMIZATION_TYPES.INTEGER:
          return <InputNumber name="value" min={0} disabled={isFormDisabled} data-testid="value" />;
        case CUSTOMIZATION_TYPES.BOOLEAN:
          return (
            <Switch
              name="value"
              defaultChecked={field.value}
              disabled={isFormDisabled}
              data-testid="value"
            />
          );
        case CUSTOMIZATION_TYPES.TEXT:
          return field.allowedValues ? (
            <Select
              name="value"
              disabled={isFormDisabled}
              data-testid="value"
              className={styles.dropdown}
              options={createSelectOptions(field.allowedValues)}
            />
          ) : (
            <Input name="value" disabled={isFormDisabled} data-testid="value" />
          );
        case CUSTOMIZATION_TYPES.TEXTLIST:
          return field.allowedValues ? (
            <Select
              name="value"
              disabled={isFormDisabled}
              mode="multiple"
              data-testid="value"
              className={styles.dropdown}
              options={createSelectOptions(field.allowedValues)}
            />
          ) : (
            <TextlistForm
              value={field.value}
              onChange={value => form.setFieldValue('value', value)}
              isSaving={isSaving}
              isDisabled={isFormDisabled}
              data-testid="value"
            />
          );
        default:
          return <Input name="value" disabled={isFormDisabled} data-testid="value" />;
      }
    },
    [isFormDisabled, isSaving]
  );

  if (!data) {
    return null;
  }

  const initialValues = {
    roles: data.roles,
    value:
      data.type === CUSTOMIZATION_TYPES.TEXTLIST && data.value ? data.value.slice() : data.value,
  };

  return (
    <Modal visible={isVisible} destroyOnClose title={data.header} closable={false} footer={null}>
      {data.description && (
        <Fragment>
          <h5 className={styles.sectionHeader}>
            <FormattedMessage id="general.description" />
          </h5>
          <p>{data.description}</p>
        </Fragment>
      )}
      {data.description && <Divider />}
      <div data-testid="edit-form">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {form => (
            <Form layout="inline">
              <Form.Item
                label={<FormattedMessage id="general.value" />}
                name="value"
                className={styles.formField}
              >
                {renderField(data, form)}
              </Form.Item>
              {allowCustomizationsOnRoles && (
                <Fragment>
                  <Divider />
                  <h5 className={styles.sectionHeader}>
                    <FormattedMessage id="customizations.roles-specific-customizations" />
                  </h5>
                  <Form.Item name="roles">
                    <RolesCustomizationsList
                      customization={data}
                      onChange={value => form.setFieldValue('roles', value)}
                      isSaving={isSaving}
                      isDisabled={isFormDisabled}
                      data-testid="value"
                    />
                  </Form.Item>
                </Fragment>
              )}
              <Divider />
              <FormActionButtons
                isSaving={customizationsStore.isSaving()}
                showCancelConfirm={form.dirty}
                isDisabled={isDisabled}
                isValid={form.isValid && form.dirty}
                onCancel={onCancel}
              />
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditCustomization;
