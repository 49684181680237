import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const Weights = ({ color = COLORS.PINK }: Props) => (
  <svg x="0px" y="0px" viewBox="0 0 100 125" height={50} fill={color}>
    <g>
      <path d="M97.429,47.504h-7.489V35.023c0-6.021-6.211-8.653-9.984-7.061c0.229-4.394-3.354-7.917-7.488-7.917   c-4.135,0-7.489,3.354-7.489,7.488v19.97H35.023v-19.97c0-4.134-3.354-7.488-7.489-7.488s-7.722,3.47-7.489,7.917   c-5.943-1.793-9.985,2.926-9.985,7.061v12.481H2.572c-1.379,0-2.496,1.117-2.496,2.496c0,1.38,1.117,2.496,2.496,2.496h7.489   v12.481c0,4.135,4.003,8.973,9.985,7.061c-0.231,4.458,3.354,7.917,7.489,7.917s7.489-3.354,7.489-7.488V52.496h29.955v19.971   c0,4.134,3.354,7.488,7.489,7.488c4.134,0,7.702-3.459,7.488-7.917c6.115,1.899,9.984-2.926,9.984-7.061V52.496h7.489   c1.38,0,2.496-1.116,2.496-2.496C99.925,48.621,98.809,47.504,97.429,47.504z M20.045,64.978c0,1.375-1.121,2.496-2.496,2.496   s-2.496-1.121-2.496-2.496V35.023c0-1.375,1.121-2.496,2.496-2.496s2.496,1.121,2.496,2.496V64.978z M30.03,72.467   c0,1.375-1.121,2.496-2.496,2.496s-2.496-1.121-2.496-2.496v-7.489V35.023v-7.489c0-1.375,1.121-2.496,2.496-2.496   s2.496,1.121,2.496,2.496V72.467z M74.963,35.023v29.955v7.489c0,1.375-1.122,2.496-2.496,2.496c-1.375,0-2.497-1.121-2.497-2.496   V27.534c0-1.375,1.122-2.496,2.497-2.496c1.374,0,2.496,1.121,2.496,2.496V35.023z M84.947,64.978c0,1.375-1.121,2.496-2.496,2.496   s-2.496-1.121-2.496-2.496V35.023c0-1.375,1.121-2.496,2.496-2.496s2.496,1.121,2.496,2.496V64.978z" />
    </g>
  </svg>
);
