import React from 'react';
import ReactDOM from 'react-dom';

// these are the styles that were imported under the hood by craco-antd previously
// import 'antd/es/style/themes/default.less';
import 'antd/dist/antd.less';

import 'config/baseStyles.module.css';
import 'config/antdStylesOverride.less';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
