import { observable, action, runInAction } from 'mobx';

import { fetchCareUnit, updateCareUnit, createCareUnit, CareUnit } from 'api/careUnitsApi';

import StateManager from './abstractStores/StateManager';
import RootStore from './RootStore';

export default class CareUnitStore extends StateManager {
  @observable
  careUnit?: CareUnit;

  constructor(private rootStore: RootStore) {
    super();
  }

  fetchCareUnit = async (careProviderId: string, id: string) => {
    const { partnersStore } = this.rootStore;
    this.setLoading();

    try {
      const { data } = await fetchCareUnit(partnersStore.partnerId, careProviderId, id);

      runInAction(() => {
        this.careUnit = data;
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  updateCareUnit = async (careProviderId: string, careUnit: CareUnit) => {
    const { userDataStore: userStore, partnersStore } = this.rootStore;

    this.setSaving();

    try {
      const { data: updatedCareUnit } = await updateCareUnit(
        partnersStore.partnerId,
        careProviderId,
        careUnit
      );

      await userStore.fetchUserCareUnits({ force: true });

      runInAction(() => {
        this.careUnit = updatedCareUnit;
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  createCareUnit = async (careProviderId: string, careUnit: CareUnit) => {
    const { partnersStore } = this.rootStore;

    this.setSaving();

    try {
      const { data: newCareUnit } = await createCareUnit(
        partnersStore.partnerId,
        careProviderId,
        careUnit
      );

      runInAction(() => {
        this.careUnit = newCareUnit;
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  @action
  dispose() {
    this.careUnit = undefined;
  }

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };
}
