import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { ContextType, Component } from 'react';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';

import SideMenu from 'components/SideMenu';
import { SideMenuLink } from 'components/SideMenu/SideMenu';
import { ORIGIN_RULES_CATEGORIES } from 'constants/origins';
import { ORIGIN_IDS } from 'constants/testIds';
import RootStoreContext from 'context/RootStoreContext';

interface Props extends WrappedComponentProps {
  basePath: string;
}

@observer
class OriginsSideMenu extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @computed
  get mainLinks(): SideMenuLink[] {
    const { intl, basePath } = this.props;

    return [
      'basic-details',
      'menu-items',
      'alerts',
      'offline-flow',
      'customizations',
      'text-content',
      // TODO: enable when backend for https://platform24.atlassian.net/browse/AX-7711 is ready
      // 'consents',
      'toplist',
      'shutdown-menu',
    ].map(itemName => ({
      url: `${basePath}/${itemName}`,
      id: `${basePath}/${itemName}`,
      content: intl.formatMessage({ id: `origins.${itemName}` }),
      testid: itemName,
    }));
  }

  get originRulesLinks(): SideMenuLink[] {
    const { rules } = this.context.originStore.rulesStore;
    const { intl, basePath } = this.props;

    if (rules.size) {
      return [
        {
          id: `${basePath}/rules`,
          content: <FormattedMessage id="origin.rules" />,
          submenus: [
            {
              key: ORIGIN_RULES_CATEGORIES.OPENING_HOURS,
              nameId: 'origin.rules.opening-hours',
            },
            {
              key: ORIGIN_RULES_CATEGORIES.CARE_UNIT,
              nameId: 'origin.rules.care-unit',
            },
            {
              key: ORIGIN_RULES_CATEGORIES.PRICE,
              nameId: 'origin.rules.prices',
            },
            {
              key: ORIGIN_RULES_CATEGORIES.RESOURCE,
              nameId: 'origin.rules.resource',
            },
            {
              key: ORIGIN_RULES_CATEGORIES.OTHER,
              nameId: 'origin.rules.other',
            },
          ]
            .filter(({ key }) => rules.has(key))
            .map(({ nameId, key }) => ({
              url: `${basePath}/rules/${key}`,
              id: `${basePath}/rules/${key}`,
              content: intl.formatMessage({ id: nameId }),
            })),
        },
      ];
    }

    return [];
  }

  render() {
    return (
      <SideMenu
        links={this.mainLinks.concat(this.originRulesLinks)}
        testid={ORIGIN_IDS.SETTINGS_MENU}
      />
    );
  }
}

export default injectIntl(OriginsSideMenu);
