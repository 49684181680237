import React from 'react';
import { FormattedMessage } from 'react-intl';

import CE from 'components/IconsSvg/CE';
import Info from 'components/IconsSvg/Info';
import Manufacturer from 'components/IconsSvg/Manufacturer';
import MD from 'components/IconsSvg/MD';

import styles from './Copyright.module.css';

const Copyright = () => (
  <div className={styles.container}>
    <div className={styles.section}>
      <div className={styles.logo}>
        <CE />
      </div>
      <div className={styles.notice}>
        <FormattedMessage id="home-copyright-ce-notice" />
      </div>
    </div>
    <div className={styles.section}>
      <div className={styles.logo}>
        <MD />
      </div>
      <div className={styles.notice}>
        <FormattedMessage id="home-copyright-md-notice" />
      </div>
    </div>
    <div className={styles.section}>
      <div className={styles.logo}>
        <Info />
      </div>
      <div className={styles.notice} data-testid="consult-instruction">
        <FormattedMessage id="about.info-notice" />
      </div>
    </div>
    <div className={styles.section}>
      <div className={styles.logo}>
        <Manufacturer />
      </div>
      <div className={styles.notice}>
        <p className={styles.manufacturerName}>
          <FormattedMessage id="home-copyright-manufacturer-name" />
        </p>
        <p>
          <FormattedMessage id="home-copyright-manufacturer-address-1" />
        </p>
        <p>
          <FormattedMessage id="home-copyright-manufacturer-address-2" />
        </p>
      </div>
    </div>
  </div>
);

export default Copyright;
