import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import { injectIntl, FormattedMessage, WrappedComponentProps } from 'react-intl';

import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import { CarePlanGroupPractitionersListItem } from 'modules/You24/CarePlanGroups/api/carePlanGroupsApi';
import { sortWithLocale } from 'utils/textUtils';

interface PractitionersListProps extends WrappedComponentProps {
  groupId: string;
}

const PractitionersList = ({ groupId, intl }: PractitionersListProps) => {
  const { carePlanGroupsStore, carePlanGroupPractitionersStore, flashMessageService } =
    useContext(RootStoreContext);

  useEffect(() => {
    carePlanGroupPractitionersStore.fetchCarePlanGroupPractitioners(groupId);
  }, [groupId, carePlanGroupPractitionersStore]);

  const columns = useMemo(() => {
    const handleRemovePractitioner = async (practitionerId: string) => {
      await carePlanGroupPractitionersStore.removePractitioner(groupId, practitionerId);

      flashMessageService.success(
        intl.formatMessage({ id: 'care-plan-group-practitioners.practitioner-removed-message' })
      );
    };

    return [
      {
        title: <FormattedMessage id="basic-details-form.name-label" />,
        dataIndex: 'name',
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: CarePlanGroupPractitionersListItem, b: CarePlanGroupPractitionersListItem) => {
          return sortWithLocale(a, b, 'name');
        },
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        width: 500,
      },
      {
        title: <FormattedMessage id="general.externally-known-id" />,
        dataIndex: 'externalId',
        render: (_: string, record: CarePlanGroupPractitionersListItem) =>
          record.externalIds.map(({ id }) => id).join(', '),
      },
      {
        title: <FormattedMessage id="general.remove" />,
        dataIndex: EDIT_ACTIONS_FIELD,
        width: 100,
        render: (_: string, record: CarePlanGroupPractitionersListItem) => (
          <Popconfirm
            title={<FormattedMessage id="general.sure-to-delete" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onConfirm={() => handleRemovePractitioner(record.id)}
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ];
  }, [groupId, carePlanGroupPractitionersStore, flashMessageService, intl]);

  return (
    <Observer>
      {() => (
        <Table<CarePlanGroupPractitionersListItem>
          columns={columns}
          dataSource={carePlanGroupPractitionersStore.practitioners.slice()}
          pagination={false}
          rowKey={FIELDS.ID}
          data-testid="care-plan-group-practitioners-list"
          loading={carePlanGroupsStore.isLoading() || carePlanGroupPractitionersStore.isLoading()}
        />
      )}
    </Observer>
  );
};

export default injectIntl(PractitionersList);
