import { Row, Col } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BASIC_DETAILS_FORM_FIELDS,
  TEMPORARY_PAYMENT_OPTIONS,
  COUNTRY_CODE_OPTIONS,
} from 'constants/origins';

import styles from '../OriginsDetailsForm.module.css';

interface Props {
  isSaving: boolean;
  isDisabled: boolean;
  isNew: boolean;
  parentOriginId: string;
}

const BasicDetails = ({ isSaving, isDisabled, isNew, parentOriginId }: Props) => (
  <div className="ant-form-vertical">
    <Row gutter={16}>
      <Col xs={24} lg={12}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.ID}
          label={<FormattedMessage id="origin.basic-details-form.origin-id-label" />}
          required
        >
          {isNew ? (
            <div className={styles.addOriginIdField}>
              <span className={styles.parentId}>{`${parentOriginId}-`}</span>
              <Input
                name={BASIC_DETAILS_FORM_FIELDS.ID}
                data-testid="id"
                disabled={isSaving || isDisabled}
              />
            </div>
          ) : (
            <Input name={BASIC_DETAILS_FORM_FIELDS.ID} data-testid="id" disabled />
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.NAME}
          label={<FormattedMessage id="origin.basic-details-form.name-label" />}
        >
          <Input name={BASIC_DETAILS_FORM_FIELDS.NAME} disabled={isSaving || isDisabled} />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.CLINIC_NAME}
          label={<FormattedMessage id="origin.basic-details-form.clinic-name-label" />}
        >
          <Input name={BASIC_DETAILS_FORM_FIELDS.CLINIC_NAME} disabled={isSaving || isDisabled} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.BANK_ID_DISPLAY_NAME}
          label={<FormattedMessage id="origin.basic-details-form.bankid-label" />}
        >
          <Input
            name={BASIC_DETAILS_FORM_FIELDS.BANK_ID_DISPLAY_NAME}
            disabled={isSaving || isDisabled}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.SMS_DISPLAY_NAME}
          label={<FormattedMessage id="origin.basic-details-form.sms-sender-label" />}
        >
          <Input
            name={BASIC_DETAILS_FORM_FIELDS.SMS_DISPLAY_NAME}
            disabled={isSaving || isDisabled}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.PAYMENT_METHOD}
          label={<FormattedMessage id="origin.basic-details-form.payment-method-label" />}
        >
          <Select
            name={BASIC_DETAILS_FORM_FIELDS.PAYMENT_METHOD}
            disabled={isSaving || isDisabled}
            options={TEMPORARY_PAYMENT_OPTIONS}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.EMAIL}
          label={<FormattedMessage id="origin.basic-details-form.email-label" />}
        >
          <Input name={BASIC_DETAILS_FORM_FIELDS.EMAIL} disabled={isSaving || isDisabled} />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} md={12} xl={6}>
        <Form.Item
          name={BASIC_DETAILS_FORM_FIELDS.COUNTRY_CODE}
          label={<FormattedMessage id="origin.basic-details-form.country-code-label" />}
        >
          <Select
            name={BASIC_DETAILS_FORM_FIELDS.COUNTRY_CODE}
            disabled={isSaving || isDisabled}
            options={COUNTRY_CODE_OPTIONS}
          />
        </Form.Item>
      </Col>
    </Row>
  </div>
);

export default BasicDetails;
