import { DeleteOutlined } from '@ant-design/icons';
import { Divider, Row, Col, Button, Form as AntDForm } from 'antd';
import { Formik, FieldArray } from 'formik';
import { Input, Form } from 'formik-antd';
import React, { FunctionComponent, useContext, useCallback, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import { CODE24_MODEL_TYPES } from 'modules/Content24/Condition/constants/code24types';
import { Guidance } from 'modules/Content24/Condition/models/Code24Model';
import {
  getDataFromFinalFormValues,
  getInitialFormValues,
} from 'modules/Content24/Condition/utils/forms';
import {
  validateStatementConditionWithDebounce,
  ExpressionError,
} from 'modules/Content24/Condition/utils/validationUtils';

import styles from './GuidanceForm.module.css';

/**
 * @notExported
 */
interface GuidanceFormProps {
  onCancel: () => void;
  onSubmit: (data: Guidance) => void;
  activeLanguage: string;
  data?: Guidance;
  isDisabled?: boolean;
}

const STAFF_MAX_LENGTH = 150;
const SYMPTOM_MAX_LENGTH = 20;

const GuidanceForm: FunctionComponent<GuidanceFormProps> = ({
  data = {
    id: '',
    type: CODE24_MODEL_TYPES.GUIDANCE,
    condition: '',
  },
  onCancel,
  onSubmit,
  isDisabled,
  activeLanguage,
}) => {
  const intl = useIntl();
  const { conditionStore } = useContext(RootStoreContext);

  const defaultValues: Guidance = {
    id: '',
    type: CODE24_MODEL_TYPES.GUIDANCE,
    condition: '',
    staff: { [activeLanguage]: '' },
    code: '',
    symptoms: [],
  };
  const initialValues = getInitialFormValues<Guidance, Guidance>(defaultValues, data);

  const validationSchema = Yup.object().shape({
    condition: Yup.string()
      .required(
        intl.formatMessage({
          id: 'general.errors.required',
        })
      )
      .test(
        'isValidCondition',
        ({ translationKey, characters }: Partial<ExpressionError & Yup.TestMessageParams>) =>
          translationKey &&
          characters &&
          intl.formatMessage({ id: translationKey }, { characters }),
        validateStatementConditionWithDebounce
      ),
  });

  const isFormDisabled = conditionStore.isLoading() || isDisabled;

  const handleSubmit = useCallback(
    (dataToSubmit: Guidance) => {
      onSubmit(getDataFromFinalFormValues<Guidance>(dataToSubmit));
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, values, dirty }) => (
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="condition"
                required
                label={<FormattedMessage id="condition-edit.if-label" />}
              >
                <Input.TextArea name="condition" disabled={isFormDisabled} rows={1} autoSize />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={`staff.${activeLanguage}`}
                label={<FormattedMessage id="condition-edit.staff-label" />}
              >
                <Input.TextArea
                  name={`staff.${activeLanguage}`}
                  disabled={isFormDisabled}
                  maxLength={STAFF_MAX_LENGTH}
                  rows={1}
                  autoSize
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="code" label={<FormattedMessage id="condition-edit.code-label" />}>
                <Input name="code" disabled={isFormDisabled} />
              </Form.Item>
            </Col>
          </Row>
          <AntDForm.Item label={<FormattedMessage id="condition-edit.symptoms-label" />}>
            <FieldArray
              name="symptoms"
              render={arrayHelpers => (
                <Fragment>
                  <Row gutter={16}>
                    {(values.symptoms || []).map((_, index) => (
                      <Col span={8} key={index}>
                        <Form.Item name={`symptoms.${index}.${activeLanguage}`}>
                          <Input
                            name={`symptoms.${index}.${activeLanguage}`}
                            disabled={isFormDisabled}
                            maxLength={SYMPTOM_MAX_LENGTH}
                            suffix={
                              <Button
                                type="link"
                                size="small"
                                icon={<DeleteOutlined />}
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={isFormDisabled}
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                    ))}
                  </Row>
                  <Button
                    type="link"
                    onClick={() => {
                      arrayHelpers.push({ [activeLanguage]: '' });
                    }}
                    className={styles.addButton}
                    disabled={isFormDisabled}
                  >
                    +{' '}
                    <FormattedMessage
                      id={
                        values.symptoms?.length ? 'general.add-more' : 'condition-edit.add-symptom'
                      }
                    />
                  </Button>
                </Fragment>
              )}
            />
          </AntDForm.Item>
          <Divider />
          <FormActionButtons
            isSaving={conditionStore.isLoading()}
            isDisabled={isDisabled}
            isValid={isValid && dirty}
            onCancel={onCancel}
            showCancelConfirm={dirty}
            cancelDeclineText={<FormattedMessage id="condition-edit.statement-cancel-confirm" />}
          />
        </Form>
      )}
    </Formik>
  );
};

export default GuidanceForm;
