import axios from 'axios';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getLanguage } from 'context/lang.utils';
import RootStore from 'stores/RootStore';

import { defaultInterceptorsBuilder } from '../../config/appConfig';

export const usePathwaysProps = <TAdditionalProps extends Record<string, unknown>>(
  rootStore: RootStore,
  additionalProps: TAdditionalProps = {} as TAdditionalProps
) => {
  // nb: we must create a new instance so that the child module won't override the root one,
  // but also we have to register interceptors again on the new instance because there are no global interceptors in axios
  const axiosInstance = axios.create();
  defaultInterceptorsBuilder.registerInterceptors(axiosInstance);

  const history = useHistory();

  return useMemo(
    () => ({
      userId: rootStore.userDataStore.id,
      partnerId: rootStore.partnersStore.partnerId,
      languageCode: getLanguage(),
      axiosInstance: axiosInstance,
      history: history,
      ...(additionalProps as { [key in keyof TAdditionalProps]: TAdditionalProps[key] }),
    }),
    [
      additionalProps,
      axiosInstance,
      history,
      rootStore.partnersStore.partnerId,
      rootStore.userDataStore.id,
    ]
  );
};
