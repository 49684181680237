import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const HeartIcon = ({ color = COLORS.PINK }: Props) => (
  <svg width="25px" height="22px" viewBox="0 0 25 22">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(-24.000000, -175.000000)" fill={color} stroke="#4A4A4A">
        <g transform="translate(24.000000, 176.000000)">
          <g>
            <path d="M22.5454545,1.84649351 C21.373268,0.667055184 19.7831114,0.00440708227 18.125,0.00440708227 C16.4668886,0.00440708227 14.876732,0.667055184 13.7045455,1.84649351 L12.5,3.05792208 L11.2954545,1.84649351 C8.8541049,-0.608806633 4.89589513,-0.608806603 2.45454552,1.84649357 C0.0131959134,4.30179375 0.0131958839,8.28262187 2.45454545,10.7379221 L3.65909091,11.9493506 L12.5,20.8407792 L21.3409091,11.9493506 L22.5454545,10.7379221 C23.7181915,9.55903728 24.3770746,7.95979411 24.3770746,6.29220779 C24.3770746,4.62462147 23.7181915,3.02537831 22.5454545,1.84649351 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
