import { Modal, Divider } from 'antd';
import { Formik } from 'formik';
import { Form, Checkbox, Select } from 'formik-antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import { RoleInCareUnit } from 'modules/Practitioner/stores/PractitionerRolesStore';
import { InputOption } from 'types/types';

interface Props {
  role?: RoleInCareUnit;
  isSaving: boolean;
  showRoleLock: boolean;
  onCancel: () => void;
  onSubmit: (data: RoleInCareUnit) => void;
  careUnitsOptions: InputOption[];
  rolesOptions: InputOption[];
  notAvailableCareUnitRoles: RoleInCareUnit[];
}

const EditPractitionerRole: FunctionComponent<Props> = ({
  isSaving,
  onCancel,
  role,
  onSubmit,
  careUnitsOptions,
  notAvailableCareUnitRoles,
  rolesOptions,
  showRoleLock,
}) => {
  const intl = useIntl();
  const requiredErrorMessage = intl.formatMessage({
    id: 'general.errors.required',
  });
  const validationSchema = Yup.object().shape({
    careUnitId: Yup.string().required(requiredErrorMessage),
    role: Yup.string().required(requiredErrorMessage),
  });

  if (!role) {
    return null;
  }

  return (
    <Modal
      visible
      destroyOnClose
      title={<FormattedMessage id="practitioner-roles-form.edit-role-label" />}
      footer={null}
      closable={false}
    >
      <Formik initialValues={role} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ isValid, dirty, values }) => {
          const filteredRolesOptions = notAvailableCareUnitRoles.length
            ? rolesOptions.filter(
                ({ value }) =>
                  !notAvailableCareUnitRoles.some(
                    ({ role, careUnitId, roleId }) =>
                      values.roleId !== roleId && role === value && values.careUnitId === careUnitId
                  )
              )
            : rolesOptions;
          const filteredCareUnitsOptions = notAvailableCareUnitRoles.length
            ? careUnitsOptions.filter(
                ({ value }) =>
                  !notAvailableCareUnitRoles.some(
                    ({ role, careUnitId, roleId }) =>
                      values.roleId !== roleId && careUnitId === value && values.role === role
                  )
              )
            : careUnitsOptions;

          return (
            <Form layout="vertical">
              <Form.Item
                name="careUnitId"
                label={<FormattedMessage id="general.care-unit" />}
                required
              >
                <Select
                  name="careUnitId"
                  disabled={isSaving}
                  optionFilterProp="label"
                  showSearch
                  options={filteredCareUnitsOptions}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label={<FormattedMessage id="practitioner-roles-form.role-label" />}
                required
              >
                <Select name="role" disabled={isSaving} options={filteredRolesOptions} />
              </Form.Item>
              <Form.Item
                name="isPrimaryRole"
                label={<FormattedMessage id="practitioner-roles-form.primary-role-label" />}
              >
                <Checkbox name="isPrimaryRole" disabled={!!role.isPrimaryRole} />
              </Form.Item>
              {showRoleLock && (
                <Form.Item
                  name="lockedFromAutoManagement"
                  label={<FormattedMessage id="practitioner-roles-form.exemption-label" />}
                >
                  <Checkbox
                    name="lockedFromAutoManagement"
                    data-testid="show-edit-role-rolelock"
                    checked={!!role.lockedFromAutoManagement}
                  />
                </Form.Item>
              )}
              <Divider />
              <FormActionButtons
                isSaving={isSaving}
                isValid={isValid && dirty}
                onCancel={onCancel}
                showCancelConfirm={dirty}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditPractitionerRole;
