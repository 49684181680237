import React from 'react';

import { COLORS } from '../../constants/colors';

interface Props {
  color?: string;
}

export const HomeCloudIcon = ({ color = COLORS.PINK }: Props) => (
  <svg width="29px" height="26px" viewBox="0 0 29 26">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-22.000000, -257.000000)">
        <g transform="translate(23.000000, 258.000000)">
          <path
            d="M7.52619869,17.55 L5.5,17.55 C5.4345,17.55 5.3715,17.5371932 5.3145,17.513477 C2.275,17.1871419 0,14.7789973 0,11.8581081 C0,9.08283649 2.1605,6.70172838 5.0005,6.24542838 C5,6.21886622 5,6.19277838 5,6.16621622 C5,2.76625946 7.916,0 11.5,0 C13.938,0 16.134,1.27356081 17.2515,3.28991351 C17.78,3.00105 18.383,2.84594595 19,2.84594595 C20.9295,2.84594595 22.5,4.33579865 22.5,6.16621622 C22.5,6.22550676 22.4985,6.2847973 22.495,6.34408784 C25.1325,6.98205405 27,9.2331973 27,11.8581081 C27,14.9967122 24.3085,17.55 21,17.55 L19.9461987,17.55"
            stroke="#4B4B4B"
            strokeWidth="1.5"
            fill="#FFFFFF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <polygon
            fill={color}
            points="12.3706686 17.5965197 10.4939109 25.0714286 18 15.9475441 14.6663846 15.947544 15.2216485 8.775 9 17.5965199"
          />
        </g>
      </g>
    </g>
  </svg>
);
