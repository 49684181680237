import { Modal, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import AddPractitionerForm, {
  AddPractitionerFormData,
} from 'modules/You24/CarePlanGroups/components/AddPractitionerForm';

interface AddPractitionerProps {
  groupId: string;
  isVisible: boolean;
  onCancel: () => void;
  onSubmit: (data: AddPractitionerFormData) => void;
}

const AddPractitioner: FunctionComponent<AddPractitionerProps> = ({
  groupId,
  isVisible,
  onCancel,
  onSubmit,
}) => (
  <Modal
    visible={isVisible}
    destroyOnClose
    title={
      <Typography.Title level={3}>
        <FormattedMessage id="care-plan-group-practitioners.add-practitioner" />
      </Typography.Title>
    }
    onCancel={onCancel}
    footer={null}
    closable={false}
    keyboard={false}
    maskClosable={false}
    width={700}
  >
    <AddPractitionerForm groupId={groupId} onCancel={onCancel} onSubmit={onSubmit} />
  </Modal>
);

export default AddPractitioner;
