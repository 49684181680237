import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Table, Popconfirm, Typography } from 'antd';
import noop from 'lodash/noop';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import DraggableRow from 'components/DraggableRow/DraggableRow';
import withIconsOptions, { IconsOptionsProps } from 'components/HOC/withIconsOptions';
import withPractitionerRolesOptions, {
  PractitionerRolesOptionsProps,
} from 'components/HOC/withPractitionerRolesOptions';
import PlusFloatingButton from 'components/PlusFloatingButton';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';
import { getOptionLabel } from 'utils/selectUtils';

import AddEditMenuItem from './components/AddEditMenuItem';
import MenuItemPropertyTag from './components/MenuItemPropertyTag';
import styles from './MenuItems.module.css';
import MenuItemsStore, {
  MenuItemTransformed,
  MenuItemTransformedProperty,
} from './stores/MenuItemsStore';

interface Props
  extends RouteComponentProps<{ parentOriginId: string; originId: string }>,
    WrappedComponentProps,
    PractitionerRolesOptionsProps,
    IconsOptionsProps {}

@observer
class MenuItems extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  menuItemsStore: MenuItemsStore;

  components = {
    body: {
      row: DraggableRow,
    },
  };

  constructor(props: Props, context: RootStore) {
    super(props);
    this.menuItemsStore = new MenuItemsStore(context, props.match.params.originId);
  }

  componentDidMount() {
    this.menuItemsStore.initialize();
  }

  get columns() {
    const {
      userPermissionsStore: { canEditCurrentPartner },
      originStore: { currentOrigin },
    } = this.context;
    const language = currentOrigin?.defaultLanguage || this.props.intl.locale;

    return [
      {
        title: <FormattedMessage id="origin.menu-items.menu-item" />,
        dataIndex: 'type',
      },
      {
        title: <FormattedMessage id="general.title" />,
        dataIndex: ['title', language],
      },
      {
        title: <FormattedMessage id="origin.menu-items.properties-label" />,
        dataIndex: 'properties',
        render: (properties: MenuItemTransformedProperty[]) => (
          <Fragment>
            {properties.map(property => (
              <MenuItemPropertyTag key={property.type} property={property} />
            ))}
          </Fragment>
        ),
      },
      {
        title: <FormattedMessage id="general.description" />,
        dataIndex: ['description', language],
      },
      {
        title: <FormattedMessage id="origin.menu-items.price-label" />,
        dataIndex: ['label', language],
      },
      {
        title: <FormattedMessage id="general.icon" />,
        dataIndex: 'icon',
        render: (icon: string) => (
          <div className={styles.iconField}>{getOptionLabel(this.props.iconsOptions, icon)}</div>
        ),
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: 'actions',
        width: 100,
        render: (_: void, record: MenuItemTransformed) => (
          <Fragment>
            <Button
              type="link"
              icon={canEditCurrentPartner ? <EditOutlined /> : <EyeOutlined />}
              onClick={() => this.menuItemsStore.handleEdit(record.id)}
              className={styles.actionBtn}
            />
            {canEditCurrentPartner && (
              <Popconfirm
                title={<FormattedMessage id="general.sure-to-delete" />}
                cancelText={<FormattedMessage id="general.cancel" />}
                onConfirm={() => this.menuItemsStore.handleDelete(record.id)}
              >
                <Button type="link" icon={<DeleteOutlined />} className={styles.actionBtn} />
              </Popconfirm>
            )}
          </Fragment>
        ),
      },
    ];
  }

  handleMoveRow = (dragIndex: number, hoverIndex: number) => {
    const menuItems = this.menuItemsStore.menuItems.slice();
    const dragRow = menuItems[dragIndex];

    menuItems.splice(dragIndex, 1);
    menuItems.splice(hoverIndex, 0, dragRow);

    this.menuItemsStore.handleUpdateOrder(menuItems);
  };

  render() {
    const {
      handleAdd,
      handleSubmit,
      menuItems,
      menuItemTypeOptions,
      menuItemTypes,
      isLoading,
      handleCancel,
      activeMenuItem,
    } = this.menuItemsStore;
    const { resourceTypesOptions } = this.props;
    const { userPermissionsStore } = this.context;

    return (
      <Fragment>
        <div className={styles.headerWrapper}>
          <Typography.Title level={2}>
            <FormattedMessage id="origin.menu-items.header" />
          </Typography.Title>
          <p className={styles.subheader}>
            <FormattedMessage id="origin.menu-items.subheader" />
          </p>
        </div>
        <DndProvider backend={HTML5Backend}>
          <Table<MenuItemTransformed>
            dataSource={toJS(menuItems)}
            pagination={false}
            loading={isLoading}
            components={userPermissionsStore.canEditCurrentPartner ? this.components : undefined}
            onRow={
              userPermissionsStore.canEditCurrentPartner
                ? (_, index) => ({
                    index,
                    moveRow: this.handleMoveRow,
                    // Required by onRow return type
                    onClick: noop,
                  })
                : undefined
            }
            data-testid="menu-items-list"
            className={styles.table}
            rowKey="id"
            columns={this.columns}
            locale={{ emptyText: <FormattedMessage id="origin.menu-items.default-config" /> }}
          />
        </DndProvider>
        <AddEditMenuItem
          initialValues={activeMenuItem}
          isSaving={isLoading}
          isDisabled={!userPermissionsStore.canEditCurrentPartner}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          menuItemTypeOptions={menuItemTypeOptions}
          resourceTypeOptions={resourceTypesOptions}
          menuItemTypes={menuItemTypes}
        />
        {userPermissionsStore.canEditCurrentPartner && <PlusFloatingButton onClick={handleAdd} />}
      </Fragment>
    );
  }
}

export default withIconsOptions(withPractitionerRolesOptions(injectIntl(MenuItems)));
