import { Divider } from 'antd';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import HsaIdLogin from '../HsaIdLogin';
import SwedishBankIdLogin from '../SwedishBankIdLogin';

const SwedishLoginMethods = () => {
  const [activeMethod, setActiveMethod] = useState<string | undefined>();

  return (
    <Fragment>
      {(!activeMethod || activeMethod === 'HsaIdLogin') && (
        <HsaIdLogin
          isActive={activeMethod === 'HsaIdLogin'}
          onClick={() => setActiveMethod('HsaIdLogin')}
        />
      )}
      {!activeMethod && (
        <Divider>
          <FormattedMessage id="general.or" />
        </Divider>
      )}
      {(!activeMethod || activeMethod === 'SwedishBankIdLogin') && (
        <SwedishBankIdLogin
          isActive={activeMethod === 'SwedishBankIdLogin'}
          onClick={() => setActiveMethod('SwedishBankIdLogin')}
          onBack={() => setActiveMethod(undefined)}
        />
      )}
    </Fragment>
  );
};

export default SwedishLoginMethods;
