import { DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Button, Form as AntdForm, Input as AntDInput } from 'antd';
import { format } from 'date-fns';
import { FieldArray, FormikProvider } from 'formik';
import { Form, Select, Input } from 'formik-antd';
import differenceWith from 'lodash/differenceWith';
import flow from 'lodash/flow';
import get from 'lodash/get';
import React, { Fragment, useState } from 'react';
import { FormattedMessage, WrappedComponentProps, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';

import { Practitioner, ExternalId } from 'api/practitionerApi';
import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import PageHeader from 'components/PageHeader';
import { DEFAULT_PHONE_COUNTRY_CODES, IDENTITIES } from 'constants/practitioner';
import RootStoreContext from 'context/RootStoreContext';
import { InputOption } from 'types/types';
import {
  addCountryCodeToPhoneNumber,
  removeNonDigitsFromPhoneNumber,
  removeStartingZeroFromPhoneNumber,
} from 'utils/phoneNumberUtils';

import styles from './PractitionerData.module.css';
import { useIdentityOptions } from './useIdentityOptions';
import { usePractitionerFormik } from './usePractitionerFormik';
import { checkEmailEquality } from './utils';

const { Title } = Typography;
const { Item: FormItem } = AntdForm;

interface Props extends Omit<WrappedComponentProps, 'intl'> {
  externalIdValidationFunction: (externalId: ExternalId) => Promise<Practitioner | undefined>;
  initialValues?: Practitioner;
  onSubmit: (data: Practitioner) => any;
  isFullyEditable: boolean;
  hasRoleAtUserCareUnit: boolean;
  isLoading: boolean;
  countryCallingCodes: string[];
}

const getExternalIdPlaceholder = (externalIdType: IDENTITIES) => {
  switch (externalIdType) {
    case IDENTITIES.SWEDISH_PERSONAL_IDENTITY_NUMBER:
      return 'general.swedish-bank-id-pattern';
    case IDENTITIES.NORWEGIAN_PERSONAL_IDENTITY_NUMBER:
      return 'general.norwegian-bank-id-pattern';
    case IDENTITIES.DANISH_PERSONAL_IDENTITY_NUMBER:
      return 'general.danish-bank-id-pattern';
    case IDENTITIES.EMAIL:
      return 'general.email-as-personal-id-pattern';
    case IDENTITIES.INTERNAL_IDP:
      return 'general.email-as-personal-id-pattern';
    default:
      return 'general.hsaid-pattern';
  }
};

const PractitionerData = (props: Props) => {
  const { id: userId } = useParams<{ id: string }>();
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState({});
  const { location } = useHistory();
  const isNewRolePage = location.pathname.includes('roles');
  const isEditMode = !!props.initialValues?.id;
  const { flashMessageService, practitionerStore } = React.useContext(RootStoreContext);

  const handleSubmit = (data: Practitioner & { countryCode: string }) => {
    const { countryCode } = data;
    const dataToSubmit = {
      ...data,
      externalIds: data.externalIds.map(({ externalId, ...others }) => ({
        ...others,
        externalId: externalId.trim(),
      })),
      countryCode: undefined,
    };

    if (dataToSubmit.mobileNumber) {
      dataToSubmit.mobileNumber = flow([
        removeNonDigitsFromPhoneNumber,
        removeStartingZeroFromPhoneNumber,
        mobileNumber =>
          mobileNumber ? addCountryCodeToPhoneNumber(mobileNumber, countryCode) : null,
      ])(dataToSubmit.mobileNumber);
    }
    props.onSubmit(dataToSubmit);
  };

  const handleExternalIdValidation = (externalId: ExternalId) => async (value: string) => {
    const { externalIdValidationFunction, initialValues } = props;

    if (!value?.trim()) return;
    if (
      searchTerm[location.pathname] &&
      searchTerm[location.pathname].externalIdType === externalId.externalIdType &&
      searchTerm[location.pathname].value === value
    ) {
      return searchTerm[location.pathname].result;
    }

    // if is edit mode and internal idp return don't even make a call.
    const isEdit = !!initialValues?.id;
    if (isEdit && initialValues.externalIdType === IDENTITIES.INTERNAL_IDP) return undefined;

    if (
      props.initialValues?.externalId === value &&
      props.initialValues?.externalIdType === externalId.externalIdType
    ) {
      return undefined;
    }

    try {
      const practitioner = await externalIdValidationFunction({
        externalIdType: externalId.externalIdType,
        externalId: value,
      });
      let result;

      if (practitioner && (!initialValues?.id || initialValues.id !== practitioner.id)) {
        result = intl.formatMessage(
          { id: 'general.errors.practitioner-exists' },
          {
            editLink: (
              <Link
                to={
                  isNewRolePage
                    ? `/roles/${practitioner.id}/edit`
                    : `/practitioners/${practitioner.id}`
                }
              >
                <FormattedMessage id="practitioner-data-form.practitioner-exists-error-edit-link" />
              </Link>
            ),
          }
        );
      }

      setSearchTerm({
        [location.pathname]: { value, result, externalIdType: externalId.externalIdType },
      });
      return result;
    } catch (error) {
      return intl.formatMessage({ id: 'general.error' });
    }
  };

  const getPhoneNumber = (number: string | null | undefined) => {
    if (!number) {
      return { phoneNumber: '', countryCode: '+46' };
    }

    const countryCode = props.countryCallingCodes?.find(countryCode =>
      number.startsWith(countryCode)
    );
    const phoneNumber = countryCode ? number.slice(countryCode.length) : number;

    return {
      phoneNumber,
      countryCode: countryCode || DEFAULT_PHONE_COUNTRY_CODES[props.initialValues!.externalIdType],
    };
  };

  const identityOptions = useIdentityOptions();

  const { phoneNumber: mobileNumber, countryCode } = getPhoneNumber(
    props.initialValues?.mobileNumber
  );

  const initialValues = {
    id: '',
    givenName: '',
    middleAndSurname: '',
    title: '',
    email: '',
    externalIds: [{ externalIdType: IDENTITIES.HSA_ID, externalId: '' }],
    ...props.initialValues,
    mobileNumber,
    countryCode,
  } as Practitioner & { countryCode: string };

  const formik = usePractitionerFormik({ initialValues, onSubmit: handleSubmit });

  const breadcrumbs: AppBreadcrumbItem[] = [
    {
      text: <FormattedMessage id="roles.header" />,
      link: isNewRolePage ? '/roles' : '/practitioners',
      icon: <TeamOutlined />,
    },
    {
      text: props.initialValues?.id ? (
        <FormattedMessage id="roles.edit.header" />
      ) : (
        <FormattedMessage id="roles.add.header" />
      ),
    },
  ];

  const titleId = !props.initialValues?.id ? 'roles.add.header' : 'roles.edit.header';

  const { hasRoleAtUserCareUnit, isFullyEditable, isLoading, countryCallingCodes } = props;

  const takenIdentityOptionsValues = formik.values.externalIds.map(
    ({ externalIdType }) => externalIdType
  );

  const handlePasswordReset = async () => {
    try {
      await practitionerStore.resetPassword(userId);
      flashMessageService.success(intl.formatMessage({ id: 'account.email-sent-info' }));
    } catch (error) {
      flashMessageService.success(intl.formatMessage({ id: 'general.error' }));
    }
  };

  const handleInvitationResend = async () => {
    try {
      await practitionerStore.resendInvite(userId);
      flashMessageService.success(intl.formatMessage({ id: 'account.email-sent-info' }));
    } catch (error) {
      flashMessageService.success(intl.formatMessage({ id: 'general.error' }));
    }
  };

  const getLink = () => {
    const isInternalIDP = props?.initialValues?.externalIdType === IDENTITIES.INTERNAL_IDP;
    if (!userId || !isInternalIDP) {
      return;
    }
    return props.initialValues?.activated ? (
      <Button
        data-testid="password-reset"
        className={styles.textDecorate}
        type="link"
        disabled={isLoading}
        onClick={handlePasswordReset}
      >
        <FormattedMessage id="account.reset-password" />
      </Button>
    ) : (
      <Button
        data-testid="send-invite"
        className={styles.textDecorate}
        type="link"
        disabled={isLoading}
        onClick={handleInvitationResend}
      >
        <FormattedMessage id="account.resend-invitation" />
      </Button>
    );
  };

  const getAccountCreationInfo = () => {
    if (!props?.initialValues?.createdAt) {
      return;
    }
    return (
      <span data-testid="account-info">
        <FormattedMessage id="account.account-created" />
        &nbsp;
        {props?.initialValues?.createdAt &&
          format(new Date(props?.initialValues?.createdAt), 'd.MM.yyyy').toString()}
        ,&nbsp;
        {props.initialValues?.activated ? (
          <FormattedMessage id="account.activated" />
        ) : (
          <FormattedMessage id="account.not-activated" />
        )}
      </span>
    );
  };

  const availableIdentityOptions = identityOptions.filter(
    ({ value }) => !takenIdentityOptionsValues.includes(value as IDENTITIES)
  );

  return (
    <Fragment>
      <PageHeader content={titleId} breadcrumbs={breadcrumbs} />
      <Title level={3}>
        <FormattedMessage id="practitioner-data-form.header" />
      </Title>
      <FormikProvider value={formik}>
        <Form layout="vertical" data-testid="practitioner-form">
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <FieldArray
              name="externalIds"
              render={arrayHelpers => (
                <Fragment>
                  {formik.values.externalIds.map((externalId, index) => {
                    const identitySelectOptions = differenceWith(
                      identityOptions,
                      takenIdentityOptionsValues.filter(
                        value => value !== externalId.externalIdType
                      ),
                      (value1: InputOption, value2: IDENTITIES) => value1.value === value2
                    );
                    const externalIdError =
                      get(formik.touched, `externalIds[${index}].externalId`) &&
                      get(formik.errors, `externalIds[${index}].externalId`);

                    return (
                      <FormItem
                        key={index}
                        label={
                          index === 0 && (
                            <FormattedMessage id="practitioner-data-form.practitioner-id-type-label" />
                          )
                        }
                        required
                        noStyle
                      >
                        <Row gutter={16}>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item
                              extra={
                                index === 0 && formik.values.externalIds.length > 1 ? (
                                  <FormattedMessage id="practitioner-data-form.primary-external-id-info" />
                                ) : undefined
                              }
                              name={`externalIds.${index}.externalIdType`}
                            >
                              <Select
                                name={`externalIds.${index}.externalIdType`}
                                data-testid="practitioner-externalIdType-field"
                                disabled={isLoading}
                                options={identitySelectOptions}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item
                              name={`externalIds.${index}.externalId`}
                              // This has to be set manually, because formik-antd does not
                              // handle FieldArray validation errors properly
                              validateStatus={externalIdError ? 'error' : undefined}
                              help={externalIdError}
                            >
                              <Input
                                placeholder={intl.formatMessage({
                                  id: getExternalIdPlaceholder(externalId.externalIdType),
                                })}
                                data-testid="practitioner-externalId-field"
                                name={`externalIds.${index}.externalId`}
                                // Unfortunately asynchronous validatation of field using other field value
                                // simply cannot be done using regular Yup validation scheme
                                validate={handleExternalIdValidation(externalId)}
                                disabled={
                                  isLoading ||
                                  (isEditMode &&
                                    externalId.externalIdType === IDENTITIES.INTERNAL_IDP)
                                }
                              />
                            </Form.Item>
                            {index === formik.values.externalIds.length - 1 &&
                              availableIdentityOptions.length > 0 &&
                              formik.values.externalIds.some(
                                item => item.externalIdType !== IDENTITIES.INTERNAL_IDP
                              ) && (
                                <div className={styles.addButtonContainer}>
                                  <Button
                                    type="link"
                                    onClick={() => {
                                      arrayHelpers.push({
                                        externalIdType: availableIdentityOptions[0].value,
                                        externalId: '',
                                      });
                                    }}
                                    className={styles.addButton}
                                    disabled={isLoading}
                                  >
                                    + <FormattedMessage id="general.add-more" />
                                  </Button>
                                </div>
                              )}
                          </Col>
                          {index > 0 && (
                            <Col span={1}>
                              <Button
                                type="link"
                                icon={<DeleteOutlined />}
                                className={styles.deleteButton}
                                onClick={() => arrayHelpers.remove(index)}
                                disabled={isLoading}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row gutter={16} className={styles.accountActivationInfo}>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            {getAccountCreationInfo()}
                          </Col>
                          <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            {getLink()}
                          </Col>
                        </Row>
                      </FormItem>
                    );
                  })}
                </Fragment>
              )}
            />
          )}
          <Row gutter={16}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
              <Form.Item
                label={
                  <FormattedMessage id="practitioner-data-form.practitioner-first-name-label" />
                }
                name="givenName"
                required={isFullyEditable}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-first-name-placeholder',
                  })}
                  name="givenName"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
              <Form.Item
                label={
                  <FormattedMessage id="practitioner-data-form.practitioner-last-name-label" />
                }
                name="middleAndSurname"
                required={isFullyEditable}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-last-name-placeholder',
                  })}
                  name="middleAndSurname"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                label={<FormattedMessage id="practitioner-data-form.practitioner-title-label" />}
                name="title"
                required={isFullyEditable}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'practitioner-data-form.practitioner-title-placeholder',
                  })}
                  name="title"
                  disabled={!isFullyEditable || isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  label={<FormattedMessage id="practitioner-data-form.practitioner-phone-label" />}
                  name="mobileNumber"
                >
                  <AntDInput.Group compact>
                    <Select
                      name="countryCode"
                      className={styles.countryCode}
                      options={countryCallingCodes?.map(value => ({
                        label: value,
                        value,
                      }))}
                      disabled={!isFullyEditable || isLoading}
                    />
                    <div className={styles.phoneNumber}>
                      <Input
                        placeholder={intl.formatMessage({
                          id: 'practitioner-data-form.practitioner-phone-placeholder',
                        })}
                        name="mobileNumber"
                        disabled={!isFullyEditable || isLoading}
                      />
                    </div>
                  </AntDInput.Group>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item
                  label={<FormattedMessage id="practitioner-data-form.practitioner-email-label" />}
                  name="email"
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'practitioner-data-form.practitioner-email-placeholder',
                    })}
                    validate={checkEmailEquality(
                      formik.values,
                      intl.formatMessage({ id: 'general.email-validation-error' }),
                      isEditMode
                    )}
                    data-testid="practitioner-email-field"
                    name="email"
                    disabled={
                      !isFullyEditable ||
                      isLoading ||
                      //fully editable and provider is internalIDP disables email field.
                      (formik.values.externalIdType === IDENTITIES.INTERNAL_IDP && isEditMode)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {(hasRoleAtUserCareUnit || isFullyEditable) && (
            <Row gutter={16}>
              <Col span={12} className={styles.submitButtonContainer}>
                <Button
                  shape="round"
                  type="default"
                  disabled={isLoading || !formik.dirty}
                  htmlType="reset"
                >
                  <FormattedMessage id="general.cancel" />
                </Button>
                <Button
                  data-testid="practitioner-submit"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                  className={styles.submitButton}
                  disabled={isLoading || !formik.isValid}
                >
                  <FormattedMessage id="general.save" />
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </FormikProvider>
    </Fragment>
  );
};

export default PractitionerData;
