import axios from 'axios';

import { IDENTITIES } from 'constants/practitioner';

export interface CarePlanGroupsListItem {
  id: string;
  name: string;
  careUnitId: string;
  careUnits: string[];
  numberOfParticipants: number;
}

export interface CarePlanGroupPractitionersListItem {
  id: string;
  practitionerId: string;
  name: string;
  externalIds: Array<{
    id: string;
    type: IDENTITIES;
  }>;
}

export interface PractitionerIdName {
  id: string;
  givenName: string;
  middleAndSurname: string;
}

export const fetchCarePlanGroups = (partnerId: string) =>
  axios.get<CarePlanGroupsListItem[]>(`/rest/admin/partners/${partnerId}/careplanner/groups`);

export const fetchCarePlanGroupPractitioners = (partnerId: string, groupId: string) =>
  axios.get<CarePlanGroupPractitionersListItem[]>(
    `/rest/admin/partners/${partnerId}/careplanner/groups/${groupId}/participants`
  );

// TODO: we're doing this every time the "Add Administrator" button is clicked -- might be nice to cache this
export const fetchPractitionerIdNames = (careUnitIds: string[]) =>
  axios.post<PractitionerIdName[]>(`/rest/admin/v2/care-units/practitioners`, careUnitIds);

export const addPractitioner = (
  partnerId: string,
  groupId: string,
  practitioners: Array<{ practitionerId: string }>
) =>
  axios.post(
    `/rest/admin/partners/${partnerId}/careplanner/groups/${groupId}/participants`,
    practitioners
  );

export const removePractitioner = (partnerId: string, groupId: string, practitionerId: string) =>
  axios.delete(
    `/rest/admin/partners/${partnerId}/careplanner/groups/${groupId}/participants/${practitionerId}`
  );
