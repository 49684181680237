import flattenDeep from 'lodash/flattenDeep';
import { observable, IObservableArray, runInAction, computed, action, ObservableMap } from 'mobx';

import { CareUnitBase } from 'api/careUnitsApi';
import { fetchConfiguration } from 'api/configApi';
import {
  Partner,
  PartnerCareProvider,
  fetchPartners,
  ChildOrigin,
  PartnerUserRole,
  fetchPartnerUsers,
  deletePartnerUserRole,
  updatePartnerUserRole,
} from 'api/partnersApi';
import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { LOCAL_STORAGE_KEYS } from 'constants/localStorageKeys';
import { sortWithLocale } from 'utils/textUtils';

import StateManager from './abstractStores/StateManager';
import ImageStore from './ImageStore';
import RootStore from './RootStore';

export default class PartnersStore extends StateManager {
  imageStore: ImageStore;
  @observable.shallow partners: IObservableArray<Partner> = observable.array([], { deep: false });
  @observable.shallow careProviders: IObservableArray<PartnerCareProvider> = observable.array([], {
    deep: false,
  });
  @observable.shallow rootOrigins: IObservableArray<ChildOrigin> = observable.array([], {
    deep: false,
  });
  @observable currentPartner?: Partner;
  @observable partnerRoles: IObservableArray<PartnerUserRole> = observable.array([]);
  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable partnerCustomizations: ObservableMap<string, any> = observable.map();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @observable partnerConfig: ObservableMap<string, any> = observable.map();

  @observable.shallow countryCallingCodes: IObservableArray<string> = observable.array([], {
    deep: false,
  });

  constructor(private rootStore: RootStore) {
    super();
    this.imageStore = new ImageStore();
  }

  fetchPartners = async () => {
    this.setLoading();

    try {
      const { data } = await fetchPartners();

      runInAction(() => {
        this.partners.replace(data);
      });

      this.setLoaded();
      return data;
    } catch (e) {
      this.manageException(e);
      return undefined;
    }
  };

  fetchPartnerConfiguration = async () => {
    this.setLoading();
    try {
      const { data } = await fetchConfiguration();
      runInAction(() => {
        const configuration = new Map();
        for (let i = 0; data && i < data.length; i++) {
          const { key, value } = data[i];
          configuration.set(key, value);
        }

        this.partnerConfig.replace(configuration);
      });

      this.setLoaded();
    } catch (e) {
      this.manageException(e);
    }
  };

  fetchCountryCallingCodes = async () => {
    this.setLoading();

    try {
      const { data } = await fetchConfiguration();
      const callingCodes = data?.find(item => item.key === 'COUNTRY_CALLING_CODES')?.value || [];

      runInAction(() => {
        this.countryCallingCodes.replace(callingCodes as string[]);
        this.setLoaded();
      });
    } catch (e) {
      this.manageException(e);
    }
  };

  setCurrentPartner = async () => {
    const partnerId = localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID);

    if (!partnerId) {
      this.rootStore.flashMessageService.translatedError('partners.errors.partner-not-found');
      return;
    }

    if (!this.partners.length) {
      await this.fetchPartners();
    }

    const partner = this.partners.find(p => p.id === partnerId);

    if (!partner) {
      this.rootStore.flashMessageService.translatedError('partners.errors.partner-not-found');
      return;
    }

    try {
      const { data: partnerCustomizations } = await fetchConfiguration();

      runInAction(() => {
        this.currentPartner = partner;
        this.partnerCustomizations = this.partnerCustomizations.replace(
          partnerCustomizations.map(({ key, value }) => [key, value])
        );

        const careProviders = partner.careProviders?.length ? partner.careProviders : [];
        const rootOrigins = partner.origins?.length
          ? partner.origins.map(origin => ({
              ...origin,
              origins:
                origin.origins && origin.origins.length
                  ? this.addParentsToOrigins(origin.origins, origin.id)
                  : origin.origins,
            }))
          : [];

        this.careProviders.replace(careProviders);
        this.rootOrigins.replace(rootOrigins);
        this.imageStore.initialize(partner.id);
      });
      // This should fail silently
      // eslint-disable-next-line no-empty
    } catch {}
  };

  get partnerId() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.PARTNER_ID) || '';
  }

  private addParentsToOrigins = (origins: ChildOrigin[], parentId: string): ChildOrigin[] =>
    origins.map(o => ({
      ...o,
      origins:
        o.origins && o.origins.length ? this.addParentsToOrigins(o.origins, o.id) : o.origins,
      parentId,
    }));

  @computed
  get partnersAsSelectOptions() {
    return this.partners
      .map(partner => ({
        value: partner.id,
        label: partner.id,
      }))
      .sort((a, b) => sortWithLocale(a, b, 'label'));
  }

  @computed
  get isReadOnlyModeEnabled() {
    return CURRENT_ENV_TYPE === ENV.DEMO || this.rootStore.partnerStatusStore.isMergeRequestPending;
  }

  @computed
  get allOrigins() {
    return this.flattenOrigins(this.rootOrigins.slice());
  }

  flattenOrigins = (origins: ChildOrigin[]) => {
    const flat: ChildOrigin[] = [];

    origins.forEach(origin => {
      flat.push(origin);
      if (origin.origins && origin.origins.length) {
        flat.push(...this.flattenOrigins(origin.origins));
      }
    });

    return flat;
  };

  isRootOrigin(originId: string) {
    return this.rootOrigins.some(({ id }) => originId === id);
  }

  fetchPartnerUsersRoles = async () => {
    this.setLoading();

    try {
      const { data } = await fetchPartnerUsers(this.rootStore.partnersStore.partnerId);

      const partnerUsers = data.map(
        ({ practitionerId = '', practitionerGivenName, practitionerMiddleAndSurname, role }) => ({
          practitionerId,
          practitionerName: `${practitionerGivenName} ${practitionerMiddleAndSurname}`,
          role,
        })
      );

      runInAction(() => {
        this.partnerRoles.replace(partnerUsers);
      });

      this.setLoaded();
    } catch (error) {
      this.manageException(error);
    }
  };

  @action
  deletePartnerRole = async (practitionerId: string) => {
    const { flashMessageService, partnersStore } = this.rootStore;

    if (this.partnerRoles.length === 1) {
      flashMessageService.translatedError('origins.add-origin.no-roles-error');
      throw new Error('At least one role must be assigned');
    }

    try {
      await deletePartnerUserRole(practitionerId, partnersStore.partnerId);

      const roleToRemove = this.partnerRoles.find(role => role.practitionerId === practitionerId);

      if (roleToRemove === undefined) {
        throw new Error('No role exists for provided practitionerId');
      }

      runInAction(() => {
        this.partnerRoles.remove(roleToRemove);
      });
    } catch (error) {
      this.manageException(error);
    }
  };

  @action
  savePartnerRole = async (role: PartnerUserRole) => {
    try {
      const existingRoleIndex = this.partnerRoles.findIndex(
        ({ practitionerId }) => practitionerId === role.practitionerId
      );

      // The backend API doesn't support PUT requests, so updating an existing role means:
      // 1. delete the old role
      // 2. save the new role
      if (existingRoleIndex !== -1) {
        await this.deletePartnerRole(role.practitionerId);
      }

      await updatePartnerUserRole(
        {
          practitionerId: role.practitionerId,
          role: role.role,
        },
        this.rootStore.partnersStore.partnerId
      );

      runInAction(() => {
        this.partnerRoles.push(role);
      });
    } catch (error) {
      this.manageException(error);
    }
  };

  manageException = (error: unknown) => {
    this.setError();
    throw error;
  };

  @computed
  get currentPartnerCareUnits(): CareUnitBase[] {
    if (this.careProviders?.length) {
      const allCareUnits = this.careProviders.map(careProvider => careProvider.careUnits || []);

      return flattenDeep(allCareUnits);
    }
    return [];
  }
}
