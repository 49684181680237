import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button, Divider } from 'antd';
import { FieldArray, FormikValues } from 'formik';
import { Select, Form, Input } from 'formik-antd';
import difference from 'lodash/difference';
import isPlainObject from 'lodash/isPlainObject';
import transform from 'lodash/transform';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { LANGS } from 'constants/enums';
import { InputOption } from 'types/types';

import styles from './RuleOutcome.module.css';
import { OriginRuleCondition, OriginRuleOutcomeOperators } from '../../api/rulesApi';

interface Props {
  values: FormikValues;
  activeLanguage: LANGS;
  isDisabled: boolean;
}

const RuleOutcome = ({ values, activeLanguage, isDisabled }: Props) => (
  <FieldArray
    name="outcome"
    render={arrayHelpers => {
      const lastItemIndex = values.outcome.length - 1;

      return (
        <Fragment>
          {values.outcome.map((_: OriginRuleCondition, index: number) => {
            const outcomeValue = values.outcome[index].value;
            const isOutcomeValueTranslatedText =
              isPlainObject(outcomeValue) &&
              !difference(Object.keys(outcomeValue), Object.values(LANGS)).length;
            const isOutcomeValueArray = Array.isArray(outcomeValue);
            const hasMethod =
              !!values.outcome[index].method && !!values.outcome[index].methodProperties;

            return (
              <Fragment key={index}>
                <div className={styles.header}>
                  <div>
                    <FormattedMessage id="origin.rules.outcome" /> {index + 1}
                  </div>
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => arrayHelpers.remove(index)}
                    className={styles.deleteButton}
                    disabled={isDisabled}
                  />
                </div>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name={`outcome.${index}.field`}
                      label={<FormattedMessage id="origin.rules.field" />}
                      required
                    >
                      <Input name={`outcome.${index}.field`} disabled={isDisabled} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={`outcome.${index}.operator`}
                      label={<FormattedMessage id="origin.rules.operator" />}
                      required
                    >
                      <Select
                        name={`outcome.${index}.operator`}
                        disabled={isDisabled}
                        options={transform<
                          OriginRuleOutcomeOperators,
                          InputOption<OriginRuleOutcomeOperators, OriginRuleOutcomeOperators>[]
                        >(
                          OriginRuleOutcomeOperators,
                          (accumulator, value) => accumulator.push({ label: value, value }),
                          []
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    {isOutcomeValueTranslatedText && (
                      <Form.Item
                        name={`outcome.${index}.value.${activeLanguage}`}
                        label={<FormattedMessage id="origin.rules.value" />}
                        required
                      >
                        <Input
                          name={`outcome.${index}.value.${activeLanguage}`}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    )}
                    {isOutcomeValueArray && (
                      <Form.Item
                        name={`outcome.${index}.value`}
                        label={<FormattedMessage id="origin.rules.value" />}
                        required
                      >
                        <Select name={`outcome.${index}.value`} mode="tags" disabled={isDisabled} />
                      </Form.Item>
                    )}
                    {!isOutcomeValueArray && !isOutcomeValueTranslatedText && (
                      <Form.Item
                        name={`outcome.${index}.value`}
                        label={<FormattedMessage id="origin.rules.value" />}
                        required
                      >
                        <Input name={`outcome.${index}.value`} disabled={isDisabled} />
                      </Form.Item>
                    )}
                  </Col>
                </Row>
                {hasMethod && (
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item
                        name={`outcome.${index}.method`}
                        label={<FormattedMessage id="origin.rules.method" />}
                      >
                        <Input name={`outcome.${index}.method`} disabled={isDisabled} />
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name={`outcome.${index}.methodProperties`}
                        label={<FormattedMessage id="origin.rules.method-properties" />}
                      >
                        <Input.TextArea
                          name={`outcome.${index}.methodProperties`}
                          rows={3}
                          value={values.outcome[index].methodProperties}
                          disabled={isDisabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {index !== lastItemIndex && <Divider />}
              </Fragment>
            );
          })}
          <Button
            type="link"
            onClick={() => {
              arrayHelpers.push({
                field: '',
                operator: '',
                value: '',
              });
            }}
            className={styles.addButton}
            disabled={isDisabled}
          >
            + <FormattedMessage id="general.add-more" />
          </Button>
        </Fragment>
      );
    }}
  />
);

export default RuleOutcome;
