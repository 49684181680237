import { ApartmentOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Badge, Drawer, Tabs } from 'antd';
import { observer } from 'mobx-react';
import React, { Fragment, ContextType, Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';

import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';

import DeploymentButton from './components/DeploymentButton';
import LogHistory from './components/LogHistory';
import MergeRequestButton from './components/MergeRequestButton';
import MergeRequestDetailsModal from './components/MergeRequestDetailsModal';
import PartnerStatusBar from './components/PartnerStatusBar';
import PartnerStatusChanges from './components/PartnerStatusChanges';
import SendForApprovalModal from './components/SendForApprovalModal';
import styles from './PartnerStatus.module.css';
import PartnerStatusViewStore, { PARTNER_STATUS_TABS } from './stores/PartnerStatusViewStore';

const { TabPane } = Tabs;

type Props = WrappedComponentProps;

@observer
class PartnerStatus extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  partnerStatusViewStore: PartnerStatusViewStore;

  constructor(props: any, context: RootStore) {
    super(props);
    this.partnerStatusViewStore = new PartnerStatusViewStore(context);
  }

  componentDidMount() {
    this.context.partnerStatusStore.fetchPartnerGitStatus();
  }

  render() {
    const {
      handleShowDrawerOnLogHistoryTab,
      handleShowDrawerOnChangesTab,
      hideStatusDrawer,
      isStatusDrawerVisible,
      activeTab,
      setActiveTab,
      handleTabChange,
      showLogItemDetails,
      currentPartnerStatus,
      cancelCurrentMergeRequest,
      handleSaveMergeRequest,
      showApprovalModal,
      hideApprovalModal,
      isApprovalModalVisible,
      activeLogItem,
      hideLogItemDetails,
    } = this.partnerStatusViewStore;
    const { partnerStatusStore, mergeRequestStore } = this.context;

    return (
      <Fragment>
        <PartnerStatusBar
          onLogHistoryClick={handleShowDrawerOnLogHistoryTab}
          onChangesClick={handleShowDrawerOnChangesTab}
        />

        <Drawer
          visible={isStatusDrawerVisible}
          width={500}
          mask={false}
          onClose={hideStatusDrawer}
          bodyStyle={{ height: '100%' }}
          className={styles.drawer}
        >
          <MergeRequestButton
            currentPartnerStatus={currentPartnerStatus}
            onCreateMergeRequest={showApprovalModal}
          />
          <Tabs
            activeKey={activeTab}
            onTabClick={(key: string) => setActiveTab(key as PARTNER_STATUS_TABS)}
            onChange={handleTabChange}
          >
            <TabPane
              tab={
                <span>
                  <ClockCircleOutlined />
                  <FormattedMessage id="partner-status.log-history" />
                </span>
              }
              key={PARTNER_STATUS_TABS.LOG_HISTORY}
            >
              <LogHistory onLogItemDetailsClick={showLogItemDetails} />
            </TabPane>
            <TabPane
              tab={
                <span>
                  <ApartmentOutlined />
                  <span className={styles.tabTitle}>
                    <FormattedMessage id="partner-status.changes" />
                  </span>
                  <Badge count={partnerStatusStore.partnerGitStatus.changesCount} />
                </span>
              }
              key={PARTNER_STATUS_TABS.CHANGES}
            >
              <DeploymentButton currentPartnerStatus={currentPartnerStatus} />
              <PartnerStatusChanges
                currentPartnerStatus={currentPartnerStatus}
                onCancelMergeRequest={cancelCurrentMergeRequest}
                onEditMergeRequest={showApprovalModal}
              />
            </TabPane>
          </Tabs>
        </Drawer>

        <SendForApprovalModal
          initialValues={currentPartnerStatus}
          isVisible={isApprovalModalVisible}
          isSaving={partnerStatusStore.isLoading || mergeRequestStore.isLoading}
          onSubmit={handleSaveMergeRequest}
          onCancel={hideApprovalModal}
        />

        <MergeRequestDetailsModal data={activeLogItem} onCancel={hideLogItemDetails} />
      </Fragment>
    );
  }
}

export default injectIntl(PartnerStatus);
