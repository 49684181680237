import { LeftOutlined } from '@ant-design/icons';
import { Button, Input, Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import React, { useCallback, Fragment, useContext, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';

import { SWEDISH_BANK_ID_REGEX } from 'constants/regex';
import RootStoreContext from 'context/RootStoreContext';

interface Props extends RouteComponentProps, WrappedComponentProps {
  isActive: boolean;
  onClick: () => void;
  onBack: () => void;
}

const SwedishBankIdLogin = ({ isActive, onClick, onBack, history, intl }: Props) => {
  const { authStore } = useContext(RootStoreContext);
  const [isLoading, handleLoadingStateChange] = useState(false);

  const handleSubmit = useCallback(
    async (values: Store) => {
      try {
        const { bankId } = values;
        handleLoadingStateChange(true);
        await authStore.loginWithSwedishBankId(bankId);
        /* eslint-disable no-empty */
      } catch (error: any) {
      } finally {
        history.push('/login/select-partner');
      }
    },
    [history, authStore]
  );

  return (
    <Fragment>
      {!isActive && (
        <Button
          onClick={onClick}
          type="primary"
          size="large"
          loading={isLoading}
          block
          data-testid="swedish-bank-id-login-btn"
        >
          <FormattedMessage id="login.with-bank-id" />
        </Button>
      )}
      {isActive && (
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="bankId"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: 'login.please-use-valid-bank-id' }),
              },
              {
                pattern: SWEDISH_BANK_ID_REGEX,
                message: intl.formatMessage({ id: 'login.please-use-valid-bank-id' }),
              },
            ]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'general.swedish-bank-id-pattern' })}
              disabled={isLoading}
              data-testid="swedish-bank-id-input"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              disabled={isLoading}
              loading={isLoading}
              htmlType="submit"
              size="large"
              block
              data-testid="swedish-bank-id-login-btn"
            >
              <FormattedMessage id="login.login" />
            </Button>
          </Form.Item>
          {isLoading && <FormattedMessage id="login.swedish-bank-id-mobile-app" />}
          <Button type="link" disabled={isLoading} onClick={onBack} icon={<LeftOutlined />}>
            <FormattedMessage id="general.back" />
          </Button>
        </Form>
      )}
    </Fragment>
  );
};

export default withRouter(injectIntl(SwedishBankIdLogin));
