import { toast, ToastOptions, ToastContent, TypeOptions } from 'react-toastify';
import uuid4 from 'uuid/v4';

import { renderFlashMessageError } from 'components/FlashMessage/FlashMessageError';
import { renderTranslatedMessage } from 'components/FlashMessage/FlashMessageTranslated';
import { DEFAULT_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { FlashMessageService, ActiveMessage } from 'types/types';

import { D24TrackedError } from './ErrorReporting/D24ErrorReporting';

export class DefaultFlashMessageService implements FlashMessageService<ToastContent, ToastOptions> {
  private activeMessages: ActiveMessage[] = [];

  trackedError = (error: D24TrackedError) => {
    const id = uuid4();
    this.activeMessages.push({ id, type: 'error' });
    const options = {
      onClose: () => this.removeMessageFromList(id),
    };

    toast.error(renderFlashMessageError(error), options);
  };

  error = (error: ToastContent, options?: ToastOptions) => this._error(error, options || {});

  private _error = (error: ToastContent, options: ToastOptions) => {
    if (Array.isArray(error)) {
      error.forEach(e => this._notifyError(e, options));
    } else {
      this._notifyError(error, options);
    }
  };

  private _notifyError = (error: ToastContent | JSX.Element, options?: ToastOptions) => {
    const id = uuid4();
    this.activeMessages.push({ id, type: 'error' });

    toast.error(error, {
      autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT,
      onClose: () => this.removeMessageFromList(id),
      ...options,
    });
  };

  success = (message: ToastContent, options?: ToastOptions) => {
    const id = uuid4();
    this.activeMessages.push({ id, type: 'success' });

    toast.success(message, {
      autoClose: DEFAULT_FLASH_MESSAGE_TIMEOUT,
      onClose: () => this.removeMessageFromList(id),
      ...options,
    });
  };

  // To be refactored
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translatedError = (error: string, options?: ToastOptions, msgValues?: { [key: string]: any }) =>
    this._notifyError(renderTranslatedMessage(error, msgValues), options);

  translatedSuccess = (
    message: string,
    options?: ToastOptions,
    // To be refactored
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    msgValues?: { [key: string]: any }
  ) => this.success(renderTranslatedMessage(message, msgValues), options);

  getActiveMessagesByType = (type: TypeOptions) => {
    const foundMessages = this.activeMessages.filter(m => m.type === type);

    return foundMessages.length ? foundMessages : null;
  };

  private removeMessageFromList = (id: string) => {
    this.activeMessages = this.activeMessages.filter(m => m.id !== id);
  };
}
