import { Button, Space, Spin } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import css from './SwedishSecureBankIdLogin.module.css';
import { SwedishSecureBankIdLoginInstructions } from './SwedishSecureBankIdLoginInstructions';
import { isDisplayFriendlyError } from '../../parts/SwedishBankId';
import { useSwedishSecureLogin } from '../../parts/SwedishBankId/useSwedishSecureLogin';

export const SwedishSecureBankIdLoginForm = () => {
  const { retry, status, autoStartToken, startOnThisDevice } = useSwedishSecureLogin();

  if (isDisplayFriendlyError(status)) {
    return (
      <Space
        direction="vertical"
        align="center"
        data-testid="swedish-secure-bank-id-login-form"
        data-is-error="true"
      >
        <SwedishSecureBankIdLoginInstructions text={status.text} />

        <div className={css.errorViewSpace} />

        <Button type="primary" onClick={retry} danger ghost data-testid="login.try-again">
          <FormattedMessage id="login.try-again" />
        </Button>
      </Space>
    );
  }

  const showLoading = !status.qrCodeString && !status.base64QrCode;

  const isSameDeviceButtonEnabled = true;

  return (
    <Space
      align="center"
      direction="vertical"
      data-testid="swedish-secure-bank-id-login-form"
      data-qrcode={status.qrCodeString}
    >
      <SwedishSecureBankIdLoginInstructions text={status.text} />
      {status.qrCodeString && (
        <QRCodeCanvas
          className={css.qrCodeContainer}
          value={status.qrCodeString}
          size={245}
          includeMargin
          data-testid="swedish-secure-bank-id-qr-code"
        />
      )}

      {!showLoading && isSameDeviceButtonEnabled && autoStartToken && (
        <Button onClick={startOnThisDevice} type="ghost">
          <FormattedMessage id="login.bank-id-on-same-device" />
        </Button>
      )}

      {showLoading && (
        <Spin data-testid="login.loading-indicator" className={css.qrCodeContainer} size="large" />
      )}
    </Space>
  );
};
