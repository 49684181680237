import axios from 'axios';

import HttpInterceptor from 'config/HttpInterceptor';
import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { DefaultFlashMessageService } from 'services/DefaultFlashMessageService';
import { D24ErrorReporting } from 'services/ErrorReporting/D24ErrorReporting';
import { ConsoleReporter } from 'services/ErrorReporting/reporters/ConsoleReporter';
import { FlashMessageReporter } from 'services/ErrorReporting/reporters/FlashMessageReporter';
import EventTrackingService from 'services/EventTrackingService';
import RootStore from 'stores/RootStore';

export const defaultFlashMessageService = new DefaultFlashMessageService();
export const rootStore = new RootStore(defaultFlashMessageService);

const defaultErrorReporter = new D24ErrorReporting({
  flashMessage: new FlashMessageReporter(defaultFlashMessageService),
});

if (CURRENT_ENV_TYPE !== ENV.PROD) {
  defaultErrorReporter.registerReporter('console', new ConsoleReporter());
}

export const defaultInterceptorsBuilder = new HttpInterceptor(rootStore, defaultErrorReporter);
defaultInterceptorsBuilder.registerInterceptors(axios);

export const eventTrackingService = new EventTrackingService(rootStore);

export const enum EVENT_NAMES {
  MANAGE_ERROR = 'manageError',
}
