import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip, Form } from 'antd';
import classNames from 'classnames';
import { Field, FieldProps } from 'formik';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './StepField.module.css';

export type InputProps = {
  isSaving: boolean;
  isDisabled?: boolean;
  onChange?: (value: number) => void;
};

const MAX_STEP = 1;
const MIN_STEP = 0.0001;

const StepField: FunctionComponent<InputProps> = ({ isDisabled, isSaving, onChange }) => (
  <Field name="step">
    {({ field, form }: FieldProps) => {
      const handleDecreaseStep = () => {
        const fieldValue = field.value !== undefined ? field.value : 1;
        if (fieldValue > MIN_STEP) {
          const newFieldValue = fieldValue / 10;
          form.setFieldValue('step', newFieldValue);
          onChange && onChange(newFieldValue);
        }
      };

      const handleIncreaseStep = () => {
        const fieldValue = field.value !== undefined ? field.value : 0.1;
        if (fieldValue < MAX_STEP) {
          const newFieldValue = fieldValue * 10;
          form.setFieldValue('step', newFieldValue);
          onChange && onChange(newFieldValue);
        }
      };

      return (
        <Form.Item
          validateStatus={form.errors.step ? 'error' : undefined}
          hasFeedback={!!form.errors.step}
          help={form.errors.step}
          label={<FormattedMessage id="condition-edit.step-label" />}
        >
          <Input value={field.value} readOnly disabled={isDisabled} />
          <div className={styles.stepBtnsContainer}>
            <Tooltip
              placement="top"
              title={<FormattedMessage id="condition-edit.decrease-decimal-places" />}
              mouseEnterDelay={0.7}
            >
              <Button
                className={styles.stepBtn}
                onClick={handleIncreaseStep}
                disabled={isDisabled || isSaving}
              >
                <div>
                  <ArrowLeftOutlined className={classNames(styles.arrow, styles.arrowLeft)} />
                  <span>.0</span>
                </div>
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={<FormattedMessage id="condition-edit.increase-decimal-places" />}
              mouseEnterDelay={0.7}
            >
              <Button
                className={styles.stepBtn}
                onClick={handleDecreaseStep}
                disabled={isDisabled || isSaving}
              >
                <div>
                  <span>.00</span>
                  <ArrowRightOutlined className={classNames(styles.arrow, styles.arrowRight)} />
                </div>
              </Button>
            </Tooltip>
          </div>
        </Form.Item>
      );
    }}
  </Field>
);

export default StepField;
