import { Modal, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import RootStoreContext from 'context/RootStoreContext';
import {
  ExpressionValidationResult,
  Rule,
  RuleDraft,
  RulesAttribute,
  RULES_ATTRIBUTE_TYPE,
} from 'modules/Rules/types';
import {
  prepareRuleDataToSubmit,
  prepareRuleDataToRender,
  getBaseValueType,
  getConstraints,
} from 'modules/Rules/utils/utils';
import { validateRuleCondition } from 'modules/Rules/utils/validationUtils';
import { Optional, Primitive } from 'types/types';
import { asyncDebounce } from 'utils/appUtils';

import { OutcomesList } from './OutcomesList';

interface RuleFormProps extends RouteComponentProps {
  rule?: Optional<Rule>;
  isSaving: boolean;
  isFormDisabled?: boolean;
  onSubmit: (updatedRule: Rule | RuleDraft) => Promise<void>;
  onCancel: () => void;
  visible?: boolean;
}

const RuleFormComponent = ({
  visible = false,
  onSubmit,
  onCancel,
  isSaving,
  rule,
  isFormDisabled = false,
}: RuleFormProps) => {
  const { formatMessage } = useIntl();

  const initialValues: RuleDraft = rule
    ? prepareRuleDataToRender(rule)
    : {
        id: '',
        description: '',
        when: null,
        outcomes: [{ field: null, value: null, operator: null }],
      };

  const shouldPreventEditing = isFormDisabled || isSaving;

  const {
    rulesPackageStore: { rulesAttributes },
    partnersStore: { partnerId },
  } = useContext(RootStoreContext);

  const onSubmitHandler = async (valuesToSend: RuleDraft) =>
    await onSubmit(prepareRuleDataToSubmit(valuesToSend));

  const debouncedIsConditionValid = asyncDebounce(validateRuleCondition, 500);

  const unsupportedTypeSchema = Yup.mixed().transform(() => {
    return undefined;
  });

  const fieldIsRequiredMsg = formatMessage({ id: 'general.errors.required' });

  const getPossibleValidationSchema = (outcomeMeta: RulesAttribute) => {
    const baseValueType = getBaseValueType(outcomeMeta);
    return Yup.lazy<Primitive>(() => {
      switch (baseValueType) {
        case RULES_ATTRIBUTE_TYPE.BOOL:
          return Yup.boolean().required(fieldIsRequiredMsg).nullable();
        case RULES_ATTRIBUTE_TYPE.LONG:
          return Yup.number().integer().required(fieldIsRequiredMsg).nullable();
        case RULES_ATTRIBUTE_TYPE.DOUBLE:
          return Yup.number().required(fieldIsRequiredMsg).nullable();

        case RULES_ATTRIBUTE_TYPE.DATE:
          return Yup.date().required(fieldIsRequiredMsg).nullable();
        case RULES_ATTRIBUTE_TYPE.STRING:
        case RULES_ATTRIBUTE_TYPE.TIME:
          return Yup.string().required(fieldIsRequiredMsg).nullable();
        default:
          console.error(`this type of value is not supported yet: ${baseValueType}`);
          // we return schema which is always return validation error here
          // in order to prevent sending values of not supported type to BE
          return unsupportedTypeSchema.required(fieldIsRequiredMsg);
      }
    });
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string().required(fieldIsRequiredMsg),
    description: Yup.string().nullable(),
    when: Yup.string()
      .nullable()
      .max(200)
      .test(
        'isConditionValid',
        ({ message }: Partial<ExpressionValidationResult & Yup.TestMessageParams>) => message,
        async function (this: Yup.TestContext, value: any) {
          return debouncedIsConditionValid(this, value, partnerId);
        }
      ),
    outcomes: Yup.array()
      .of(
        Yup.object().shape({
          field: Yup.string()
            .required(fieldIsRequiredMsg)
            .nullable()
            .test(
              'doesFiledHaveMeta',
              ({ value }) =>
                formatMessage(
                  { id: 'rules.errors.attribute-for-filed-missed' },
                  { fieldId: value }
                ),
              fieldId => !!rulesAttributes[fieldId]
            ),
          operator: Yup.string().required(fieldIsRequiredMsg).nullable(),

          value: Yup.mixed().when('field', (field, schema) => {
            // field === null --> creation of new outcome
            // field === undefined --> filed select has been cleared
            if (field === null || field === undefined) {
              return schema.required(fieldIsRequiredMsg);
            }

            const outcomeMeta = rulesAttributes[field];

            if (!outcomeMeta) {
              console.error(`not possible to find meta data for this field type: ${field}`);
              // we return schema which is always return validation error here
              // since it not possible to validate value we know nothing about
              return unsupportedTypeSchema;
            }
            const baseType = outcomeMeta.type.baseType;

            const possibleTypeSchemas = getPossibleValidationSchema(outcomeMeta);

            let finalSchema = schema;
            switch (baseType) {
              case RULES_ATTRIBUTE_TYPE.BOOL: {
                finalSchema = Yup.boolean();
                break;
              }

              case RULES_ATTRIBUTE_TYPE.LONG:
              case RULES_ATTRIBUTE_TYPE.DOUBLE: {
                finalSchema = Yup.number().transform((value, inputValue) => {
                  return inputValue === '' ? undefined : value;
                });

                // constraints could also have allowedValues array but we do not validate for it
                // because we have done this validation on form input level since as soon as there are
                // allowedValues we render select with predefined list of options
                const { min, max } = getConstraints(outcomeMeta);

                if (min !== null) {
                  finalSchema = finalSchema.min(
                    min,
                    formatMessage({ id: 'general.errors.int_outbound_min' }, { min })
                  );
                }
                if (max !== null) {
                  finalSchema = finalSchema.max(
                    max,
                    formatMessage({ id: 'general.errors.int_outbound_max' }, { max })
                  );
                }

                if (baseType === RULES_ATTRIBUTE_TYPE.LONG) {
                  finalSchema = finalSchema.integer(
                    formatMessage({ id: 'general.errors.numbers-validation-integers-only' })
                  );
                }

                break;
              }

              case RULES_ATTRIBUTE_TYPE.DATE: {
                finalSchema = Yup.date();
                break;
              }

              case RULES_ATTRIBUTE_TYPE.STRING:
              case RULES_ATTRIBUTE_TYPE.TIME: {
                // we have covered format on the input level. So it is not possible to input anything else but time only (12:21)
                // here we just checking it is passed in correct type which is string
                finalSchema = Yup.string();
                break;
              }

              case RULES_ATTRIBUTE_TYPE.LIST:
              case RULES_ATTRIBUTE_TYPE.SET: {
                finalSchema = Yup.array().of(possibleTypeSchemas);
                break;
              }

              case RULES_ATTRIBUTE_TYPE.MAP: {
                finalSchema = Yup.array().of(
                  Yup.object().shape({
                    key: Yup.string().nullable().required(fieldIsRequiredMsg),
                    value: possibleTypeSchemas,
                  })
                );
                break;
              }

              default:
                console.error(`this type of value is not supported yet: ${baseType}`);
                // we return schema which is always return validation error here
                // in order to prevent sending values of not supported type to BE
                return unsupportedTypeSchema.required(fieldIsRequiredMsg);
            }

            // we have to invoke smth which returns a schema
            // if just return finalSchema == doesn't work
            return finalSchema.nullable().required(fieldIsRequiredMsg);
          }),
        })
      )
      .required(fieldIsRequiredMsg),
  });

  return (
    <Modal visible={visible} destroyOnClose footer={null} closable={false}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {({ isValid, values, dirty, setFieldValue }) => {
          return (
            <Form layout="vertical" labelAlign="left">
              <Typography.Title level={3}>
                <FormattedMessage id="rules.rule" />
              </Typography.Title>
              {/* 
                  rendering rule meta information  
                */}
              <Form.Item label={<FormattedMessage id="rules.id" />} name="id" required>
                <Input placeholder="" name="id" disabled={shouldPreventEditing} />
              </Form.Item>
              <Form.Item label={<FormattedMessage id="rules.description" />} name="description">
                <Input.TextArea placeholder="" name="description" disabled={shouldPreventEditing} />
              </Form.Item>
              <Form.Item label={<FormattedMessage id="rules.condition" />} name="when">
                <Input.TextArea
                  placeholder=""
                  name="when"
                  disabled={shouldPreventEditing}
                  onChange={({ currentTarget: { value } }) => {
                    setFieldValue('when', value === '' ? null : value);
                  }}
                />
              </Form.Item>
              {/*
                  rendering outcomes 
                */}
              <OutcomesList outcomes={values?.outcomes} isDisabled={shouldPreventEditing} />
              <FormActionButtons
                isSaving={isSaving}
                isValid={isValid && dirty}
                onCancel={onCancel}
                showCancelConfirm={dirty}
                cancelDeclineText={<FormattedMessage id="general.cancel" />}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export const RuleForm = withRouter(RuleFormComponent);
