import { Modal, Row, Col, Divider, Form as AntdForm } from 'antd';
import { Formik } from 'formik';
import { Input, Select, Form } from 'formik-antd';
import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import * as Yup from 'yup';

import FormActionButtons from 'components/FormActionButtons';
import withIconsOptions, { IconsOptionsProps } from 'components/HOC/withIconsOptions';
import ModalTitleWithLanguageSwitcher from 'components/ModalTitleWithLanguageSwitcher';
import { LANGS } from 'constants/enums';
import { MENU_ITEMS_PROPERTIES_VALUES } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import { InputOption } from 'types/types';

import styles from './AddEditMenuItem.module.css';
import { MenuItemTransformed } from '../../stores/MenuItemsStore';
import MenuItemProperties from '../MenuItemProperties';

const { Item: FormItem } = AntdForm;

const TITLE_MAX_LENGTH = 35;
const DESCRIPTION_MAX_LENGTH = 600;
const LABEL_MAX_LENGTH = 10;

interface Props extends WrappedComponentProps, IconsOptionsProps {
  initialValues?: MenuItemTransformed;
  menuItemTypes: { [key: string]: string[] };
  resourceTypeOptions: InputOption[];
  menuItemTypeOptions: InputOption[];
  isSaving: boolean;
  isDisabled?: boolean;
  onCancel: () => void;
  onSubmit: (data: MenuItemTransformed) => void;
}

const AddEditMenuItem: FunctionComponent<Props> = ({
  iconsOptions,
  menuItemTypeOptions,
  isSaving,
  isDisabled,
  onCancel,
  initialValues,
  menuItemTypes,
  onSubmit,
  resourceTypeOptions,
  intl,
}) => {
  const validationSchema = Yup.object().shape({
    type: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
    icon: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
    properties: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(intl.formatMessage({ id: 'general.errors.required' })),
        value: Yup.mixed()
          .when('type', {
            is: MENU_ITEMS_PROPERTIES_VALUES.INTERVIEW_PROPERTIES,
            then: Yup.string().test(
              'isStringifiedObject',
              intl.formatMessage({ id: 'origin.menu-items.properties.interview-properties-error' }),
              value => {
                try {
                  return typeof JSON.parse(value) === 'object';
                } catch (error: any) {
                  return false;
                }
              }
            ),
            otherwise: Yup.string(),
          })
          .required(intl.formatMessage({ id: 'general.errors.required' })),
      })
    ),
  });

  const handleSubmit = useCallback(
    (data: MenuItemTransformed) => {
      onSubmit({
        ...data,
        properties: data.properties.map(({ value, type }) => {
          let rawValue = value;

          if (type === MENU_ITEMS_PROPERTIES_VALUES.OPTIONS_IDS) {
            rawValue = rawValue.split(' ');
          }

          if (type === MENU_ITEMS_PROPERTIES_VALUES.INTERVIEW_PROPERTIES) {
            rawValue = JSON.parse(rawValue);
          }

          return {
            type,
            value: rawValue,
          };
        }),
      });
    },
    [onSubmit]
  );

  const formLayouts = {
    form: {
      labelCol: {
        sm: { span: 14 },
      },
      wrapperCol: {
        sm: { span: 10 },
      },
    },
    sideBySide: {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 12,
      },
    },
    fullWidth: {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    },
  };

  const isFormDisabled = isSaving || isDisabled;

  const { originStore } = useContext(RootStoreContext);

  const [activeLanguage, setLanguage] = useState<LANGS>(
    originStore.currentOrigin?.defaultLanguage || (intl.locale as LANGS)
  );

  if (!initialValues) {
    return null;
  }

  // we want optionsIds property value to be treated as space-separated string
  const formattedInitialValues = {
    ...initialValues,
    properties: initialValues.properties.map(({ value, type }) => {
      let formattedValue = value;

      if (type === MENU_ITEMS_PROPERTIES_VALUES.OPTIONS_IDS && Array.isArray(formattedValue)) {
        formattedValue = formattedValue.join(' ');
      }

      if (
        type === MENU_ITEMS_PROPERTIES_VALUES.INTERVIEW_PROPERTIES &&
        formattedValue &&
        typeof formattedValue === 'object'
      ) {
        formattedValue = JSON.stringify(formattedValue);
      }

      return {
        type,
        value: formattedValue,
      };
    }),
  };

  return (
    <Modal
      visible
      destroyOnClose
      title={
        <ModalTitleWithLanguageSwitcher
          title={
            formattedInitialValues.id ? (
              <FormattedMessage id={isDisabled ? 'general.view' : 'general.edit'} />
            ) : (
              <FormattedMessage id="origin.menu-items.add-item" />
            )
          }
          activeLanguage={activeLanguage}
          onLanguageChange={setLanguage}
          availableLanguages={originStore.availableOriginLanguages}
        />
      }
      footer={null}
      closable={false}
    >
      <Formik
        initialValues={formattedInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        render={({ isValid, values, setFieldValue, dirty }) => (
          <Form layout="horizontal" labelAlign="left" {...formLayouts.form}>
            <Form.Item
              name="type"
              required
              label={<FormattedMessage id="origin.menu-items.menu-item" />}
            >
              <Select
                name="type"
                showSearch
                optionFilterProp="label"
                onChange={() => {
                  setFieldValue('properties', []);
                }}
                disabled={isFormDisabled}
                options={menuItemTypeOptions}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              name={`title.${activeLanguage}`}
              label={<FormattedMessage id="general.title" />}
            >
              <Input
                name={`title.${activeLanguage}`}
                disabled={isFormDisabled}
                maxLength={TITLE_MAX_LENGTH}
              />
            </Form.Item>
            <Divider />
            <Form.Item
              name={`description.${activeLanguage}`}
              label={<FormattedMessage id="general.description" />}
              {...formLayouts.fullWidth}
            >
              <Input.TextArea
                name={`description.${activeLanguage}`}
                rows={3}
                disabled={isFormDisabled}
                maxLength={DESCRIPTION_MAX_LENGTH}
              />
            </Form.Item>
            <Divider />
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name={`label.${activeLanguage}`}
                  label={<FormattedMessage id="origin.menu-items.price-label" />}
                  {...formLayouts.sideBySide}
                >
                  <Input
                    name={`label.${activeLanguage}`}
                    disabled={isFormDisabled}
                    maxLength={LABEL_MAX_LENGTH}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="icon"
                  required
                  label={<FormattedMessage id="general.icon" />}
                  {...formLayouts.sideBySide}
                >
                  <Select
                    name="icon"
                    className={styles.iconSelect}
                    disabled={isFormDisabled}
                    options={iconsOptions.map(({ value, label }) => ({
                      value,
                      label,
                      className: styles.iconOption,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <FormItem
              label={<FormattedMessage id="origin.menu-items.properties-label" />}
              {...formLayouts.fullWidth}
            >
              <MenuItemProperties
                menuItemTypes={menuItemTypes}
                values={values}
                resourceTypeOptions={resourceTypeOptions}
                setFieldValue={setFieldValue}
                isSaving={isSaving}
                isDisabled={isDisabled}
              />
            </FormItem>
            <Divider />
            <FormActionButtons
              isSaving={isSaving}
              isDisabled={isDisabled}
              showCancelConfirm={dirty}
              isValid={isValid && dirty}
              onCancel={onCancel}
            />
          </Form>
        )}
      />
    </Modal>
  );
};

export default withIconsOptions(injectIntl(AddEditMenuItem));
