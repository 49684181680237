import { Menu } from 'antd';
import classNames from 'classnames';
import React, { useContext, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import RootStoreContext from 'context/RootStoreContext';

import styles from './Submenu.module.css';

export interface SublinkItem {
  id?: string;
  name?: string;
  slug?: string;
  testId?: string;
}

interface Props {
  mainLink: string;
  subLinks: SublinkItem[];
  text: JSX.Element;
  icon: JSX.Element;
  testId?: string;
  isEmptyVisible?: boolean;
  activeMenuKey?: string;
  isMainLinkActive?: boolean;
}

const Submenu = ({
  mainLink,
  subLinks,
  text,
  icon,
  testId,
  isEmptyVisible,
  activeMenuKey,
  isMainLinkActive = true,
  ...otherProps
}: Props) => {
  const { layoutStore } = useContext(RootStoreContext);
  const mainLinkContent = (
    <Fragment>
      <icon.type {...icon.props} />
      <span>{text}</span>
    </Fragment>
  );

  return (
    <Menu.SubMenu
      popupClassName={classNames(styles.wrapper, {
        // this is for Ant Design theme customization file, so that submenu position can be set properly
        collapsed: layoutStore.collapsed,
      })}
      className={classNames({
        // this is for Ant Design theme customization file, because library does not set this class on
        // active submenu item by itself
        'ant-menu-item-selected': activeMenuKey === mainLink,
      })}
      key={mainLink}
      title={
        isMainLinkActive ? (
          <NavLink to={`/${mainLink}`}>{mainLinkContent}</NavLink>
        ) : (
          mainLinkContent
        )
      }
      data-testid={testId}
      {...otherProps}
    >
      {subLinks
        .filter(({ id }) => !!id || !!isEmptyVisible)
        .map((link, index) => (
          <Menu.Item key={link.id || index}>
            <NavLink
              to={`/${mainLink}${link.slug}`}
              data-testid={link.testId}
              // NavLink compares only pathname by default
              isActive={(_, { pathname, search }) =>
                `/${mainLink}${link.slug}` === `${pathname}${search}`
              }
              exact
            >
              {link.name}
            </NavLink>
          </Menu.Item>
        ))}
    </Menu.SubMenu>
  );
};

export default Submenu;
