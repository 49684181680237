import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router';

import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { BASIC_DETAILS_FORM_FIELDS as FIELDS } from 'constants/origins';
import RootStoreContext from 'context/RootStoreContext';
import { CarePlanGroupsListItem } from 'modules/You24/CarePlanGroups/api/carePlanGroupsApi';
import { sortWithLocale } from 'utils/textUtils';

const CarePlanGroupsList = ({ history }: RouteComponentProps) => {
  const { carePlanGroupsStore } = useContext(RootStoreContext);

  useEffect(() => {
    carePlanGroupsStore.fetchCarePlanGroups();
  }, [carePlanGroupsStore]);

  const columns = [
    {
      title: <FormattedMessage id="basic-details-form.name-label" />,
      dataIndex: FIELDS.NAME,
      defaultSortOrder: 'ascend' as SortOrder,
      sorter: (a: CarePlanGroupsListItem, b: CarePlanGroupsListItem) => {
        return sortWithLocale(a, b, 'name');
      },
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      width: 500,
    },
    {
      title: <FormattedMessage id="care-plan-groups.practitioners" />,
      dataIndex: 'numberOfParticipants',
      render: (practitionersNumber: number) =>
        0 === practitionersNumber ? '-' : practitionersNumber,
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: CarePlanGroupsListItem) => (
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => history.push(`/care-plan-groups/${record.id}`)}
        />
      ),
    },
  ];

  return (
    <Observer>
      {() => (
        <Table<CarePlanGroupsListItem>
          columns={columns}
          dataSource={carePlanGroupsStore.groups.slice()}
          pagination={false}
          rowKey={FIELDS.ID}
          data-testid="care-plan-groups-list"
          loading={carePlanGroupsStore.isLoading()}
        />
      )}
    </Observer>
  );
};

export default withRouter(CarePlanGroupsList);
