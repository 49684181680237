import { DeleteOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import classNames from 'classnames';
import { FieldArray } from 'formik';
import { Form, Input, Radio } from 'formik-antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Hostname } from 'api/originsApi';
import { BASIC_DETAILS_FORM_FIELDS } from 'constants/origins';

import styles from '../OriginsDetailsForm.module.css';

interface Props {
  isSaving: boolean;
  isDisabled: boolean;
  hostnames: Hostname[];
}

const HostnamesList = ({ isSaving, isDisabled, hostnames }: Props) => (
  <div className={classNames('ant-form-vertical', styles.fieldArray)}>
    <FieldArray
      name={BASIC_DETAILS_FORM_FIELDS.HOSTNAMES}
      render={arrayHelpers => (
        <Radio.Group name="primaryHostname" className={styles.radioGroup}>
          {hostnames.map((_, index) => (
            <Row key={index} gutter={16}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  key={index}
                  label={
                    index === 0 && <FormattedMessage id="origin.basic-details-form.domain-label" />
                  }
                  name={`${BASIC_DETAILS_FORM_FIELDS.HOSTNAMES}.${index}.hostname`}
                >
                  <Input
                    disabled={isSaving || isDisabled}
                    name={`${BASIC_DETAILS_FORM_FIELDS.HOSTNAMES}.${index}.hostname`}
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                {index > 0 && (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => arrayHelpers.remove(index)}
                    disabled={isSaving || isDisabled}
                  />
                )}
              </Col>
              <Col xs={12} sm={6} lg={4}>
                <Form.Item
                  label={
                    index === 0 && (
                      <FormattedMessage id="origin.basic-details-form.set-as-primary" />
                    )
                  }
                  name={`${BASIC_DETAILS_FORM_FIELDS.HOSTNAMES}.${index}.index`}
                  wrapperCol={{ offset: 8 }}
                >
                  <Radio
                    name={`${BASIC_DETAILS_FORM_FIELDS.HOSTNAMES}.${index}.index`}
                    disabled={isSaving || isDisabled}
                  />
                </Form.Item>
              </Col>
            </Row>
          ))}
          <Button
            type="link"
            onClick={() => {
              arrayHelpers.push({
                hostname: '',
                primary: false,
                index: hostnames.length,
              });
            }}
            className={styles.addButton}
            disabled={isSaving || isDisabled}
          >
            + <FormattedMessage id="general.add-more" />
          </Button>
        </Radio.Group>
      )}
    />
  </div>
);

export default HostnamesList;
