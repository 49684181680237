import { ErrorReporter } from 'types/types';
import { generateUUID } from 'utils/uuidUtils';

export enum TRACKED_ERROR_TYPE {
  HTTP = 'http',
  RECOVERY = 'recovery',
  TERMINATE = 'terminate',
  AUTH = 'auth',
}

export class D24TrackedError extends Error {
  id: string;
  type: TRACKED_ERROR_TYPE;
  meta?: Record<string, string | number>;

  constructor(message: string, type: TRACKED_ERROR_TYPE, meta?: Record<string, string | number>) {
    super(message);

    this.id = generateUUID();
    this.type = type;
    this.meta = meta;
  }
}

export class D24ErrorReporting {
  constructor(private readonly reporters: { [name: string]: ErrorReporter } = {}) {
    if (reporters) {
      Object.keys(reporters).forEach(key => {
        this.reporters[key] = reporters[key];
      });
    }
  }

  registerReporter(name: string, reporter: ErrorReporter): void {
    this.reporters[name] = reporter;
  }

  getReporter(name: string): ErrorReporter {
    return this.reporters[name];
  }

  reportError(error: D24TrackedError): void {
    Object.keys(this.reporters).forEach(reporter => {
      try {
        this.reporters[reporter].report(error);
      } catch (e) {
        console.error(e);
      }
    });
  }
}
export {};
