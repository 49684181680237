import axios from 'axios';
import { Parser } from 'bowser';

export interface AnalyticsEvent {
  name: string;
  clientProperties: {
    currentUrl: string;
    browser: Parser.Details;
    os: Parser.OSDetails;
    platform: Parser.PlatformDetails;
    engine: Parser.Details;
  };
  eventProperties: { [key: string]: number | boolean | string };
}

export const sendAnalyticsEvent = (event: AnalyticsEvent, partnerId: string, userId: string) =>
  axios.post(`/rest/event-analytics/v1/event`, event, {
    // Event tracker should fail silently
    ignoreErrorStatuses: [400, 500, 401, 404],
    headers: {
      'X-Origin': partnerId,
      'X-Client': userId,
    },
  });
