import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table } from 'antd';
import noop from 'lodash/noop';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, Fragment, ContextType } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import DraggableRow from 'components/DraggableRow';
import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import { TEST_IDS } from 'constants/testIds';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';

import styles from './Alerts.module.css';
import { NewsItem } from './api/newsItemsApi';
import AddEditAlert, { FIELDS } from './components/AddEditAlert';
import AlertsStore from './stores/AlertsStore';

interface Props extends RouteComponentProps<{ originId: string }>, WrappedComponentProps {}

@observer
class Alerts extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  alertsStore: AlertsStore;

  components = {
    body: {
      row: DraggableRow,
    },
  };

  columns = [
    {
      title: <FormattedMessage id="origin.alerts.subject" />,
      dataIndex: FIELDS.SUBJECT,
    },
    {
      title: <FormattedMessage id="origin.alerts.body" />,
      dataIndex: FIELDS.BODY,
    },
    {
      title: <FormattedMessage id="origin.alerts.link" />,
      dataIndex: FIELDS.LINK,
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: any) => (
        <Fragment>
          <Button
            type="link"
            icon={
              this.context.userPermissionsStore.canEditCurrentPartner ? (
                <EditOutlined />
              ) : (
                <EyeOutlined />
              )
            }
            onClick={() => this.alertsStore.handleEdit(record.uuid)}
            data-testid={TEST_IDS.EDIT_BTN}
          />
          {this.context.userPermissionsStore.canEditCurrentPartner && (
            <Popconfirm
              title={<FormattedMessage id="general.sure-to-delete" />}
              cancelText={<FormattedMessage id="general.cancel" />}
              onConfirm={() => this.alertsStore.handleDelete(record.uuid)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          )}
        </Fragment>
      ),
    },
  ];

  constructor(props: Props, context: RootStore) {
    super(props);
    this.alertsStore = new AlertsStore(context, props.match.params.originId);
  }

  componentDidMount() {
    this.alertsStore.fetchNewsItems();
  }

  handleMoveRow = (dragIndex: number, hoverIndex: number) => {
    const newsItems = this.alertsStore.newsItems.slice();
    const dragRow = newsItems[dragIndex];

    newsItems.splice(dragIndex, 1);
    newsItems.splice(hoverIndex, 0, dragRow);

    this.alertsStore.saveData(newsItems);
  };

  render() {
    const {
      handleAdd,
      handleSubmit,
      handleDelete,
      newsItems,
      isLoading,
      handleCancel,
      activeItem,
    } = this.alertsStore;
    const { userPermissionsStore } = this.context;

    return (
      <Fragment>
        <PageHeader content="origin.alerts.header" canEdit={false} />
        <DndProvider backend={HTML5Backend}>
          <Table<NewsItem>
            dataSource={toJS(newsItems)}
            columns={this.columns}
            pagination={false}
            loading={isLoading}
            components={userPermissionsStore.canEditCurrentPartner ? this.components : undefined}
            onRow={
              userPermissionsStore.canEditCurrentPartner
                ? (_, index) => ({
                    index,
                    moveRow: this.handleMoveRow,
                    // Required by onRow return type
                    onClick: noop,
                  })
                : undefined
            }
            data-testid="menu-items-list"
            className={styles.table}
            rowKey={FIELDS.SUBJECT}
          />
        </DndProvider>
        <AddEditAlert
          initialValues={activeItem}
          isSaving={isLoading}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onCancel={handleCancel}
          isDisabled={!userPermissionsStore.canEditCurrentPartner}
        />
        {userPermissionsStore.canEditCurrentPartner && (
          <PlusFloatingButton onClick={handleAdd} testId={TEST_IDS.ADD_BTN} />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(Alerts);
